import { combineReducers } from "redux";
import accountsReducer from "./accounts";
import usersReducer from "./users";
import authenticationReducer from "./authentication";
import loaderReducer from "./loader";
import sessionReducer from "./session";
import notificationReducer from "./notification";
import { reducer as toastrReducer } from "react-redux-toastr";
import projectsReducer from "./projects";
import routerReducer from "./router";
import modalReducer from "./modal";
import blocksReducer from "./blocks";
import segmentsReducer from "./segments";
import promptsReducer from "./prompts";
import responsesReducer from "./responses";
import documentsReducer from "./documents";
import medicationsReducer from "./medications";
import multiPartFormReducer from "./multi-part-form";
import plansReducer from "./plans";
import profilesReducer from "./profiles";
import adminReducer from "./admin";
import contactsReducer from "./contacts";
import transactionsReducer from "./transactions";

const createRootReducer = (history) => combineReducers({
  router: routerReducer,
  session: sessionReducer,
  accounts: accountsReducer,
  users: usersReducer,
  contacts: contactsReducer,
  authentication: authenticationReducer,
  loader: loaderReducer,
  notification: notificationReducer,
  projects: projectsReducer,
  documents: documentsReducer,
  blocks: blocksReducer,
  segments: segmentsReducer,
  prompts: promptsReducer,
  responses: responsesReducer,
  toastr: toastrReducer,
  modal: modalReducer,
  multi_part_form: multiPartFormReducer,
  medications: medicationsReducer,
  plans: plansReducer,
  profiles: profilesReducer,
  admin: adminReducer,
  transactions: transactionsReducer,
});
export default createRootReducer;