import { API } from "aws-amplify";
import { apiGateway } from "../../config";
import { showNotification } from "./notification";
import { store } from "..";
import {
  GET_ACCOUNT_CUSTOMER,
  IS_FETCHING_CUSTOMER,
  SHOW_LOADER,
  UPDATE_USER
} from "./constants";
import { getAccounts } from "./accounts";
import { getTransactions } from "./transactions";
const Gateway = apiGateway.find((gateway) => gateway.name === "client");

export const createStripeCustomer = (account_id, cognito_id, details) => async (dispatch) => {
  return API.post(
    Gateway.name,
    `/payments/customers/create/${account_id || store.getState().session.account.account_id}/${cognito_id || store.getState().authentication.user.cognito_id}`,
    {
      headers: {
        Authorization: store.getState().session.token
      },
      body: {
        ...details
      }
    })
    .then((created) => {
      dispatch({ type: UPDATE_USER, payload: { customer_id: created.payload.id } });
      dispatch({ type: GET_ACCOUNT_CUSTOMER, payload: created.payload });
      return created.payload;
    })
    .catch((error) => {
      dispatch(showNotification("error", "Customer Creation", (error.response && error.response.data ? error.response.data.message : "Something went wrong.")));
      return { success: false };
    });
};

export const updateStripeCustomer = (customer_id, updates) => async (dispatch) => {
  return API.patch(
    Gateway.name,
    `/payments/customers/update/${customer_id}/${store.getState().session.account.account_id}/${store.getState().authentication.user.cognito_id}`,
    {
      headers: {
        Authorization: store.getState().session.token
      },
      body: {
        updates
      }
    })
    .then((updated) => {
      dispatch(showNotification("success", "Customer Updated", "We successfully updated your customer record."));
      dispatch({ type: GET_ACCOUNT_CUSTOMER, payload: updated.payload });
      return updated;
    })
    .catch((error) => {
      dispatch(showNotification("error", "Customer Update", (error.response && error.response.data ? error.response.data.message : "Something went wrong.")));
      return { success: false };
    });
};

export const getStripeExpandedCustomer = (override = false, customer_id) => async (dispatch) => {
  if ((!store.getState().session.isFetchingCustomer) || override) {
    dispatch({ type: IS_FETCHING_CUSTOMER, payload: true });
    try {
      const data = await API.get(
        Gateway.name,
        `/payments/customers/${customer_id}/${store.getState().session.account.account_id}/${store.getState().authentication.user.cognito_id}`,
        {
          headers: {
            Authorization: store.getState().session.token
          }
        });
      if (data.success) {
        dispatch({ type: GET_ACCOUNT_CUSTOMER, payload: data.payload });
        dispatch({ type: IS_FETCHING_CUSTOMER, payload: false });
        return data;
      } else {
        dispatch({ type: IS_FETCHING_CUSTOMER, payload: false });
        return data;
      }
    } catch (error) {
      dispatch({ type: IS_FETCHING_CUSTOMER, payload: false });
      return { success: false, message: error.response && error.response.data ? error.response.data.message : "Something went wrong." };
    }
  }
};

export const addPaymentSource = (source, customer_id, primary) => async (dispatch) => {
  if (source.object !== "token") dispatch({ type: SHOW_LOADER, payload: { show: true, message: "Adding Card..." } });
  return API.post(
    Gateway.name,
    `/payments/sources/create/${store.getState().session.account.account_id}/${store.getState().authentication.user.cognito_id}`,
    {
      headers: {
        Authorization: store.getState().session.token
      },
      body: {
        source,
        primary,
        customer_id
      }
    })
    .then((updated) => {
      if (updated.success) {
        if (source.object !== "token") {
          if (primary) dispatch(showNotification("success", "Payment Method Added", "We added your new default payment method to your account."));
          if (!primary) dispatch(showNotification("success", "Payment Method Added", "We added your new payment method to your account."));
        }
        dispatch({ type: GET_ACCOUNT_CUSTOMER, payload: updated.payload });
      }
      if (source.object !== "token") dispatch({ type: SHOW_LOADER, payload: { show: false } });
      return updated;
    })
    .catch((error) => {
      if (source.object !== "token") dispatch({ type: SHOW_LOADER, payload: { show: false } });
      dispatch(showNotification("error", "Add Payment Method", (error.response && error.response.data ? error.response.data.message : "Something went wrong.")));
      return { success: false };
    });
};
export const deletePaymentSource = (source_id, customer_id) => async (dispatch) => {
  return API.del(
    Gateway.name,
    `/payments/sources/delete/${customer_id}/${source_id}/${store.getState().session.account.account_id}/${store.getState().authentication.user.cognito_id}`,
    {
      headers: {
        Authorization: store.getState().session.token
      }
    })
    .then((deleted) => {
      dispatch(showNotification("success", "Payment Method Deleted", "We successfully removed this payment method from your account."));
      dispatch({ type: GET_ACCOUNT_CUSTOMER, payload: deleted.payload });
      return deleted;
    })
    .catch((error) => {
      dispatch(showNotification("error", "Payment Method Removal", (error.response && error.response.data ? error.response.data.message : "Something went wrong.")));
      return { success: false };
    });
};

export const createSubscription = (subscription, product_id, customer_id) => async (dispatch) => {
  return API.post(
    Gateway.name,
    `/payments/subscriptions/create/${customer_id}/${store.getState().session.account.account_id}/${store.getState().authentication.user.cognito_id}`,
    {
      headers: {
        Authorization: store.getState().session.token
      },
      body: {
        subscription,
        product_id
      }
    })
    .then(async (subscription) => {
      dispatch({ type: GET_ACCOUNT_CUSTOMER, payload: subscription.payload });
      dispatch(getAccounts(store.getState().authentication.user.cognito_id, store.getState().session.account.account_id, true));
      dispatch(getTransactions(customer_id, true));
      return subscription;
    })
    .catch((error) => {
      dispatch(showNotification("error", "Something went wrong", error.response && error.response.data ? error.response.data.message : "An unknown error occurred."));
      return false;
    });
};

export const updateSubscription = (subscription, product_id, initial_product_id, customer_id) => async (dispatch) => {
  return API.post(
    Gateway.name,
    `/payments/subscriptions/update/${customer_id}/${store.getState().session.account.account_id}/${store.getState().authentication.user.cognito_id}`,
    {
      headers: {
        Authorization: store.getState().session.token
      },
      body: {
        subscription,
        product_id,
        initial_product_id
      }
    })
    .then(async (subscription) => {
      dispatch(getAccounts(store.getState().authentication.user.cognito_id, store.getState().session.account.account_id, true));
      dispatch(getTransactions(customer_id, true));
      dispatch({ type: GET_ACCOUNT_CUSTOMER, payload: subscription.payload });
      return subscription;
    })
    .catch((error) => {
      dispatch(showNotification("error", "Something went wrong", error.response && error.response.data ? error.response.data.message : "An unknown error occurred."));
      return false;
    });
};

export const updateSubscriptionItem = (subscription_id, updates, customer_id) => async (dispatch) => {
  return API.post(
    Gateway.name,
    `/payments/subscriptions/items/update/${customer_id}/${store.getState().session.account.account_id}/${store.getState().authentication.user.cognito_id}`,
    {
      headers: {
        Authorization: store.getState().session.token
      },
      body: {
        subscription_id,
        updates
      }
    })
    .then(async (subscription) => {
      dispatch(showNotification("success", "Subscription Updated", "We successfully updated your subscription limits."));
      dispatch(getStripeExpandedCustomer(true, customer_id));
      dispatch(getAccounts(store.getState().authentication.user.cognito_id, store.getState().session.account.account_id, true));
      return subscription;
    })
    .catch((error) => {
      dispatch(showNotification("error", "Something went wrong", error.response && error.response.data ? error.response.data.message : "An unknown error occurred."));
      return false;
    });
};
export const verifyDiscount = (code, product_id) => async (dispatch) => {
  if (code) {
    return API.get(Gateway.name,
      `/payments/discounts/verify/${code}/${store.getState().session.account.account_id}/${store.getState().authentication.user.cognito_id}?product_id=${product_id}`,
      {
        headers: {
          Authorization: store.getState().session.token
        }
      })
      .then((coupon) => {
        if (coupon.success) {
          return coupon.payload;
        } else {
          dispatch(showNotification("error", "Discount Code", coupon.message));
        }
      })
      .catch((error) => {
        dispatch(showNotification("error", "Discount Verification", error.response.data.message));
        return false;
      });
  } else {
    dispatch(showNotification("error", "Discount Verification", "You must enter a discount code."));
    return false;
  }
};

export const applyCoupon = (subscription_id, updates, customer_id) => async (dispatch) => {
  return API.post(
    Gateway.name,
    `/payments/subscriptions/apply-coupon/${customer_id}/${store.getState().session.account.account_id}/${store.getState().authentication.user.cognito_id}`,
    {
      headers: {
        Authorization: store.getState().session.token
      },
      body: {
        subscription_id,
        updates
      }
    })
    .then(async (subscription) => {
      dispatch({ type: GET_ACCOUNT_CUSTOMER, payload: subscription.payload });
      dispatch(showNotification("success", "Subscription Updated", "We successfully applied your discount."));
      return subscription;
    })
    .catch((error) => {
      dispatch(showNotification("error", "Something went wrong", error.response && error.response.data ? error.response.data.message : "An unknown error occurred."));
      return false;
    });
};