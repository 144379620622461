import { UPDATE_DEVICE_ID, WORKER_ACTIVE, SET_IOS_PROMPTED, SET_ONBOARDED, SET_LAUNCHED, TOGGLE_MENU, SET_THEME, UPDATE_APP_VERSION, UPDATE_ONLINE_STATUS, IS_LOGGED_IN, IS_LOGGED_OUT, SET_TOKEN, SET_CREDITS, CLEAR_ALL, SET_ACCOUNT, SET_TAB, SET_ABLY_TOKEN, LOCATION_CHANGE, UPDATE_USER, GET_ACCOUNT_CUSTOMER, IS_FETCHING_CUSTOMER, UPDATE_CHAT_BADGE, SET_SETTINGS, SET_IDLE, SET_LOCKED, SET_ALLOW_LOCK_SCREEN, REMEMBER_ME, SET_DEV_AUTH, SET_SMART_SCHEDULE_ACTIVATED, SET_ONBOARDING_PEOPLE, SET_ONBOARDING_DOCUMENTS, SET_ONBOARDING_CATEGORIES, SET_ONBOARDING_WELCOME, SET_ONBOARDING_DONE, FOCUS_CHAT_OPEN, SET_ONBOARDING_STEP } from "../actions/constants";
import defaults from "./defaults";

const sessionReducer = (state = defaults.session, { type, payload }) => {
  switch (type) {
    case SET_SETTINGS:
      return { ...state, settings: { ...payload, fetched: new Date() } };
    case IS_LOGGED_IN:
      return { ...state, authorized: true };
    case IS_LOGGED_OUT:
      return { ...state, authorized: false, ably_token: false, logging_out: false };
    case SET_ACCOUNT:
      return {...state, account: payload };
    case SET_TOKEN:
      return { ...state, token: payload };
    case SET_CREDITS:
      return { ...state, credits: payload };
    case SET_TAB:
      return { ...state, tab: payload };
    case SET_ABLY_TOKEN:
      return { ...state, ably_token: payload };
    case UPDATE_ONLINE_STATUS:
      return { ...state, online_status: payload };
    case UPDATE_APP_VERSION:
      return {...state, app_version: payload };
    case TOGGLE_MENU:
      return { ...state, menu: !state.menu };
    case SET_THEME:
      return { ...state, theme: payload };
    case SET_ONBOARDED:
      return {...state, onboarded: payload };
    case LOCATION_CHANGE:
      return {...state, menu: false };
    case SET_LAUNCHED:
      return { ...state, launched: payload, onboarding_step: 0 };
    case SET_IOS_PROMPTED:
      return { ...state, iosPrompted: payload };
    case WORKER_ACTIVE:
      return { ...state, workerActive: payload };
    case UPDATE_DEVICE_ID:
      return {...state, device_id: payload };
    case GET_ACCOUNT_CUSTOMER:
      return {...state, customer: payload };
    case IS_FETCHING_CUSTOMER:
      return {...state, isFetchingCustomer: payload };
    case UPDATE_CHAT_BADGE:
      return { ...state, unread_chats: payload };
    case SET_IDLE:
      return { ...state, idle: payload, last_active: new Date() };
    case SET_LOCKED:
      return { ...state, locked: { ...payload } };
    case SET_ALLOW_LOCK_SCREEN:
      return { ...state, allow_lock_screen: payload };
    case REMEMBER_ME:
      return { ...state, remember_me: payload };
    case SET_SMART_SCHEDULE_ACTIVATED:
      return { ...state, smart_schedule_activated: payload };
    case UPDATE_USER:
      return { ...state, last_logged_in: {
        email: payload.email,
        avatar: payload.avatar,
        cognito_id: payload.cognito_id,
        first_name: payload.first_name,
        last_name: payload.last_name
      }
    };
    case CLEAR_ALL:
      return {
        ...defaults.session,
        auth: state.auth,
        remember_me: state.remember_me,
        allow_lock_screen: state.allow_lock_screen,
        theme: state.theme,
        launched: state.launched,
        onboarded: state.onboarded,
        iosPrompted: state.iosPrompted,
        last_logged_in: state.last_logged_in,
        device_id: state.device_id,
        workerActive: state.workerActive
      };
    case SET_ONBOARDING_STEP:
      return {...state, onboarding_step: payload };
    case SET_ONBOARDING_WELCOME:
      return { ...state, onboarding_welcome: payload };
    case SET_ONBOARDING_CATEGORIES:
      return { ...state, onboarding_categories: payload };
    case SET_ONBOARDING_PEOPLE:
      return { ...state, onboarding_people: payload };
    case SET_ONBOARDING_DOCUMENTS:
      return { ...state, onboarding_documents: payload };
    case SET_ONBOARDING_DONE:
      return { ...state, onboarding_done: payload };
    case FOCUS_CHAT_OPEN:
      return { ...state, focus_chat_open: payload };
    case SET_DEV_AUTH:
      return { ...state, auth: payload };
    default:
      return state;
  }
};

export default sessionReducer;