import { CHANGE_FORM_SLIDE, CHANGE_STEP_COMPLETION, CLEAR_MULTI_PART_FORM, IS_LOGGED_OUT } from "../actions/constants";
import defaults from "./defaults";
const multiPartFormReducer = (state = defaults.multi_part_form, { type, payload }) => {
  switch (type) {
    case CHANGE_FORM_SLIDE:
      return { ...state, slide: payload };
    case CHANGE_STEP_COMPLETION:
      return { ...state, steps_status: { ...state.steps_status, [payload.step]: payload.status } };
    case CLEAR_MULTI_PART_FORM:
      return defaults.multi_part_form;
    case IS_LOGGED_OUT:
      return defaults.multi_part_form;
    default:
      return state;
  }
};

export default multiPartFormReducer;