import { API } from "aws-amplify";
import { apiGateway } from "../../config";
import { store } from "../";
import { getAccounts } from "./accounts";
import { getProject, getPrompts } from "./projects";
import { getUser } from "./user";
import { SET_SETTINGS, TOGGLE_MENU, UPDATE_CHAT_BADGE, SET_ONBOARDING_STEP } from "./constants";
import { getBlocks } from "./blocks";
import { getResponses } from "./responses";
import { getSegments } from "./segments";
import { getDocuments } from "./document";
import { getAccountUsers } from "./users";
import { getContacts } from "./contacts";
const Gateway = apiGateway.find((gateway) => gateway.name === "client");

export const refreshImperatives = async (projectId) => {
  const cognito_id = store.getState().authentication.user.cognito_id;
  const account_id = store.getState().session.account.account_id;
  let requests = [
    store.dispatch(getAccounts(cognito_id, account_id, true)),
    store.dispatch(getAccountUsers(account_id, cognito_id, true)),
    getUser(cognito_id, account_id, true),
  ];
  if (projectId) {
    requests.push(store.dispatch(getProject(projectId)));
    requests.push(store.dispatch(getBlocks(true)));
    requests.push(store.dispatch(getResponses(projectId, true)));
    requests.push(store.dispatch(getSegments(projectId, true)));
    requests.push(store.dispatch(getDocuments(projectId, true)));
    requests.push(store.dispatch(getPrompts(projectId, true)));
    requests.push(store.dispatch(getContacts(projectId, true)));
  }
  return await Promise.all(requests);
};

export const setOnboardingStep = (step) => async (dispatch) => {
  dispatch({ type: SET_ONBOARDING_STEP, payload: step });
};

export const checkMaintenanceMode = async () => {
  return API.get(Gateway.name, `/settings`)
    .then((data) => {
      store.dispatch({ type: SET_SETTINGS, payload: data.payload });
      window.LOG_LEVEL = false;
      if (data.payload.debug) window.LOG_LEVEL = "DEBUG";
      return data;
    })
    .catch((error) => {
      return {
        success: false,
        message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
      };
    });
};

export const toggleMenu = () => async (dispatch) => {
  dispatch({ type: TOGGLE_MENU });
};

export const updateChatBadge = (count) => async (dispatch) => {
  dispatch({ type: UPDATE_CHAT_BADGE, payload: count });
};

export const createCompletion = async (prompt) => {
  return API.post(Gateway.name, `/prompts/create-completion/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`, {
    headers: {
      Authorization: store.getState().session.token,
    },
    body: {
      prompt
    },
  })
    .then((updated) => {
      return updated;
    })
    .catch((error) => {
      return {
        success: false,
        message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
      };
    });
};

export const createGeneration = async (prompt) => {
  return API.post(Gateway.name, `/prompts/generate/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`, {
    headers: {
      Authorization: store.getState().session.token,
    },
    body: {
      prompt
    },
  })
    .then((updated) => {
      return updated;
    })
    .catch((error) => {
      return {
        success: false,
        message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
      };
    });
};

export const createResponse = async (block_id, project_id, prompt, page_id) => {
  return API.post(Gateway.name, `/prompts/generate-response/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`, {
    headers: {
      Authorization: store.getState().session.token,
    },
    body: {
      block_id,
      project_id,
      prompt,
      page_id
    },
  })
    .then((updated) => {
      return updated;
    })
    .catch((error) => {
      return {
        success: false,
        message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
      };
    });
};
