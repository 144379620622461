import {
  GET_TRANSACTIONS,
  FETCHING_TRANSACTIONS,
  FETCHED_TRANSACTIONS,
  CLEAR_ALL
} from "../actions/constants";
import defaults from "./defaults";

const transactionsReducer = (state = defaults.transactions, { type, payload }) => {
  switch (type) {
    case GET_TRANSACTIONS:
      return { ...state, list: payload, fetched_all: new Date() };
    case FETCHING_TRANSACTIONS:
      return { ...state, fetching: payload };
    case FETCHED_TRANSACTIONS:
      return { ...state, fetched: payload };
    case CLEAR_ALL:
      return defaults.transactions;
    default:
      return state;
  }
};

export default transactionsReducer;