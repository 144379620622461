import { Auth } from "@aws-amplify/auth";
import { API, Hub, Logger } from "aws-amplify";
import { apiGateway } from "../../config";
import { externalNavigate } from "./navigation";
import { checkOnlineStatus, clearObjectStore, formatUSPhoneNumber, getCurrentTimezone, passkeyAuth } from "../../utilities";
import {
  CLEAR_ALL,
  IS_LOGGED_IN,
  IS_LOGGED_OUT,
  LOGGING_OUT,
  SET_LOCKED,
  SET_TOKEN,
  UPDATE_CREDENTIALS,
  AUTO_VERIFYING_PHONE
} from "./constants";
import { showLoader, hideLoader } from "./loader";
import { checkEmail, createUser, getUser } from "./user";
import { createAccount, getAccounts } from "./accounts";
import { store } from "..";
import { showNotification } from "./notification";
import { toastr } from "react-redux-toastr";
import LogRocket from "logrocket";
import { themeBuilder } from "../../global-styles";
import { getProjects } from "./projects";
import config from "../../config";
import { Crisp } from "crisp-sdk-web";
import { createStripeCustomer, createSubscription } from "./stripe";
import { getPlans } from "./plans";
const Gateway = apiGateway.find((gateway) => gateway.name === "client");
const logger = new Logger("Auth-Logger");

export const setCrisp = (u) => {
  const data = {
    cognito_id: u.cognito_id
  };
  if (u.gender) data.gender = u.gender;
  Crisp.muteSound(false);
  Crisp.setAvailabilityTooltip(false);
  Crisp.setHideOnMobile(true);
  Crisp.setTokenId(u.cognito_id);
  Crisp.user.setEmail(u.email);
  Crisp.user.setNickname(`${u.first_name} ${u.last_name}`);
  if (u.phone) Crisp.user.setPhone(u.phone);
  Crisp.user.setAvatar(`https://${process.env.REACT_APP_STAGE || "development"}-api.${process.env.REACT_APP_API_BASE}/client/users/get-user-avatar/${u?.cognito_id}`);
  Crisp.session.setSegments([process.env.REACT_APP_STAGE || "development"]);
  if (process.env.REACT_APP_STAGE === "production") Crisp.setSafeMode(true);
  Crisp.session.setData(data);
  LogRocket.getSessionURL((url) => {
    if (url) {
      Crisp.session.setData({
        logrocket_url: url
      });
    }
  });
  Crisp.chat.hide();
  Crisp.load();
};

export const verifyCredential = async (credential, challenge) => {
  return API.post(
    Gateway.name,
    `/users/verify-credential/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`,
    {
      headers: {
        Authorization: store.getState().session.token
      },
      body: {
        credential,
        challenge
      }
    })
    .then((response) => response)
    .catch((error) => {
      return error;
    });
};

export const storeCredential = async (credential, challenge, origin) => {
  return API.post(
    Gateway.name,
    `/users/store-credential/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`,
    {
      headers: {
        Authorization: store.getState().session.token
      },
      body: {
        credential,
        challenge
      }
    })
    .then((response) => response)
    .catch((error) => {
      return { success: false, message: error.response && error.response.data ? error.response.data.message : "Something went wrong." };
    });
};

export const getAuthChallenge = async (verify) => {
  return API.get(
    Gateway.name,
    `/users/get-auth-challenge/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}?verify=${verify}`,
    {
      headers: {
        Authorization: store.getState().session.token
      }
    })
    .then((response) => response)
    .catch((error) => {
      return { success: false, message: error.response && error.response.data ? error.response.data.message : "Something went wrong." };
    });
};

export const deletePasskey = async (id) => {
  return API.del(
    Gateway.name,
    `/users/delete-passkey/${id}/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`,
    {
      headers: {
        Authorization: store.getState().session.token
      }
    })
    .then(async (response) => {
      await getUser(store.getState().authentication.user.cognito_id, store.getState().session.account.account_id, true);
      return response;
    })
    .catch((error) => {
      console.log(error);
      return { success: false, message: error.response && error.response.data ? error.response.data.message : "Something went wrong." };
    });
};

export const editPasskeyName = async (id, name) => {
  return API.patch(
    Gateway.name,
    `/users/edit-passkey/${id}/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`,
    {
      headers: {
        Authorization: store.getState().session.token
      },
      body: {
        name
      }
    })
    .then(async (response) => {
      await getUser(store.getState().authentication.user.cognito_id, store.getState().session.account.account_id, true);
      return response;
    })
    .catch((error) => {
      console.log(error);
      return { success: false, message: error.response && error.response.data ? error.response.data.message : "Something went wrong." };
    });
};

export const signUp = ({
  email,
  password,
  phone,
  first_name,
  last_name,
  invite
}) => async (dispatch) => {
  const user_email = email.toLowerCase().replace(/\s+/g, "");
  const attributes = {
    email: user_email,
    name: `${first_name} ${last_name}`
  };
  if (phone) attributes.phone_number = formatUSPhoneNumber(phone);

  dispatch(showLoader("Loading..."));

  try {
    await checkEmail(user_email);
    return Auth.signUp({
      username: attributes.email,
      password,
      attributes
    })
    .then((cognitoUser) => {
      dispatch({
        type: UPDATE_CREDENTIALS,
        payload: {
          invite,
          verifying: true,
          first_name,
          last_name,
          email: user_email,
          phone,
          password,
          username: cognitoUser.userSub,
          codeDeliveryDetails: cognitoUser.codeDeliveryDetails
        }
      });
      LogRocket.identify(cognitoUser.userSub, {
        name: `${first_name} ${last_name}`,
        email: user_email,
        environment: process.env.REACT_APP_STAGE || "development"
      });
      dispatch(hideLoader());
      return { success: true, payload: { ...cognitoUser, email: user_email, first_name, last_name } };
    })
    .catch((e) => {
      dispatch(hideLoader());
      return { success: false, error: e };
    });
  } catch (e) {
    dispatch(hideLoader());
    if (e.response && e.response.data && e.response && e.response.data.status_code === "blacklist_domain") store.dispatch({ type: CLEAR_ALL });
    return { success: false, message: e.response && e.response.data ? e.response.data.message : e.message || "Something went wrong." };
  }
};

export const confirmSignup = (email, code, cognito, referral) => async (dispatch) => {
  dispatch(showLoader("Loading..."));
  try {
    await Auth.confirmSignUp(email, code, "SMS_MFA");
    const cognitoUser = await Auth.signIn(email, cognito.password);
    const JWT = cognitoUser.signInUserSession.idToken.jwtToken;
    dispatch({ type: SET_TOKEN, payload: JWT });

    const createUserResult = await createUser({
      cognito_id: cognitoUser.username,
      email,
      phone: formatUSPhoneNumber(cognito.phone),
      first_name: cognito.first_name,
      last_name: cognito.last_name,
      time_zone: getCurrentTimezone()
    }, cognitoUser.username, referral);

    dispatch({
      type: UPDATE_CREDENTIALS,
      payload: {
        username: cognitoUser.username,
        preferredMFA: cognitoUser.preferredMFA,
        attributes: cognitoUser.attributes
      }
    });

    if (createUserResult.success) {
      const user = createUserResult.payload;
      const createAccountResult = await createAccount({
        account_id: user.cognito_id,
        cognito_id: user.cognito_id,
        account_name: `${user.first_name} ${user.last_name}`
      }, cognito.invite);

      if (createAccountResult.success) {
        await getUser(user.cognito_id, createAccountResult.payload.account_id, true);
        await dispatch(getAccounts(createAccountResult.payload.account_id, user.cognito_id));
        dispatch(createStripeCustomer(createAccountResult.payload.account_id, user.cognito_id, {
          phone: formatUSPhoneNumber(cognito.phone),
          name: `${cognito.first_name} ${cognito.last_name}`,
          email: user.email,
          address: {
            country: "US"
          }
        }))
        .then((customer) => {
          dispatch(getPlans(true))
          .then((plans) => {
            const free_plan = plans.find((p) => !p.value);
            dispatch(createSubscription({}, free_plan.product_id, customer.id))
          })
        })
        dispatch({ type: IS_LOGGED_IN });

        LogRocket.identify(user.cognito_id, {
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          account_id: createAccountResult.payload.account_id,
          environment: process.env.REACT_APP_STAGE || "development"
        });
        setCrisp(user);

        externalNavigate("/");
        dispatch(hideLoader());
        return createAccountResult;
      }

      return createAccountResult;
    }
    return createUserResult;
  } catch (e) {
    console.error(e);
    dispatch({ type: IS_LOGGED_OUT });
    dispatch(hideLoader());
    return { success: false };
  }
};

export const resendSignup = (email) => async (dispatch) => {
  email = email.toLowerCase().replace(/\s+/g, "");

  try {
    await Auth.resendSignUp(email);
    dispatch(showNotification("success", "A verification code has been sent to you."));
    return { success: true };
  } catch (error) {
    dispatch(showNotification("error", "Something went wrong.", "Could not resend verification code."));
    return {
      success: false,
      message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
    };
  }
};

export const signIn = async ({ email, password }, cognito) => {
  const dispatch = store.dispatch;
  const user_email = email.toLowerCase().replace(/\s+/g, "");
  try {
    if ((user_email && password) || cognito) {
      let cognitoUser;
      if (cognito) cognitoUser = cognito;
      else cognitoUser = await Auth.signIn(user_email, password);
      if (cognitoUser?.challengeName === "SMS_MFA") {
        dispatch(hideLoader());
        return { success: false, is_mfa: true, user: cognitoUser };
      } else if (cognitoUser?.challengeName === "NEW_PASSWORD_REQUIRED") {
        dispatch(hideLoader());
        return { success: false, is_force: true, user: cognitoUser };
      } else {
        dispatch(showLoader("Loading..."));
        dispatch({
          type: UPDATE_CREDENTIALS,
          payload: {
            username: cognitoUser.username,
            preferredMFA: cognitoUser.preferredMFA,
            attributes: cognitoUser.attributes
          }
        });
        const JWT = cognitoUser.signInUserSession.idToken.jwtToken;
        dispatch({ type: SET_TOKEN, payload: JWT });
        const [accounts] = await Promise.all([
          dispatch(getAccounts(cognitoUser.username, cognitoUser.username, true))
        ]);

        if (!accounts || !accounts?.accounts?.length) {
          dispatch(hideLoader());
          store.dispatch(showNotification("error", "Sign In Failed - Could not fetch accounts."));
          return { success: false, is_unconfirmed: true };
        }
        const userData = await getUser(cognitoUser.username, accounts.current.account_id, true);

        if (userData.success) {
          if (store.getState().session.onboarded) {
            const authed = await passkeyAuth();
            if (!authed) {
              dispatch({ type: IS_LOGGED_IN });
              checkOnlineStatus();
              dispatch(hideLoader());
              dispatch({ type: SET_LOCKED, payload: { show: true, inactive: false } })
              return { success: false };
            } else {
              dispatch({ type: SET_LOCKED, payload: { show: false, inactive: false } })
            }
          }
          dispatch({ type: IS_LOGGED_IN });
          const u = userData.payload;
          return dispatch(getProjects(true))
          .then((projects) => {
            LogRocket.identify(u.cognito_id, {
              name: `${u.first_name} ${u.last_name}`,
              email: user_email,
              account_id: accounts.current.account_id,
              environment: process.env.REACT_APP_STAGE || "development"
            });
            setCrisp(u);
            checkOnlineStatus();
            dispatch(hideLoader());
            return { success: true, projects: projects.payload };
          })
        }
        dispatch(hideLoader());
        store.dispatch(showNotification("error", "Sign In Failed - Could not fetch user record."));
        return { success: false };
      }
    } else if (store.getState().session.authorized) {
      return dispatch(tokenRefresh(store.getState().authentication.user?.last_update));
    } else {
      dispatch(hideLoader());
      return { success: false };
    }
  } catch (error) {
    if (error.code === "UserNotConfirmedException") {
      return { success: false, is_unconfirmed: true, challengeName: error.code, user: { email: user_email } }
    } else if (error.code === "PasswordResetRequiredException") {
      dispatch({ type: UPDATE_CREDENTIALS, payload: { challengeName: error.code, email: user_email } });
      externalNavigate("/change-password");
    } else if (error.code === "NotAuthorizedException" || error.code === "UserNotFoundException") {
      return { success: false, error, message: "NotAuthorizedException" };
    } else {
      return { success: false, error, message: "UserNotFoundException" };
    }
    externalNavigate("/");
    checkOnlineStatus();
    dispatch(hideLoader());
    return false;
  }
};

export const cancelSignup = async (email) => {
  if (email) {
    email = email.toLowerCase().replace(/\s+/g, "");
    return API.post(Gateway.name, `/users/cancel-signup`, { body: { email } })
      .then((data) => {
        return data.deleted;
      })
      .catch((error) => {
        return {
          success: false,
          message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
        };
      });
  }
  return {
    success: false,
    message: "Could not cancel registration. Please provide a valid email address."
  };
};

export const signOut = (global = false) => async (dispatch) => {
  try {
    dispatch({ type: LOGGING_OUT });
    return Auth.signOut({ global })
    .then(() => {
      localStorage.removeItem("billing_code");
      if (global && window.$crisp?.do) Crisp.session.reset();
      dispatch({ type: IS_LOGGED_OUT });
      return true;
    })
    .catch(() => {
      return false;
    })
  } catch (e) {
    console.log(e);
    return false;
  }
};

const promptLogin = (message, okText) => {
  const promptOptions = {
    onOk: () => store.dispatch(signOut()),
    onCancel: () => toastr.removeByType("confirms"),
    okText,
    cancelText: "Cancel",
    disableCancel: true,
  };
  toastr.confirm(message, promptOptions);
};

// To initiate the process of verifying the attribute like 'phone_number' or 'email'
export const verifyCurrentUserAttribute = async (attr) => {
  return Auth.verifyCurrentUserAttribute(attr)
  .then((v) => {
    return { success: true, message: "Verification code sent successfully." };
  })
  .catch((err) => {
    return { success: false, error: err, message: err.message };
  });
};

// To verify attribute with the code
export const verifyCurrentUserAttributeSubmit = async (attr, verificationCode) => {
  return Auth.verifyCurrentUserAttributeSubmit(attr, verificationCode)
    .then(async (v) => {
      await getUser(store.getState().authentication.user.cognito_id, store.getState().session.account.account_id, true);
      return { success: true, message: "Verified successfully." };
    })
    .catch((err) => {
      return { success: false, error: err, message: err.message };
    });
};

export const completeNewPassword = async (cognito, email, password) => {
  try {
    return Auth.completeNewPassword(
      cognito,
      password
    )
    .then(async (loggedInUser) => {
      await signIn({ email, password });
      return loggedInUser;
    })
    .catch((error) => {
      store.dispatch(showNotification("error", "Could not update password.", error.message));
    });
  } catch (err) {
    console.log(err);
    store.dispatch(showNotification("error", "Could not update password.", err.message));
    return err;
  }
};

export const changePassword = async (oldPassword, newPassword) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
    store.dispatch(showNotification("success", "Password Updated", "Your password was successfully changed."));
    return true;
  } catch (err) {
    store.dispatch(showNotification("error", "Password Update Failed", err.message));
    return false;
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    await Auth.forgotPassword(email);
    dispatch(showNotification("success", "Password verification code has been sent to your email."));
    return { success: true };
  } catch (error) {
    console.log(error);
    dispatch(showNotification("error", "Could not send password reset.", error.message));
    return { success: false, error };
  }
};

export const forgotPasswordSubmit = (email, code, newPassword) => async (dispatch) => {
  try {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
    dispatch(showNotification("success", "Password successfully updated. Logging in..."));
    dispatch(showLoader("Loading..."));
    return { success: true };
  } catch (error) {
    console.log(error);
    dispatch(showNotification("error", "Validation Failed - Could not update password.", error.message));
    return { success: false, error };
  }
};

export const setupMfa = async (type) => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    await Auth.setPreferredMFA(cognitoUser, type);
    return Auth.getPreferredMFA(cognitoUser, { bypassCache: true })
      .then((preferred) => {
      if (preferred) {
        store.dispatch({
          type: UPDATE_CREDENTIALS,
          payload: {
            username: cognitoUser.username,
            preferredMFA: preferred,
            attributes: cognitoUser.attributes
          }
        });
        return { success: true, data: preferred };
      }
      return { success: false };
    })
    .catch((error) => {
      console.log(error)
      return { success: false, error };
    })
  } catch (error) {
    console.log('Error setting MFA', error);
    return { success: false, error };
  }
};

export const rememberMFADevice = async () => {
  try {
    const result = await Auth.rememberDevice();
    return result;
  } catch (error) {
    console.log('Error remembering device', error);
    return error;
  }
};

export const forgetMFADevice = async () => {
  try {
    const result = await Auth.forgetDevice();
    return result;
  } catch (error) {
    console.log('Error forgetting device', error);
    return error;
  }
};

export const fetchMFADevices = async () => {
  try {
    const result = await Auth.fetchDevices();
    return result;
  } catch (error) {
    console.log('Error fetching device', error);
    return error;
  }
};

export const autoVerifyingPhone = (payload) => async (dispatch) => {
  dispatch({ type: AUTO_VERIFYING_PHONE, payload });
};

export const tokenRefresh = (last_update) => async (dispatch) => {
  const now = new Date();
  const lastUpdateTimestamp = last_update * 1000; // Convert Unix timestamp to milliseconds
  const expirationTimestamp = lastUpdateTimestamp + 60 * 60 * 1000; // Add 1 hour in milliseconds
  const isExpired = now.getTime() > expirationTimestamp;
  
  // Check if last_updated is more than 1 hour ago
  if ((!last_update || isExpired)) {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = cognitoUser.signInUserSession;

      cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
        const JWT = session.idToken.jwtToken;
        dispatch({ type: SET_TOKEN, payload: JWT });
        dispatch({
          type: UPDATE_CREDENTIALS,
          payload: {
            username: cognitoUser.username,
            preferredMFA: cognitoUser.preferredMFA,
            attributes: cognitoUser.attributes,
          },
        });
        getUser(cognitoUser.username, store.getState().session.account.account_id, true)
          .then(async (user) => {
            if (user.success) {
              dispatch({ type: IS_LOGGED_IN });
              LogRocket.identify(user.payload.cognito_id, {
                name: `${user.payload.first_name} ${user.payload.last_name}`,
                email: user.payload.email,
                account_id: store.getState().session.account?.account_id,
                environment: process.env.REACT_APP_STAGE || "development"
              });
            }
            checkOnlineStatus();
            return user;
          })
          .catch((error) => {
            console.log(error);
            return false;
          });
      });
    } catch (e) {
      checkOnlineStatus();
      if (store.getState().session.authorized) promptLogin("Your session has expired", "Log Out");
      return false;
    }
  } else {
    checkOnlineStatus();
    return true;
  }
};

const authListener = (data) => {
  switch (data.payload.event) {
    case "configured":
      logger.info("the Auth module is configured");
      break;
    case "signIn":
      logger.info("user signed in");
      break;
    case "signIn_failure":
      store.dispatch(hideLoader());
      store.dispatch(showNotification("error", `Sign In Failed - ${data.payload.data.message}`));
      logger.error("user sign in failed");
      break;
    case "signUp":
      logger.info("user signed up");
      break;
    case "signUp_failure":
      logger.error("user sign up failed");
      break;
    case "confirmSignUp":
      logger.info("user confirmation successful");
      break;
    case "completeNewPassword_failure":
      logger.error("user did not complete new password flow");
      break;
    case "autoSignIn":
      logger.info("auto sign in successful");
      break;
    case "autoSignIn_failure":
      logger.error("auto sign in failed");
      break;
    case "forgotPassword":
      logger.info("password recovery initiated");
      break;
    case "forgotPassword_failure":
      logger.error("password recovery failed");
      break;
    case "forgotPasswordSubmit":
      logger.info("password confirmation successful");
      break;
    case "forgotPasswordSubmit_failure":
      logger.error("password confirmation failed");
      break;
    case "tokenRefresh":
      logger.info("token refresh succeeded");
      break;
    case "tokenRefresh_failure":
      logger.error("token refresh failed");
      break;
    case "signOut":
      themeBuilder().reset();
      Crisp.session.reset();
      Crisp.chat.hide();
      Crisp.setTokenId(null);
      localStorage.removeItem("ably_token");
      clearObjectStore(config.system.IDB_NAME, config.system.IDB_STORE);
      store.dispatch({ type: CLEAR_ALL });
      logger.info("user signed out");
      break;
    default:
      logger.info("unknown");
  }
};

Hub.listen("auth", authListener);