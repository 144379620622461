import { API } from "aws-amplify";
import { apiGateway } from "../../config";
import { store } from "../";
import {
  GET_TRANSACTIONS,
  FETCHING_TRANSACTIONS,
  FETCHED_TRANSACTIONS
} from "./constants";
const Gateway = apiGateway.find((gateway) => gateway.name === "client");

export const getTransactions = (customer_id, override = false) => async (dispatch) => {
  if ((!store.getState().transactions.fetching && !store.getState().transactions.fetched) || override) {
    dispatch({ type: FETCHING_TRANSACTIONS, payload: true });
    return API.get(
      Gateway.name,
      `/payments/get-transactions/${customer_id}/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`,
      {
        headers: {
          "Authorization": store.getState().session.token
        }
      })
      .then(async (data) => {
        dispatch({ type: GET_TRANSACTIONS, payload: data.payload });
        dispatch({ type: FETCHED_TRANSACTIONS, payload: true });
        dispatch({ type: FETCHING_TRANSACTIONS, payload: false });
      })
      .catch((error) => {
        if (error?.response?.data?.payload && !error.response.data.payload.length) dispatch({ type: GET_TRANSACTIONS, payload: [] });
        dispatch({ type: FETCHED_TRANSACTIONS, payload: true });
        dispatch({ type: FETCHING_TRANSACTIONS, payload: false });
        return {
          success: false,
          error
        };
      });
  }
};