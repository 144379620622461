import { API } from "aws-amplify";
import { apiGateway } from "../../config";
import { store } from "..";
import {
  UPDATE_USER,
  FETCHING_CREDENTIALS,
  FETCHED_CREDENTIALS,
  CLEAR_ALL
} from "./constants";
import moment from "moment";
import { checkPushSubscription, getCurrentTimezone } from "../../utilities";
import { showNotification } from "./notification";
import { setCrisp } from "./authentication";
const Gateway = apiGateway.find((gateway) => gateway.name === "client");

export const getUser = async (cognito_id, account_id, override) => {
  if ((!store.getState().authentication.fetched && !store.getState().authentication.fetching) || override) {
    store.dispatch({ type: FETCHING_CREDENTIALS, payload: true });
    return API.get(
      Gateway.name,
      `/users/get-user/${account_id || cognito_id}/${cognito_id}`,
      {
        headers: {
          Authorization: store.getState().session.token,
        },
      }
    )
      .then((response) => {
        if (response.success) store.dispatch({ type: UPDATE_USER, payload: { ...response.payload, last_update: moment().unix() } });
        store.dispatch({ type: FETCHING_CREDENTIALS, payload: false });
        store.dispatch({ type: FETCHED_CREDENTIALS, payload: true });
        return response;
      })
      .catch((error) => {
        console.log(error);
        store.dispatch({ type: FETCHING_CREDENTIALS, payload: false });
        store.dispatch({ type: FETCHED_CREDENTIALS, payload: true });
        return { success: false, message: error.response && error.response.data ? error.response.data.message : "Something went wrong." };
      });
  }
  return { success: false };
};

export const updateUser = async (updates) => {
  return API.patch(
    Gateway.name,
    `/users/update/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`,
    {
      headers: {
        Authorization: store.getState().session.token
      },
      body: {
        updates
      }
    })
    .then((response) => {
      setCrisp(response.payload);
      if (response.success) store.dispatch({ type: UPDATE_USER, payload: response.payload });
      return response;
    })
    .catch((error) => {
      console.log(error);
      return { success: false, message: error.response && error.response.data ? error.response.data.message : "Something went wrong." };
    });
};

export const createUser = async (user, account_id, referral) => {
  return API.post(
      Gateway.name,
      `/users/create/${account_id}/${user.cognito_id}`,
      {
        headers: {
          Authorization: store.getState().session.token
        },
        body: {
          user,
          account_id,
          referral
        }
      })
    .then((response) => {
      if (response.success) {
        store.dispatch({ type: UPDATE_USER, payload: response.payload });
      }
      return response;
    })
    .catch((error) => {
      if (error.response && error.response.data && error.response && error.response?.payload?.status_code === "blacklist_domain") {
        store.dispatch({ CLEAR_ALL });
      }
      return { success: false, message: error.response && error.response.data ? error.response.data.message : "Something went wrong." };
    });
};

export const createPushSubscription = async (subscription, config) => {
  return API.post(
    Gateway.name,
    `/users/create-push-subscription/${store.getState().session.account.account_id}/${store.getState().authentication.user.cognito_id}`,
    {
      headers: {
        Authorization: store.getState().session.token
      },
      body: {
        subscription,
        config: {
          ...config,
          time_zone: getCurrentTimezone()
        }
      }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return { success: false, message: error.response && error.response.data ? error.response.data.message : "Something went wrong." };
    });
};

export const updatePushSubscription = async (subscription, updates) => {
  if (!subscription) subscription = await checkPushSubscription();
  if (subscription) {
    return API.post(
      Gateway.name,
      `/users/update-push-subscription/${store.getState().session.account.account_id}/${store.getState().authentication.user.cognito_id}`,
      {
        headers: {
          Authorization: store.getState().session.token
        },
        body: {
          endpoint: subscription.endpoint,
          updates
        }
      })
      .then(async (response) => {
        await getUser(store.getState().authentication.user.cognito_id, store.getState().session.account.account_id, true);
        return response;
      })
      .catch((error) => {
        return { success: false, message: error.response && error.response.data ? error.response.data.message : "Something went wrong." };
      });
  } else {
    return { success: false, message: "Subscription not found." };
  }
};

export const getAblyToken = async () => {
  return API.get(
    Gateway.name,
    `/users/get-ably-token/${store.getState().session.account.account_id}/${store.getState().authentication.user.cognito_id}`,
    {
      headers: {
        Authorization: store.getState().session.token
      }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return { success: false, message: error.response && error.response.data ? error.response.data.message : "Something went wrong." };
    });
};

export const inviteFriends = (invites) => async (dispatch) => {
  return API.post(
    Gateway.name,
    `/users/invite/${store.getState().session.account.account_id}/${store.getState().authentication.user.cognito_id}`,
    {
      headers: {
        Authorization: store.getState().session.token
      },
      body: {
        invites
      }
    })
    .then((response) => {
      if (response.success) dispatch(showNotification("success", "Invites sent successfully."));
      return response;
    })
    .catch((error) => {
      console.log(error);
      return { success: false, message: error.response && error.response.data ? error.response.data.message : "Something went wrong." };
    });
};

export const checkEmail = async (email) => {
  return await API.get(Gateway.name, `/users/check-email?email=${email}`);
};