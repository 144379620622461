import {
  ADD_PROMPTS,
  REMOVE_PROMPT,
  FETCHING_PROMPTS,
  FETCHED_PROMPTS,
  CLEAR_ALL,
  ADD_PROJECTS
} from "../actions/constants";
import defaults from "./defaults";

const promptsReducer = (state = defaults.prompts, { type, payload }) => {
  switch (type) {
    case ADD_PROMPTS:
      return { ...state, list: { ...state.list, [payload.project_id]: payload.list }, prompt_reminders: payload.prompt_reminders };
    case REMOVE_PROMPT:
      const current = state.list[payload.project_id];
      const remaining = current.filter((d) => d.schedule_id !== payload.schedule_id);
      return { ...state, list: { ...state.list, [payload.project_id]: remaining } };
    case FETCHING_PROMPTS:
      return { ...state, fetching: payload };
    case FETCHED_PROMPTS:
      return { ...state, fetched: { ...state.fetched, [payload.project_id]: new Date() } };
    case ADD_PROJECTS:
      return { ...state, fetched: {}, fetching: false, list: {} };
    case CLEAR_ALL:
      return defaults.prompts;
    default:
      return state;
  }
};

export default promptsReducer;