import { GET_PLANS, FETCHING_PLANS, FETCHED_PLANS, CLEAR_ALL } from "../actions/constants";
import defaults from "./defaults";

const plansReducer = (state = defaults.plans, { type, payload }) => {
  switch (type) {
    case GET_PLANS:
      return { ...state, list: payload, last_fetched: new Date() };
    case FETCHING_PLANS:
      return { ...state, fetching: payload };
    case FETCHED_PLANS:
      return { ...state, fetched: payload };
    case CLEAR_ALL:
      return defaults.plans;
    default:
      return state;
  }
};

export default plansReducer;