import {
  GET_USERS,
  ADD_USER,
  FETCHING_USERS,
  FETCHED_USERS,
  CLEAR_ALL,
  DELETE_USER,
} from "../actions/constants";
import defaults from "./defaults";

const usersReducer = (state = defaults.users, { type, payload }) => {
  switch (type) {
    case GET_USERS:
      return { ...state, list: payload, fetched_all: new Date() };
    case ADD_USER:
      const existing = state.list.filter((u) => u.cognito_id !== payload.cognito_id);
      return { ...state, list: [...existing, payload] };
    case DELETE_USER:
      const users = state.list.filter((u) => u.membership_id !== payload);
      return { ...state, list: [...users] };
    case FETCHING_USERS:
      return { ...state, fetching: payload };
    case FETCHED_USERS:
      return { ...state, fetched: payload };
    case CLEAR_ALL:
      return defaults.users;
    default:
      return state;
  }
};

export default usersReducer;