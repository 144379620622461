import { API } from "aws-amplify";
import { apiGateway } from "../../config";
import { store } from "..";
import {
  FETCHING_CONTACTS,
  FETCHED_CONTACTS,
  ADD_CONTACTS,
  ADD_CONTACT,
  UPDATE_CONTACT,
  DELETE_CONTACT,
  ADD_USER
} from "./constants";
import { getProject } from "./projects";
import { getAccountUsers } from "./users";
const Gateway = apiGateway.find((gateway) => gateway.name === "client");

export const getContacts = (project_id, override = false) => async (dispatch) => {
  if ((!store.getState().contacts.fetching && !store.getState().contacts.fetched[project_id]) || override) {
    dispatch({ type: FETCHING_CONTACTS, payload: true });
    return API.get(
      Gateway.name,
      `/contacts/get-all/${project_id}/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`,
      {
        headers: {
          "Authorization": store.getState().session.token
        }
      })
      .then(async (data) => {
        dispatch({ type: ADD_CONTACTS, payload: { list: data.payload, project_id } });
        dispatch({ type: FETCHED_CONTACTS, payload: { status: true, project_id } });
        dispatch({ type: FETCHING_CONTACTS, payload: false });
      })
      .catch((error) => {
        if (error?.response?.data?.payload && !error.response.data.payload.length) dispatch({ type: ADD_CONTACTS, payload: { list: [], project_id } });
        dispatch({ type: FETCHED_CONTACTS, payload: { status: true, project_id } });
        dispatch({ type: FETCHING_CONTACTS, payload: false });
        return {
          success: false,
          error
        };
      });
  }
};

export const createContact = (project_id, data) => async (dispatch) => {
  return API.post(Gateway.name, `/contacts/create/${project_id}/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`, {
    headers: {
      Authorization: store.getState().session.token,
    },
    body: {
      contact: data
    },
  })
    .then((data) => {
      dispatch({ type: ADD_CONTACT, payload: data.payload });
      dispatch(getProject(project_id, true));
      return data;
    })
    .catch((error) => {
      return {
        success: false,
        message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
      };
    });
};

export const updateContact = (contact_id, updates) => async (dispatch) => {
  return API.patch(Gateway.name, `/contacts/update/${contact_id}/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`, {
    headers: {
      Authorization: store.getState().session.token,
    },
    body: {
      updates
    },
  })
    .then((updated) => {
      dispatch({ type: UPDATE_CONTACT, payload: { new: updated.payload, contact_id } });
      dispatch(getProject(updated.payload.project_id, true));
      return updated;
    })
    .catch((error) => {
      return {
        success: false,
        message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
      };
    });
};

export const deleteContact = (contact_id, project_id) => async (dispatch) => {
  return API.del(Gateway.name, `/contacts/delete/${contact_id}/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`, {
    headers: {
      Authorization: store.getState().session.token,
    }
  })
    .then((deleted) => {
      dispatch({ type: DELETE_CONTACT, payload: { project_id, id: deleted.payload } });
      dispatch(getProject(project_id, true));
      return deleted;
    })
    .catch((error) => {
      return {
        success: false,
        message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
      };
    });
};

export const convertContact = (contact_id, project_id) => async (dispatch) => {
  return API.patch(Gateway.name, `/contacts/convert/${contact_id}/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`, {
    headers: {
      Authorization: store.getState().session.token,
    }
  })
    .then((data) => {
      dispatch({ type: ADD_USER, payload: data.payload });
      dispatch(getContacts(project_id, true));
      dispatch(getAccountUsers(store.getState().session.account.account_id, store.getState().authentication.user.cognito_id, true))
      dispatch(getProject(project_id, true));
      return data;
    })
    .catch((error) => {
      return {
        success: false,
        message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
      };
    });
};