import React, { lazy } from "react";
import { createRoot } from "react-dom/client";
import { Provider, useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import CustomRouter from "./Components/CustomRouter";
import { Amplify } from "aws-amplify";
import { amplifyConfiguration } from "./amplify-config";
import { GlobalStyle, themeBuilder } from "./global-styles";
import { store, persistor, history } from "./store";
import { ThemeProvider } from "styled-components";
import { SET_THEME } from "./store/actions/constants";
import "survey-core/defaultV2.min.css";
import "survey-core/survey.i18n";
import loadIcons from "./iconLoader";
import { addMetaTag } from "./utilities";
const App = lazy(() => import(/* webpackChunkName: "App" */ "./App"));

Amplify.configure(amplifyConfiguration);
const root = createRoot(document.getElementById("root"));
loadIcons();
addMetaTag();

const ThemeListener = ({ children }) => {
	const theme = useSelector((globalStore) => globalStore.session.theme);
	const dark = window.matchMedia("(prefers-color-scheme: dark)");
	const dispatch = useDispatch();
	const themeObj = themeBuilder(theme)?.buildTheme;
	
	dark.addEventListener("change", (e) => {
		dispatch({ type: SET_THEME, payload: (e.matches ? "dark" : theme || "default") });
	});

	return (
		<ThemeProvider theme={themeObj || {}}>
			{children}
		</ThemeProvider>
	);
};

root.render(
	<Provider store={store}>
		<ThemeListener>
			<PersistGate
				loading={null}
				persistor={persistor}>
				<CustomRouter basename="/" history={history}>
					<GlobalStyle />
					<App />
				</CustomRouter>
			</PersistGate>
		</ThemeListener>
	</Provider>
);