import { API } from "aws-amplify";
import { apiGateway } from "../../config";
import { store } from "../";
import {
  GET_USERS,
  FETCHING_USERS,
  FETCHED_USERS,
  ADD_USER,
  DELETE_USER
} from "./constants";
import { getProject } from "./projects";
import { showNotification } from "./notification";
import { refreshImperatives } from "./session";
const Gateway = apiGateway.find((gateway) => gateway.name === "client");

export const getAccountUsers = (account_id, cognito_id, override) => async (dispatch) => {
  if ((!store.getState().users.fetching && !store.getState().users.fetched) || override) {
    dispatch({ type: FETCHING_USERS, payload: true });
    return API.get(Gateway.name, `/accounts/get-account-users/${(account_id || store.getState().session.account.account_id)}/${cognito_id}`,
      {
        headers: {
          Authorization: store.getState().session.token
        }
      })
        .then((data) => {
          dispatch({ type: GET_USERS, payload: data.payload });
          dispatch({ type: FETCHING_USERS, payload: false });
          dispatch({ type: FETCHED_USERS, payload: true });
          return { success: data.success, users: data.payload };
        })
        .catch((error) => {
          dispatch({ type: FETCHING_USERS, payload: false });
          dispatch({ type: FETCHED_USERS, payload: true });
          return {
            success: false,
            message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
          };
        });
  }
};

export const createAccountUser = (new_user, config, project_id) => async (dispatch) => {
  return API.post(Gateway.name, `/users/create-account-user/${project_id}/${store.getState().session.account.account_id}/${store.getState().authentication.user.cognito_id}`, {
    headers: {
      Authorization: store.getState().session.token,
    },
    body: {
      new_user,
      config
    },
  })
    .then((newUser) => {
      dispatch({ type: ADD_USER, payload: newUser.payload });
      dispatch(getProject(project_id, true));
      return newUser;
    })
    .catch((error) => {
      return {
        success: false,
        message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
      };
    });
};

export const updateAccountUser = (cognito_id, updates, config, projectId) => async (dispatch) => {
  return API.patch(Gateway.name, `/users/update-account-user/${projectId}/${cognito_id}/${store.getState().session.account.account_id}/${store.getState().authentication.user.cognito_id}`, {
    headers: {
      Authorization: store.getState().session.token,
    },
    body: {
      updates,
      config
    },
  })
    .then((newUser) => {
      dispatch({ type: ADD_USER, payload: newUser.payload });
      dispatch(getProject(projectId, true));
      return newUser;
    })
    .catch((error) => {
      return {
        success: false,
        message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
      };
    });
};

export const deleteAccountUser = (membership_id, project_id) => async (dispatch) => {
  dispatch({ type: DELETE_USER, payload: membership_id });
  return API.del(Gateway.name, `/memberships/delete/${membership_id}/${store.getState().session.account.account_id}/${store.getState().authentication.user.cognito_id}`, {
    headers: {
      Authorization: store.getState().session.token,
    }
  })
    .then(async (response) => {
      await refreshImperatives(project_id);
      return response.deleted;
    })
    .catch((error) => {
      return {
        success: false,
        message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
      };
    });
};

export const resetUserPassword = async (email) => {
  return API.post(Gateway.name, `/users/reset-password/${store.getState().session.account.account_id}/${store.getState().authentication.user.cognito_id}`, {
    headers: {
      Authorization: store.getState().session.token,
    },
    body: {
      email
    },
  })
   .then((response) => {
      store.dispatch(showNotification("success", response.message));
      return response.success;
    })
   .catch((error) => {
      store.dispatch(showNotification("error", error.response && error.response.data ? error.response.data.message : "Something went wrong."));
      return {
        success: false,
        message: error.response && error.response.data? error.response.data.message : "Something went wrong."
      };
    });
};