import { SHOW_LOADER, HIDE_LOADER, CLEAR_ALL } from "../actions/constants";
import defaults from "./defaults";

const loaderReducer = (state = defaults.loader, { type, payload }) => {
  switch (type) {
    case SHOW_LOADER:
      return { ...state, show: true, message: payload.message, timer: payload.timer, cancel: payload.cancel };
    case HIDE_LOADER:
      return defaults.loader;
    case CLEAR_ALL:
      return defaults.loader;
    default:
      return state;
  }
};

export default loaderReducer;