import { SHOW_MODAL, HIDE_MODAL, CLEAR_ALL, LOCATION_CHANGE } from "../actions/constants";
import defaults from "./defaults";

const modalReducer = (state = defaults.modal, { type, payload }) => {
  switch (type) {
    case SHOW_MODAL:
      return { ...state, show: true, data: payload };
    case HIDE_MODAL:
      return { ...state, show: false };
    case LOCATION_CHANGE:
      return defaults.modal;
    case CLEAR_ALL:
      return defaults.modal;
    default:
      return state;
  }
};

export default modalReducer;