import { API } from "aws-amplify";
import { apiGateway } from "../../config";
import axios from "axios";
import {
  UPDATE_DOCUMENT_PROPS,
  ADD_DOCUMENTS,
  DELETE_DOCUMENT,
  CREATING_DOCUMENT,
  UPDATE_DOCUMENT_USAGE,
  FETCHING_DOCUMENTS,
  FETCHED_DOCUMENTS,
  ADD_DOCUMENT,
  UPDATE_SINGLE_DOCUMENT,
  UPDATING_DOCUMENT,
  DELETE_DOCUMENT_BY_TAG
} from "./constants";
import { store } from "..";
import { showNotification } from "./notification";
import { getProject } from "./projects";
const Gateway = apiGateway.find((gateway) => gateway.name === "client");

export const getDocuments = (project_id, override = false) => async (dispatch) => {
  if ((!store.getState().documents.fetching && !store.getState().documents.fetched[project_id]) || override) {
    dispatch({ type: FETCHING_DOCUMENTS, payload: true });
    return API.get(
      Gateway.name,
      `/documents/get-all/${project_id}/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`,
      {
        headers: {
          "Authorization": store.getState().session.token
        }
      })
      .then(async (data) => {
        dispatch({ type: ADD_DOCUMENTS, payload: { list: data.payload, project_id } });
        dispatch({ type: UPDATE_DOCUMENT_USAGE, payload: { project_id, usage: data.usage } });
        dispatch({ type: FETCHED_DOCUMENTS, payload: { status: true, project_id } });
        dispatch({ type: FETCHING_DOCUMENTS, payload: false });
      })
      .catch((error) => {
        if (error?.response?.data?.payload && !error.response.data.payload.length) dispatch({ type: ADD_DOCUMENTS, payload: { list: [], project_id } });
        dispatch({ type: UPDATE_DOCUMENT_USAGE, payload: { project_id, usage: error.response.data.usage } });
        dispatch({ type: FETCHED_DOCUMENTS, payload: { status: true, project_id } });
        dispatch({ type: FETCHING_DOCUMENTS, payload: false });
        return {
          success: false,
          error
        };
      });
    }
};

export const createDocument = (url, file, silent = false, refreshProject = true, shouldCreateRecord, share = false) => async (dispatch) => {
  dispatch({ type: CREATING_DOCUMENT, payload: true });
  let headers = {
    'Content-Type': file.file.type
  };
  if (share) headers["x-amz-tagging"] = "temporary=1" 
  const document = await axios.put(url, file.file, {
    disableMultipart: true,
    headers
  });

  if (document.status === 200) {
    if (shouldCreateRecord) {
      return API.post(
        Gateway.name,
        `/documents/create/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`,
        {
          headers: {
            "Authorization": store.getState().session.token
          },
          body: {
            file: file.config
          }
        })
        .then(async (data) => {
          dispatch({ type: ADD_DOCUMENT, payload: data.payload });
          dispatch({ type: UPDATE_DOCUMENT_USAGE, payload: { project_id: file.config.project_id, usage: data.payload.size + (store.getState().documents.usage[file.config.project_id] || 0) } });
          dispatch({ type: CREATING_DOCUMENT, payload: false });
          if (!silent) dispatch(showNotification("success", "Document created successfully."));
          if (refreshProject) dispatch(getProject(file.config.project_id, true));
          return data;
        })
        .catch((error) => {
          dispatch(showNotification("error", error.message));
          dispatch({ type: CREATING_DOCUMENT, payload: false });
          return {
            success: false,
            error
          };
        });
    } else {
      dispatch({ type: CREATING_DOCUMENT, payload: false });
      return { success: true };
    }
  } else {
    dispatch(showNotification("error", "Could not upload document. Please try again."));
    dispatch({ type: CREATING_DOCUMENT, payload: false });
    return { success: false };
  }
};

export const getSignedURL = (file, method = "put", duration, silent = false, refreshProject, shouldCreateRecord = true) => async (dispatch) => {
  if (method === "put") {
    if (shouldCreateRecord) dispatch({ type: CREATING_DOCUMENT, payload: true });
    file.config.filename = file.config.filename.replaceAll("/", "-");
  }
  return API.post(
    Gateway.name,
    `/documents/get-signed-url/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`,
    {
      headers: {
        "Authorization": store.getState().session.token
      },
      body: {
        config: { ...file.config, duration },
        method
      }
    })
    .then(async (data) => {
      if (data.success) {
        if (method === "put") return dispatch(createDocument(data.payload, file, silent, refreshProject, shouldCreateRecord, file.config.share));
        dispatch({ type: CREATING_DOCUMENT, payload: false });
        return data.payload;
      }
      dispatch({ type: CREATING_DOCUMENT, payload: false });
      return { success: false };
    })
    .catch((error) => {
      dispatch({ type: CREATING_DOCUMENT, payload: false });
      return {
        success: false,
        error
      };
    });
};

// export const getDocument = (key, associated_account_id) => async (dispatch) => {
//   const document = await API.post(
//     Gateway.name,
//     `/documents/get-single-document/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`,
//     {
//       headers: {
//         "Authorization": store.getState().session.token
//       },
//       body: {
//         key,
//         associated_account_id
//       }
//     });
//   return document;
// };

export const updateDocument = (project_id, id, updates, new_file) => async (dispatch) => {
  dispatch({ type: UPDATING_DOCUMENT, payload: true });
  return API.patch(
    Gateway.name,
    `/documents/update/${project_id}/${id}/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`,
    {
      headers: {
        Authorization: store.getState().session.token
      },
      body: {
        new_file,
        updates
      }
    }).then((data) => {
      dispatch(showNotification("success", "Document updated successfully."));
      dispatch({ type: UPDATE_SINGLE_DOCUMENT, payload: data.payload });
      return data;
    }).catch((error) => {
      dispatch(showNotification("error", error.message));
      dispatch({ type: UPDATING_DOCUMENT, payload: false });
      return {
        success: false,
        error
      };
    });
};

export const deleteDocument = (key, project_id, size) => async (dispatch) => {
  dispatch({ type: DELETE_DOCUMENT, payload: { project_id, filename: key, size } });
  return API.del(
    Gateway.name,
    `/documents/delete/${project_id}/${encodeURIComponent(key)}/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`,
    {
      headers: {
        "Authorization": store.getState().session.token
      }
    })
    .then(async (data) => {
      dispatch(showNotification("success", "Document deleted successfully."));
      return data;
    })
    .catch((error) => {
      dispatch(showNotification("error", error.message));
      return {
        success: false,
        error
      };
    });
};

export const deleteDocumentsByTag = (tag, project_id) => async (dispatch) => {
  return API.patch(
    Gateway.name,
    `/documents/delete-by-tag/${project_id}/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`,
    {
      headers: {
        "Authorization": store.getState().session.token
      },
      body: {
        question_name: tag
      }
    })
    .then(async (data) => {
      dispatch({ type: DELETE_DOCUMENT_BY_TAG, payload: { project_id, tag, size: data.payload.size } });
      dispatch(showNotification("success", "Document deleted successfully."));
      return data;
    })
    .catch((error) => {
      dispatch(showNotification("error", error.message));
      return {
        success: false,
        error
      };
    });
};

export const getPreview = async (key, project_id, size = "thumbnail") => {
  return API.get(
    Gateway.name,
    `/documents/get-preview/${size}/${store.getState().session.account.account_id}/${store.getState().authentication.user.cognito_id}/${project_id}/${key}`,
    {
      headers: {
        "Authorization": store.getState().session.token
      }
    })
};
export const updateDocumentProps = (project_id, key, updates) => async (dispatch) => {
  dispatch({ type: UPDATE_DOCUMENT_PROPS, payload: { project_id, key, updates } });
};