import {
  ADD_PROJECTS,
  ADD_PROJECT,
  UPDATE_PROJECT,
  REMOVE_PROJECT,
  FETCHING_PROJECTS,
  FETCHED_PROJECTS,
  FETCHING_PROJECT,
  FETCHED_PROJECT,
  OPEN_SURVEY,
  CLOSE_SURVEY,
  LOADING_BLOCKS,
  CLEAR_ALL,
  LOCATION_CHANGE,
  SET_DOCUMENT,
  UPDATE_RESPONSE,
  UPDATE_DOCUMENT,
  RESET_DOCUMENT,
  STORE_PROJECT_SETTINGS,
  SET_PROMPT_SUCCESS,
  BUILDING_SURVEYS,
  SET_ADAPTIVE,
  PLAN_COMPLETE,
  QUICK_LOOK
} from "../actions/constants";
import defaults from "./defaults";

const projectsReducer = (state = defaults.projects, { type, payload }) => {
  switch (type) {
    case ADD_PROJECTS:
      const new_projects = payload.reduce((init, p) => {
        init[p.id] = { ...p, settings: {}, last_fetched: new Date() };
        return init;
      }, {});
      return { ...defaults.projects, list: new_projects, fetched_all: new Date() };
    case ADD_PROJECT:
      return { ...state, list: { ...state.list, [payload.id]: { ...payload, settings: {}, last_fetched: new Date() } } };
    case UPDATE_PROJECT:
      return { ...state, list: { ...state.list, [payload.id]: { ...state.list[payload.id], ...payload, settings: {}, last_fetched: new Date() } }, document: [] };
    case REMOVE_PROJECT:
      const remaining = { ...state.list };
      delete remaining[payload];
      return { ...state, list: { ...remaining } };
    case BUILDING_SURVEYS:
      return{ ...state, building_surveys: payload };
    case FETCHING_PROJECTS:
      return { ...state, fetching: payload };
    case FETCHED_PROJECTS:
      return { ...state, fetched: payload };
    case FETCHING_PROJECT:
      return { ...state, fetching_project: payload };
    case FETCHED_PROJECT:
      return { ...state, fetched_project: payload };
    case OPEN_SURVEY:
      return { ...state, survey: { ...payload }, survey_open: true };
    case CLOSE_SURVEY:
      return { ...state, survey: false, survey_open: false };
    case LOADING_BLOCKS:
      return { ...state, loading_blocks: payload };
    case UPDATE_DOCUMENT:
      return { ...state, document: payload };
    case SET_DOCUMENT:
      return { ...state, document: payload, original_document: payload };
    case SET_PROMPT_SUCCESS:
      return { ...state, promptSuccess: payload };
    case RESET_DOCUMENT:
      return { ...state, document: state.original_document };
    case STORE_PROJECT_SETTINGS:
      return { ...state, list: { ...state.list, [payload.project_id]: { ...state.list[payload.project_id], settings: payload.settings } }};
    case UPDATE_RESPONSE:
      if (payload.has_updates) return { ...state, list: { ...state.list, [payload.project_id]: { ...state.list[payload.project_id], last_updated_by: payload.project_last_updated_by, progress: payload.progress }}, loading_blocks: state.loading_blocks?.length ? state.loading_blocks.filter((b) => b !== payload.page_id) : [] };
      return { ...state, loading_blocks: [] };
    case SET_ADAPTIVE:
      return {...state, adaptive: payload };
    case PLAN_COMPLETE:
      return { ...state, plan_complete: payload };
    case QUICK_LOOK:
      return { ...state, quickLook: payload };
    case CLEAR_ALL:
      return defaults.projects;
    case LOCATION_CHANGE:
      return { ...state, survey: false, survey_open: false, quickLook: false };
    default:
      return state;
  }
};

export default projectsReducer;