import {
  ADD_PROFILES,
  ADD_PROFILE,
  DELETE_PROFILE,
  UPDATE_PROFILE,
  FETCHING_PROFILES,
  FETCHED_PROFILES,
  ADD_PROJECTS,
  CLEAR_ALL,
  CREATING_PROFILE,
  UPDATING_PROFILE
} from "../actions/constants";
import defaults from "./defaults";

const profilesReducer = (state = defaults.profiles, { type, payload }) => {
  switch (type) {
    case ADD_PROFILES:
      return { ...state, list: { ...state.list, [payload.project_id]: payload.list } };
    case ADD_PROFILE:
      const existing_profiles_add = (state.list[payload.project_id] || []).filter((p) => p.id !== payload.id);
      return { ...state, list: { ...state.list, [payload.project_id]: [...existing_profiles_add, payload] } };
    case UPDATE_PROFILE:
      const updating_target_list = state.list[payload.project_id];
      const updating_target = updating_target_list.find((d) => d.id === payload.id);
      const updating_filtered = updating_target_list.filter((d) => d.id !== payload.id);
      const updating_with_updates = { ...updating_target, ...payload };
      return { ...state, updating_profile: false, list: { ...state.list, [payload.project_id]: [...updating_filtered, updating_with_updates] } };
    case DELETE_PROFILE:
      const current = state.list[payload.project_id];
      const remaining = current.filter((d) => d.id !== payload.id);
      return { ...state, list: { ...state.list, [payload.project_id]: remaining } };
    case FETCHING_PROFILES:
      return { ...state, fetching: payload };
    case FETCHED_PROFILES:
      return { ...state, fetched: { ...state.fetched, [payload.project_id]: new Date() } };
    case ADD_PROJECTS:
      return { ...state, fetched: {}, fetching: false, list: {} };
    case CREATING_PROFILE:
      return { ...state, creating_profile: payload };
    case UPDATING_PROFILE:
      return { ...state, updating_profile: payload };
    case CLEAR_ALL:
      return defaults.profiles;
    default:
      return state;
  }
};

export default profilesReducer;