import { createBrowserHistory } from "history";
import { configureStore } from "@reduxjs/toolkit"
import {
  persistStore,
  persistReducer
} from "redux-persist";
import promise from "redux-promise-middleware";
import storage from "localforage";
import createRootReducer from "./reducers";
import defaults from "./reducers/defaults";
import LogRocket from "logrocket";
import thunk from "redux-thunk";

storage.config({ name: "Global Eleplan", storeName: "eleplan_db", driver: [storage.INDEXEDDB, storage.WEBSQL, storage.LOCALSTORAGE] });
const persistConfig = { key: "eleplan", storage, timeout: null, blacklist: ["router"] };
const history = createBrowserHistory({ basename: "/" });
const persistedReducer = persistReducer(persistConfig, createRootReducer(history));
let middleware = [thunk, promise, LogRocket.reduxMiddleware()];
const store = configureStore({
  preloadedState: defaults,
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_STAGE !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    }).concat(middleware)
});

const persistor = persistStore(store);
export { store, history, persistor };