import {
  ADD_SEGMENTS,
  ADD_SEGMENT,
  UPDATE_SEGMENT,
  UPDATE_SEGMENTS,
  REMOVE_SEGMENT,
  FETCHING_SEGMENTS,
  FETCHED_SEGMENTS,
  CLEAR_ALL,
  ADD_PROJECTS
} from "../actions/constants";
import defaults from "./defaults";

const segmentsReducer = (state = defaults.segments, { type, payload }) => {
  switch (type) {
    case ADD_SEGMENTS:
      return { ...state, list: { ...state.list, [payload.project_id]: payload.list } };
    case ADD_SEGMENT:
      const existing_segments_add = state.list[payload.project_id].filter((p) => p.question_id !== payload.question_id);
      return { ...state, list: { ...state.list, [payload.project_id]: [...existing_segments_add, payload] } };
    case UPDATE_SEGMENT:
      const existing_segments_update = state.list[payload.project_id].filter((p) => p.question_id !== payload.question_id);
      return { ...state, list: { ...state.list, [payload.project_id]: [...existing_segments_update, payload] } };
    case UPDATE_SEGMENTS:
      const removed = state.list[payload.project_id].filter((p) => {
        const exists = payload.list.find((s) => s.question_id === p.question_id);
        if (exists) return false;
        return p;
      });
      return { ...state, list: { ...state.list, [payload.project_id]: [...removed, ...payload.list] } };
    case REMOVE_SEGMENT:
      const current = state.list[payload.project_id];
      const remaining = current.filter((d) => d.question_id !== payload.question_id);
      return { ...state, list: { ...state.list, [payload.project_id]: remaining } };
    case FETCHING_SEGMENTS:
      return { ...state, fetching: payload };
    case FETCHED_SEGMENTS:
      return { ...state, fetched: { ...state.fetched, [payload.project_id]: new Date() } };
    case ADD_PROJECTS:
      return { ...state, fetched: {}, fetching: false, list: {} };
    case CLEAR_ALL:
      return defaults.segments;
    default:
      return state;
  }
};

export default segmentsReducer;