import {
  ADD_ACCOUNTS,
  SET_CURRENT_ACCOUNT,
  SET_PRIMARY_ACCOUNT,
  FETCHING_ACCOUNTS,
  FETCHED_ACCOUNTS,
  LOGGING_OUT,
  CLEAR_ALL,
} from "../actions/constants";
import defaults from "./defaults";

const accountsReducer = (state = defaults.accounts, { type, payload }) => {
  switch (type) {
    case ADD_ACCOUNTS:
      return { ...state, list: payload };
    case SET_CURRENT_ACCOUNT:
      return { ...state, current: payload };
    case SET_PRIMARY_ACCOUNT:
      return { ...state, primary: payload };
    case FETCHING_ACCOUNTS:
      return { ...state, fetching: payload };
    case FETCHED_ACCOUNTS:
      return { ...state, fetched: payload };
    case LOGGING_OUT:
      return { ...state, logging_out: payload };
    case CLEAR_ALL:
      return defaults.accounts;
    default:
      return state;
  }
};

export default accountsReducer;