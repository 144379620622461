import { API } from "aws-amplify";
import { apiGateway } from "../../config";
import { store } from "../";
import {
  ADD_SEGMENTS,
  UPDATE_SEGMENT,
  UPDATE_SEGMENTS,
  FETCHING_SEGMENTS,
  FETCHED_SEGMENTS,
  FETCHING_PROJECT,
  FETCHED_PROJECT,
  LOADING_BLOCKS
} from "./constants";
import { uniq } from "lodash";
import { getPrompts } from "./projects";
const Gateway = apiGateway.find((gateway) => gateway.name === "client");

export const getSegments = (project_id, override) => async (dispatch) => {
  if ((!store.getState().segments.fetching && !store.getState().segments.fetched[project_id]) || override) {
    dispatch({ type: FETCHING_SEGMENTS, payload: true });
    return API.get(Gateway.name, `/segments/get-all/${project_id}/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`,
      {
        headers: {
          Authorization: store.getState().session.token
        }
      })
      .then((data) => {
        dispatch({ type: ADD_SEGMENTS, payload: { list: data.payload, project_id } });
        dispatch({ type: FETCHING_SEGMENTS, payload: false });
        dispatch({ type: FETCHED_SEGMENTS, payload: { status: true, project_id } });
        return data;
      })
      .catch((error) => {
        dispatch({ type: FETCHING_SEGMENTS, payload: false });
        dispatch({ type: FETCHED_SEGMENTS, payload: { status: true, project_id } });
        return {
          success: false,
          message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
        };
      });
  }
};

export const createSegments = ({ total_progress, segments, data, block_id, project_id, headings, complete, next_questions, new_times }) => async (dispatch) => {
  dispatch({ type: LOADING_BLOCKS, payload: uniq(segments.map((s) => s.page_id)) });
  return API.post(Gateway.name, `/segments/create/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`, {
    headers: {
      Authorization: store.getState().session.token,
    },
    body: {
      total_progress,
      segments,
      data,
      block_id,
      project_id,
      headings,
      complete,
      next_questions,
      new_times
    },
  })
    .then((segments) => {
      dispatch({ type: FETCHING_PROJECT, payload: false });
      dispatch({ type: FETCHED_PROJECT, payload: true });
      return segments;
    })
    .catch((error) => {
      dispatch({ type: FETCHING_PROJECT, payload: false });
      dispatch({ type: FETCHED_PROJECT, payload: true });

      return {
        success: false,
        message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
      };
    });
};

export const updateSegment = (segment_id, project_id, block_id, updates) => async (dispatch) => {
  return API.patch(Gateway.name, `/segments/update/${segment_id}/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`, {
    headers: {
      Authorization: store.getState().session.token,
    },
    body: {
      updates,
      project_id,
      block_id
    },
  })
    .then((updated) => {
      dispatch({ type: UPDATE_SEGMENT, payload: updated.payload });
      return updated;
    })
    .catch((error) => {
      return {
        success: false,
        message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
      };
    });
};

export const updateSegments = (updated_segments, project_id, block_id) => async (dispatch) => {
  dispatch({ type: FETCHING_SEGMENTS, payload: true });
  return API.post(Gateway.name, `/segments/get/${project_id}/${block_id}/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`, {
    headers: {
      Authorization: store.getState().session.token,
    },
    body: {
      question_ids: updated_segments
    },
  })
    .then((updated) => {
      dispatch({ type: UPDATE_SEGMENTS, payload: { list: updated.payload, project_id } });
      dispatch({ type: FETCHED_SEGMENTS, payload: { status: true, project_id } });
      dispatch({ type: FETCHING_SEGMENTS, payload: false });
      dispatch(getPrompts(project_id, true));
      return updated;
    })
    .catch((error) => {
      dispatch({ type: FETCHED_SEGMENTS, payload: { status: true, project_id } });
      dispatch({ type: FETCHING_SEGMENTS, payload: false });
      return {
        success: false,
        message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
      };
    });
};