import {
  ADD_RESPONSES,
  ADD_RESPONSE,
  UPDATE_RESPONSE,
  REMOVE_RESPONSE,
  FETCHING_RESPONSES,
  FETCHED_RESPONSES,
  CLEAR_ALL,
  ADD_PROJECTS
} from "../actions/constants";
import defaults from "./defaults";

const responsesReducer = (state = defaults.responses, { type, payload }) => {
  switch (type) {
    case ADD_RESPONSES:
      return { ...state, list: { ...state.list, [payload.project_id]: payload.list } };
    case ADD_RESPONSE:
      const existing_responses_add = state.list[payload.project_id]?.filter((p) => p.page_id !== payload.page_id);
      return { ...state, list: { ...state.list, [payload.project_id]: [...existing_responses_add, payload] } };
    case UPDATE_RESPONSE:
      if (payload.has_updates || payload.silent) {
        const existing_responses_update = state.list[payload.project_id]?.filter((p) => p.page_id !== payload.page_id);
        return { ...state, list: { ...state.list, [payload.project_id]: [...existing_responses_update, payload] }, fetched: { ...state.fetched, [payload.project_id]: new Date() } };
      }
      return { ...state, fetched: { ...state.fetched, [payload.project_id]: new Date() } };
    case REMOVE_RESPONSE:
      const current = state.list[payload.project_id];
      const remaining = current?.filter((d) => d.page_id !== payload.page_id);
      return { ...state, list: { ...state.list, [payload.project_id]: remaining } };
    case FETCHING_RESPONSES:
      return { ...state, fetching: payload };
    case FETCHED_RESPONSES:
      return { ...state, fetched: { ...state.fetched, [payload.project_id]: new Date() } };
    case ADD_PROJECTS:
      return { ...state, fetched: {}, fetching: false, list: {} };
    case CLEAR_ALL:
      return defaults.responses;
    default:
      return state;
  }
};

export default responsesReducer;