const admin = {
  fetched_accounts: false,
  fetching_accounts: false,
  fetching_settings: false,
  fetched_settings: false,
  fetching_subscriptions: false,
  fetched_subscriptions: false,
  fetching_users: false,
  fetched_users: false,
  fetching_projects: false,
  fetched_projects: false,
  fetching_costs: false,
  fetched_costs: false,
  fetching_prompts: false,
  fetched_prompts: false
};

const authentication = {
  user: false,
  cognito: false,
  fetched: false,
  fetching: false,
  auto_verifying_phone: false
};

const multi_part_form = {
  slide: 0,
  steps_status: {}
}

const loader = {
  show: false,
  message: "",
  timer: 30000,
  cancel: false
};

const plans = {
  list: [],
  last_fetched: null,
  fetched: false,
  fetching: false
};

const router = {
  pathname: "",
  search: "",
  hash: "",
  state: null,
  key: ""
};

const session = {
  account: false,
  customer: {},
  authorized: false,
  token: null,
  tab: null,
  ably_token: false,
  online_status: false,
  theme: "light",
  menu: false,
  launched: false,
  onboarded: false,
  iosPrompted: false,
  workerActive: false,
  hide_bars: false,
  last_logged_in: false,
  logging_out: false,
  device_id: false,
  unread_chats: 0,
  settings: false,
  idle: false,
  last_active: null,
  locked: {
    show: false,
    inactive: false
  },
  onboarding_step: 0,
  allow_lock_screen: false,
  remember_me: true,
  authed: false,
  showFocusChat: false,
  onboarding_people: false,
  onboarding_documents: false,
  onboarding_categories: false,
  onboarding_welcome: false,
  onboarding_done: false
};

const accounts = {
  list: [],
  current: false,
  primary: false,
  fetched: false,
  fetching: false
};

const users = {
  list: [],
  fetched: false,
  fetching: false,
  fetched_all: null
};

const transactions = {
  list: [],
  fetched: false,
  fetching: false,
  fetched_all: null
};

const notification = {
  message: "",
  timeout: 10000,
  action: "",
  button_text: "",
  hide_close: true,
  type: "", // error, success, warning, danger
  pages: [] // what pages should this display on? empty array will show on all pages
};

const projects = {
  list: {},
  fetched: false,
  fetching: false,
  survey: false,
  survey_open: false,
  loading_blocks: [],
  document: [],
  original_document: [],
  promptSuccess: { show: false },
  fetched_all: null,
  adaptive: { show: false, config: {} },
  plan_complete: false,
  quickLook: false
};

const blocks = {
  list: [],
  fetched: {},
  fetching: false
};

const responses = {
  list: {},
  fetched: {},
  fetching: false
};

const segments = {
  list: {},
  fetched: {},
  fetching: false
};

const prompts = {
  list: {},
  fetched: {},
  fetching: false,
  prompt_reminders: 0
};

const medications = {
  list: {},
  fetched: {},
  fetching: false,
  creating_medication: false,
  fetched_all: null
};

const contacts = {
  list: {},
  fetched: {},
  fetching: false,
  creating_contact: false,
  fetched_all: null
};

const profiles = {
  list: {},
  fetched: {},
  fetching: false
};

const documents = {
  list: {},
  fetched: {},
  fetching: false,
  creating_document: false,
  usage: {},
  fetched_all: null
};

const modal = {
  show: false,
  data: {}
};

const defaults = {
  admin,
  session,
  accounts,
  users,
  contacts,
  authentication,
  loader,
  notification,
  projects,
  blocks,
  responses,
  segments,
  prompts,
  documents,
  medications,
  router,
  modal,
  plans,
  multi_part_form,
  profiles,
  transactions
};

export default defaults;
