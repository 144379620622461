const defaults = {
  system: {
    name: process.env.REACT_APP_NAME,
    IDB_NAME: process.env.REACT_APP_IDB_NAME,
    IDB_STORE: process.env.REACT_APP_IDB_STORE
  },
  s3: {
    REGION: process.env.REACT_APP_REGION,
    BUCKET: `${process.env.REACT_APP_STAGE}-uploads-bucket`
  },
  apiGateway: [
    {
      region: process.env.REACT_APP_REGION,
      endpoint: `https://${process.env.REACT_APP_STAGE}-api.${process.env.REACT_APP_API_BASE}/client`,
      name: "client",
    }
  ],
  cognito: {
    REGION: process.env.REACT_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID
  },
  crisp: {
    CRISP_SITE_ID: process.env.REACT_APP_CRISP_SITE_ID
  },
  stripe: {
    STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY
  },
  google: {
    GOOGLE_ANALYTICS_TRACKING_ID: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID,
    FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY
  }
};

const environments = {
  development: {
    system: {
      name: "eleplan",
      IDB_NAME: "eleplan-survey-db",
      IDB_STORE: "idb"
    },
    s3: {
      REGION: "us-east-1",
      BUCKET: "development-uploads-bucket"
    },
    apiGateway: [
      {
        region: process.env.REACT_APP_REGION,
        endpoint: !process.env.REACT_APP_LOCAL ? `https://development-api.${process.env.REACT_APP_API_BASE}/client` : "http://localhost:3100",
        name: "client",
      }
    ],
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_PolCAHOmW",
      APP_CLIENT_ID: "6frilk7mkvank7esvbudmhgv66",
      IDENTITY_POOL_ID: "us-east-1:9ffc52ef-7f3f-4ca8-b3ea-9acf8926013e"
    },
    crisp: {
      CRISP_SITE_ID: "270b5e86-7525-4648-8655-a30c58306edc"
    },
    stripe: {
      STRIPE_PUBLIC_KEY: "pk_test_51OHDsrAMB6p3TXwRokoqoGcx8mPvWHCZAIMlbSbWBHZI7ez5lnFMdEXfnpxiGoONnjr8mqyKt9aE4W6tcdL3dA2a00rtrNchSH"
    },
    google: {
      GOOGLE_ANALYTICS_TRACKING_ID: "G-VLFQT7K5TC",
      FIREBASE_API_KEY: ""
    }
  },
  production: defaults,
  staging: defaults
};
module.exports = environments[process.env.REACT_APP_STAGE || "development"];