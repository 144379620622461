import { API } from "aws-amplify";
import { apiGateway } from "../../config";
import { store } from "../";
import {
  ADD_RESPONSES,
  UPDATE_RESPONSE,
  FETCHING_RESPONSES,
  FETCHED_RESPONSES,
  LOADING_BLOCKS
} from "./constants";
const Gateway = apiGateway.find((gateway) => gateway.name === "client");

export const getResponses = (project_id, override) => async (dispatch) => {
  if ((!store.getState().responses.fetching && !store.getState().responses.fetched[project_id]) || override) {
    dispatch({ type: FETCHING_RESPONSES, payload: true });
    return API.get(Gateway.name, `/block-responses/get-all/${project_id}/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`,
      {
        headers: {
          Authorization: store.getState().session.token
        }
      })
      .then((data) => {
        dispatch({ type: ADD_RESPONSES, payload: { list: data.payload, project_id } });
        dispatch({ type: FETCHING_RESPONSES, payload: false });
        dispatch({ type: FETCHED_RESPONSES, payload: { status: true, project_id } });
        return data;
      })
      .catch((error) => {
        dispatch({ type: FETCHING_RESPONSES, payload: false });
        dispatch({ type: FETCHED_RESPONSES, payload: { status: true, project_id } });
        return {
          success: false,
          message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
        };
      });
  }
};

export const updateBlockResponse = (response_id, page_id, updates, silent = false) => async (dispatch) => {
  if (!silent) dispatch({ type: LOADING_BLOCKS, payload: [page_id] });
  return API.patch(Gateway.name, `/block-responses/update/${response_id}/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`, {
    headers: {
      Authorization: store.getState().session.token,
    },
    body: {
      updates
    },
  })
    .then((updated) => {
      dispatch({ type: UPDATE_RESPONSE, payload: { ...updated.payload, silent } });
      return updated;
    })
    .catch((error) => {
      return {
        success: false,
        message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
      };
    });
};