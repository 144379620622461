import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  CLEAR_ALL,
} from "../actions/constants";
import defaults from "./defaults";

const notificationReducer = (state = defaults.notification, { type, payload }) => {
  switch (type) {
    case SHOW_NOTIFICATION:
      return { ...state, show: true, notification_config: payload };
    case HIDE_NOTIFICATION:
      return defaults.notification;
    case CLEAR_ALL:
      return defaults.notification;
    default:
      return state;
  }
};

export default notificationReducer;
