import {
  ADD_DOCUMENTS,
  ADD_DOCUMENT,
  DELETE_DOCUMENT,
  UPDATE_SINGLE_DOCUMENT,
  REMOVE_DOCUMENT,
  FETCHING_DOCUMENTS,
  FETCHED_DOCUMENTS,
  ADD_PROJECTS,
  UPDATE_DOCUMENT_USAGE,
  CLEAR_ALL,
  CREATING_DOCUMENT,
  UPDATING_DOCUMENT,
  UPDATE_DOCUMENT_PROPS,
  DELETE_DOCUMENT_BY_TAG
} from "../actions/constants";
import defaults from "./defaults";

const documentsReducer = (state = defaults.documents, { type, payload }) => {
  switch (type) {
    case ADD_DOCUMENTS:
      return { ...state, list: { ...state.list, [payload.project_id]: payload.list }, fetched_all: new Date() };
    case ADD_DOCUMENT:
      const existing_documents_add = (state.list[payload.project_id] || []).filter((p) => p.filename !== payload.filename);
      return { ...state, list: { ...state.list, [payload.project_id]: [...existing_documents_add, payload] } };
    case DELETE_DOCUMENT:
      const usage_before = state.usage[payload.project_id];
      const new_usage = usage_before - payload.size;
      const after_delete = (state.list[payload.project_id] || []).filter((p) => p.filename !== payload.filename);
      return { ...state, usage: { ...state.usage, [payload.project_id]: new_usage }, list: { ...state.list, [payload.project_id]: after_delete } };
    case DELETE_DOCUMENT_BY_TAG:
      const tag_usage_before = state.usage[payload.project_id];
      const tag_new_usage = tag_usage_before - payload.size;
      const tag_after_delete = (state.list[payload.project_id] || []).filter((p) => !p.tags.includes(payload.tag));
      return { ...state, usage: { ...state.usage, [payload.project_id]: tag_new_usage }, list: { ...state.list, [payload.project_id]: tag_after_delete } };
    case UPDATE_SINGLE_DOCUMENT:
      const updating_target_list = state.list[payload.project_id];
      const updating_target = updating_target_list.find((d) => d.id === payload.id);
      const updating_filtered = updating_target_list.filter((d) => d.id !== payload.id);
      const updating_with_updates = { ...updating_target, ...payload };
      return { ...state, updating_document: false, list: { ...state.list, [payload.project_id]: [...updating_filtered, updating_with_updates] } };
    case REMOVE_DOCUMENT:
      const current = state.list[payload.project_id];
      const remaining = current.filter((d) => d.filename !== payload.filename);
      return { ...state, list: { ...state.list, [payload.project_id]: remaining } };
    case UPDATE_DOCUMENT_PROPS:
      const target_list = state.list[payload.project_id];
      const target = target_list.find((d) => d.filename === payload.key);
      const filtered = target_list.filter((d) => d.filename !== payload.key);
      const with_updates = { ...target, ...payload.updates };
      return { ...state, list: { ...state.list, [payload.project_id]: [...filtered, with_updates] } };
    case FETCHING_DOCUMENTS:
      return { ...state, fetching: payload };
    case FETCHED_DOCUMENTS:
      return { ...state, fetched: { ...state.fetched, [payload.project_id]: new Date() } };
    case ADD_PROJECTS:
      return { ...state, fetched: {}, fetching: false, list: {} };
    case CREATING_DOCUMENT:
      return { ...state, creating_document: payload };
    case UPDATING_DOCUMENT:
      return { ...state, updating_document: payload };
    case UPDATE_DOCUMENT_USAGE:
      return { ...state, usage: { ...state.usage, [payload.project_id]: payload.usage } }
    case CLEAR_ALL:
      return defaults.documents;
    default:
      return state;
  }
};

export default documentsReducer;