import {
  ADD_MEDICATIONS,
  ADD_MEDICATION,
  DELETE_MEDICATION,
  UPDATE_MEDICATION,
  FETCHING_MEDICATIONS,
  FETCHED_MEDICATIONS,
  ADD_PROJECTS,
  CLEAR_ALL,
  CREATING_MEDICATION,
  UPDATING_MEDICATION
} from "../actions/constants";
import defaults from "./defaults";

const medicationsReducer = (state = defaults.medications, { type, payload }) => {
  switch (type) {
    case ADD_MEDICATIONS:
      return { ...state, list: { ...state.list, [payload.project_id]: payload.list }, fetched_all: new Date() };
    case ADD_MEDICATION:
      const existing_medications_add = (state.list[payload.project_id] || []).filter((p) => p.id !== payload.id);
      return { ...state, list: { ...state.list, [payload.project_id]: [...existing_medications_add, payload] } };
    case UPDATE_MEDICATION:
      const updating_target_list = state.list[payload.new.project_id];
      const updating_filtered = updating_target_list.filter((d) => d.id !== payload.medication_id);
      return { ...state, updating_medication: false, list: { ...state.list, [payload.new.project_id]: [...updating_filtered, payload.new] } };
    case DELETE_MEDICATION:
      const current = state.list[payload.project_id];
      const remaining = current.filter((d) => d.id !== payload.id);
      return { ...state, list: { ...state.list, [payload.project_id]: remaining } };
    case FETCHING_MEDICATIONS:
      return { ...state, fetching: payload };
    case FETCHED_MEDICATIONS:
      return { ...state, fetched: { ...state.fetched, [payload.project_id]: new Date() } };
    case ADD_PROJECTS:
      return { ...state, fetched: {}, fetching: false, list: {} };
    case CREATING_MEDICATION:
      return { ...state, creating_medication: payload };
    case UPDATING_MEDICATION:
      return { ...state, updating_medication: payload };
    case CLEAR_ALL:
      return defaults.medications;
    default:
      return state;
  }
};

export default medicationsReducer;