//Root constants
export const CLEAR_ALL = "CLEAR_ALL";
export const LOCATION_CHANGE = "LOCATION_CHANGE";

//Account constants
export const ADD_ACCOUNTS = "ADD_ACCOUNTS";
export const SET_CURRENT_ACCOUNT = "SET_CURRENT_ACCOUNT";
export const SET_PRIMARY_ACCOUNT = "SET_PRIMARY_ACCOUNT";
export const FETCHING_ACCOUNTS = "FETCHING_ACCOUNTS";
export const FETCHED_ACCOUNTS = "FETCHED_ACCOUNTS";

//User constants
export const GET_USERS = "GET_USERS";
export const ADD_USER = "ADD_USER";
export const DELETE_USER = "DELETE_USER";
export const FETCHING_USERS = "FETCHING_USERS";
export const FETCHED_USERS = "FETCHED_USERS";

// Contacts constants
export const ADD_CONTACTS = "ADD_CONTACTS";
export const ADD_CONTACT = "ADD_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const DELETE_CONTACT = "REMOVE_CONTACT";
export const FETCHING_CONTACTS = "FETCHING_CONTACTS";
export const FETCHED_CONTACTS = "FETCHED_CONTACTS";
export const CREATING_CONTACT = "CREATING_CONTACT";
export const UPDATING_CONTACT = "UPDATING_CONTACT";

// Session constants
export const AUTO_VERIFYING_PHONE = "AUTO_VERIFYING_PHONE";
export const LOGGING_OUT = "LOGGING_OUT";
export const IS_LOGGED_IN = "IS_LOGGED_IN";
export const IS_LOGGED_OUT = "IS_LOGGED_OUT";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const SET_TOKEN = "SET_TOKEN";
export const SET_ABLY_TOKEN = "SET_ABLY_TOKEN";
export const SET_CREDITS = "SET_CREDITS";
export const SET_TAB = "SET_TAB";
export const UPDATE_APP_VERSION = "UPDATE_APP_VERSION";
export const UPDATE_ONLINE_STATUS = "UPDATE_ONLINE_STATUS";
export const NEW_VERSION = "NEW_VERSION";
export const SET_THEME = "SET_THEME";
export const TOGGLE_MENU = "TOGGLE_MENU";
export const SET_LAUNCHED = "SET_LAUNCHED";
export const SET_ONBOARDED = "SET_ONBOARDED";
export const SET_IOS_PROMPTED = "SET_IOS_PROMPTED";
export const WORKER_ACTIVE = "WORKER_ACTIVE";
export const HIDE_BARS = "HIDE_BARS";
export const UPDATE_DEVICE_ID = "UPDATE_DEVICE_ID";
export const UPDATE_CHAT_BADGE = "UPDATE_CHAT_BADGE";
export const SET_SETTINGS = "SET_SETTINGS";
export const SET_IDLE = "SET_IDLE";
export const SET_LOCKED = "SET_LOCKED";
export const SET_ALLOW_LOCK_SCREEN = "SET_ALLOW_LOCK_SCREEN";
export const REMEMBER_ME = "REMEMBER_ME";
export const SET_DEV_AUTH = "SET_DEV_AUTH";
export const SET_SMART_SCHEDULE_ACTIVATED = "SET_SMART_SCHEDULE_ACTIVATED";
export const SET_ONBOARDING_WELCOME = "SET_ONBOARDING_WELCOME";
export const SET_ONBOARDING_PEOPLE = "SET_ONBOARDING_PEOPLE";
export const SET_ONBOARDING_DOCUMENTS = "SET_ONBOARDING_DOCUMENTS";
export const SET_ONBOARDING_CATEGORIES = "SET_ONBOARDING_CATEGORIES";
export const SET_ONBOARDING_DONE = "SET_ONBOARDING_DONE";
export const FOCUS_CHAT_OPEN = "FOCUS_CHAT_OPEN";
export const SET_ONBOARDING_STEP = "SET_ONBOARDING_STEP";

// Authorization constants
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_CREDENTIALS = "UPDATE_CREDENTIALS";
export const FETCHING_USER = "FETCHING_USER";
export const FETCHED_USER = "FETCHED_USER";
export const FETCHING_CREDENTIALS = "FETCHING_CREDENTIALS";
export const FETCHED_CREDENTIALS = "FETCHED_CREDENTIALS";

// Loader constants
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

// Modal constants
export const HIDE_MODAL = "HIDE_MODAL";
export const SHOW_MODAL = "SHOW_MODAL";

// Notification constants
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";

// Project constants
export const ADD_PROJECTS = "ADD_PROJECTS";
export const ADD_PROJECT = "ADD_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const REMOVE_PROJECT = "REMOVE_PROJECT";
export const FETCHING_PROJECTS = "FETCHING_PROJECTS";
export const FETCHED_PROJECTS = "FETCHED_PROJECTS";
export const FETCHING_PROJECT = "FETCHING_PROJECT";
export const FETCHED_PROJECT = "FETCHED_PROJECT";
export const OPEN_SURVEY = "OPEN_SURVEY";
export const CLOSE_SURVEY = "CLOSE_SURVEY";
export const LOADING_BLOCKS = "LOADING_BLOCKS";
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";
export const SET_DOCUMENT = "SET_DOCUMENT";
export const RESET_DOCUMENT = "RESET_DOCUMENT";
export const STORE_PROJECT_SETTINGS = "STORE_PROJECT_SETTINGS";
export const SET_PROMPT_SUCCESS = "SET_PROMPT_SUCCESS";
export const BUILDING_SURVEYS = "BUILDING_SURVEYS";
export const SET_ADAPTIVE = "SET_ADAPTIVE";
export const PLAN_COMPLETE = "PLAN_COMPLETE";
export const QUICK_LOOK = "QUICK_LOOK";

// Block constants
export const ADD_BLOCKS = "ADD_BLOCKS";
export const ADD_BLOCK = "ADD_BLOCK";
export const UPDATE_BLOCK = "UPDATE_BLOCK";
export const REMOVE_BLOCK = "REMOVE_BLOCK";
export const FETCHING_BLOCKS = "FETCHING_BLOCKS";
export const FETCHED_BLOCKS = "FETCHED_BLOCKS";

// Block Response constants
export const ADD_RESPONSES = "ADD_RESPONSES";
export const ADD_RESPONSE = "ADD_RESPONSE";
export const UPDATE_RESPONSE = "UPDATE_RESPONSE";
export const REMOVE_RESPONSE = "REMOVE_RESPONSE";
export const FETCHING_RESPONSES = "FETCHING_RESPONSES";
export const FETCHED_RESPONSES = "FETCHED_RESPONSES";

// Segment constants
export const ADD_SEGMENTS = "ADD_SEGMENTS";
export const ADD_SEGMENT = "ADD_SEGMENT";
export const UPDATE_SEGMENT = "UPDATE_SEGMENT";
export const UPDATE_SEGMENTS = "UPDATE_SEGMENTS";
export const REMOVE_SEGMENT = "REMOVE_SEGMENT";
export const FETCHING_SEGMENTS = "FETCHING_SEGMENTS";
export const FETCHED_SEGMENTS = "FETCHED_SEGMENTS";

// Prompts constants
export const ADD_PROMPTS = "ADD_PROMPTS";
export const REMOVE_PROMPT = "REMOVE_PROMPT";
export const FETCHING_PROMPTS = "FETCHING_PROMPTS";
export const FETCHED_PROMPTS = "FETCHED_PROMPTS";

// Document constants
export const ADD_DOCUMENTS = "ADD_DOCUMENTS";
export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const DELETE_DOCUMENT_BY_TAG = "DELETE_DOCUMENT_BY_TAG";
export const UPDATE_DOCUMENT_PROPS = "UPDATE_DOCUMENT_PROPS";
export const UPDATE_SINGLE_DOCUMENT = "UPDATE_SINGLE_DOCUMENT";
export const UPDATE_DOCUMENTS = "UPDATE_DOCUMENTS";
export const REMOVE_DOCUMENT = "REMOVE_DOCUMENT";
export const FETCHING_DOCUMENTS = "FETCHING_DOCUMENTS";
export const FETCHED_DOCUMENTS = "FETCHED_DOCUMENTS";
export const UPDATE_DOCUMENT_USAGE = "UPDATE_DOCUMENT_USAGE";
export const CREATING_DOCUMENT = "CREATING_DOCUMENT";
export const UPDATING_DOCUMENT = "UPDATING_DOCUMENT";

// Medications constants
export const ADD_MEDICATIONS = "ADD_MEDICATIONS";
export const ADD_MEDICATION = "ADD_MEDICATION";
export const UPDATE_MEDICATION = "UPDATE_MEDICATION";
export const DELETE_MEDICATION = "REMOVE_MEDICATION";
export const FETCHING_MEDICATIONS = "FETCHING_MEDICATIONS";
export const FETCHED_MEDICATIONS = "FETCHED_MEDICATIONS";
export const CREATING_MEDICATION = "CREATING_MEDICATION";
export const UPDATING_MEDICATION = "UPDATING_MEDICATION";

// Multi Part Form constantsa
export const CHANGE_FORM_SLIDE = "CHANGE_FORM_SLIDE";
export const CHANGE_STEP_COMPLETION = "CHANGE_STEP_COMPLETION";
export const CLEAR_MULTI_PART_FORM = "CLEAR_MULTI_PART_FORM";

// Stripe constants
export const GET_ACCOUNT_CUSTOMER = "GET_ACCOUNT_CUSTOMER";
export const IS_FETCHING_CUSTOMER = "IS_FETCHING_CUSTOMER";

// Plans constants
export const GET_PLANS = "GET_PLANS";
export const FETCHING_PLANS = "FETCHING_PLANS";
export const FETCHED_PLANS = "FETCHED_PLANS";

// Profiles constants
export const ADD_PROFILES = "ADD_PROFILES";
export const ADD_PROFILE = "ADD_PROFILE";
export const DELETE_PROFILE = "DELETE_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const FETCHING_PROFILES = "FETCHING_PROFILES";
export const FETCHED_PROFILES = "FETCHED_PROFILES";
export const CREATING_PROFILE = "CREATING_PROFILE";
export const UPDATING_PROFILE = "UPDATING_PROFILE";

// Admin constants
export const FETCHING_ADMIN_ACCOUNTS = "FETCHING_ADMIN_ACCOUNTS";
export const FETCHED_ADMIN_ACCOUNTS = "FETCHED_ADMIN_ACCOUNTS";
export const FETCHING_ADMIN_SUBSCRIPTIONS = "FETCHING_ADMIN_SUBSCRIPTIONS";
export const FETCHED_ADMIN_SUBSCRIPTIONS = "FETCHED_ADMIN_SUBSCRIPTIONS";
export const FETCHING_ADMIN_USERS = "FETCHING_ADMIN_USERS";
export const FETCHED_ADMIN_USERS = "FETCHED_ADMIN_USERS";
export const FETCHING_ADMIN_PROJECTS = "FETCHING_ADMIN_PROJECTS";
export const FETCHED_ADMIN_PROJECTS = "FETCHED_ADMIN_PROJECTS";
export const FETCHING_ADMIN_COSTS = "FETCHING_ADMIN_COSTS";
export const FETCHED_ADMIN_COSTS = "FETCHED_ADMIN_COSTS";
export const FETCHING_ADMIN_SETTINGS = "FETCHING_ADMIN_SETTINGS";
export const FETCHED_ADMIN_SETTINGS = "FETCHED_ADMIN_SETTINGS";
export const FETCHING_ADMIN_PROMPTS = "FETCHING_ADMIN_PROMPTS";
export const FETCHED_ADMIN_PROMPTS = "FETCHED_ADMIN_PROMPTS";
export const FETCHING_ADMIN_BLOCKS = "FETCHING_ADMIN_BLOCKS";
export const FETCHED_ADMIN_BLOCKS = "FETCHED_ADMIN_BLOCKS";

// transactions constants
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const FETCHING_TRANSACTIONS = "FETCHING_TRANSACTIONS";
export const FETCHED_TRANSACTIONS = "FETCHED_TRANSACTIONS";