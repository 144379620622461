import phoneFormatter from "phone-formatter";
import { store } from "./store";
import { toastr } from "react-redux-toastr";
import { SET_LOCKED, UPDATE_DEVICE_ID, UPDATE_ONLINE_STATUS, WORKER_ACTIVE } from "./store/actions/constants";
import { createPushSubscription, getUser, updatePushSubscription } from "./store/actions/user";
import { deviceDetect, deviceType } from "react-device-detect";
import Resizer from "react-image-file-resizer";
import LogRocket from "logrocket";
import { v4 } from "uuid";
import ReactGA from "react-ga4";
import { getAuthChallenge, storeCredential, verifyCredential } from "./store/actions/authentication";
import { client } from "@passwordless-id/webauthn";
import moment from "moment";
import { showNotification } from "./store/actions/notification";

export const SMART_SCHEDULE_THRESHOLD = 7;
export const SMART_SCHEDULE_LOOKBACK = 30;
export const MAX_FILE_SIZE = 5000000;
export const DATA_THRESHOLD = 15;

export const addMetaTag = () => {
  if (process.env.REACT_APP_STAGE !== "production") {
    const existingMetaTags = document.querySelectorAll("meta[name='robots']");
    if (!existingMetaTags.length) {
      const meta = document.createElement("meta");
      meta.setAttribute("name", "robots");
      meta.setAttribute("content", "noindex, nofollow");
      document.head.appendChild(meta);
    }
  }
};

export const capitalize = (str, lower = false) =>
  ((lower ? str.toLowerCase() : str) || "").replace(/(^|\s)\S/g, (match) => match.toUpperCase());
  
export const formatUSPhoneNumber = (number) => {
  if (number) {
    const formatted = phoneFormatter.format(number, "+1NNNNNNNNNN");
    if (verifyPhoneFormat(formatted)) return formatted;
  }
  return "";
};

export const formatUSPhoneNumberPretty = (phoneNumber) => {
  if (phoneNumber) {
    const PHONE_NUMBER_FORMAT = "(NNN) NNN-NNNN";
    phoneNumber = phoneNumber.replace("+1", "");
    if (phoneNumber.length === 10) return phoneFormatter.format(phoneNumber, PHONE_NUMBER_FORMAT);
    return phoneNumber;
  }
  return "";
};
export const verifyEmailFormat = (email) => {
  const patt = new RegExp(/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/g);
  return patt.test(email);
};

export const verifyPhoneFormat = (phone) => {
  const patt = new RegExp(/^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
  return patt.test(phone);
};
export const allowNumbersOnly = (e) => {
  const code = (e.which) ? e.which : e.keyCode;
  if (code > 31 && (code < 48 || code > 57)) e.preventDefault();
};

export const allowNumbersAndDecimalsOnly = (e) => {
  const key = e.key || e.charCode;
  const isValidInput = /^\d$/.test(key) || key === '.'; // Check if the key is a number or a decimal point

  if (!isValidInput) {
    e.preventDefault(); // Prevent default behavior if the input is not valid
  }
};

export const limitNumberRange = (e, min, max) => {
  if (e.target.value < min) e.target.value = min;
  if (e.target.value > max) e.target.value = max;
};

export const limitInput = (event, limit) => {
  if ((limit > 0) && event.target.value.length > limit) event.preventDefault();
};

export const uniqueID = () => {
  return "_" + Math.random().toString(36).substr(2, 9);
};
export const generatePassword = (length = 8) => {
  let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) retVal += charset.charAt(Math.floor(Math.random() * n));
  return retVal;
};

export const getReadableFileSizeString = (fileSizeInBytes, granular) => {
  let i = -1;
  const byteUnits = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  do {
    fileSizeInBytes = fileSizeInBytes / 1024;
    i++;
  } while (fileSizeInBytes > 1024);
  return Math.max(fileSizeInBytes, 0.1).toFixed(granular ? 2 : 0) + byteUnits[i];
};
export const getReadableUserAddress = (user) => {
  let user_address = [];
  if (user.address) user_address.push(user.address);
  if (user.address2) user_address.push(user.address2);
  if (user.city) user_address.push(user.city);
  if (user.state) user_address.push(user.state);
  if (user.zip) user_address.push(user.zip);
  if (user_address.length) return user_address.join(", ");
  return false;
};
export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
export const getCardIcon = (type) => {
  switch (type) {
    case "amex":
      return { icon: "cc-amex", icon_type: "fab", color: "#336cae" };
    case "cartes bancaires":
      return { icon: "credit-card", icon_type: "fad", color: "#309469" };
    case "diners":
      return { icon: "cc-diners-club", icon_type: "fab", color: "#2d6aa6" };
    case "discover":
      return { icon: "cc-discover", icon_type: "fab", color: "#E55C20" };
    case "jcb":
      return { icon: "cc-jcb", icon_type: "fab", color: "#F9B101" };
    case "mastercard":
      return { icon: "cc-mastercard", icon_type: "fab", color: "#eb001b" };
    case "visa":
      return { icon: "cc-visa", icon_type: "fab", color: "#172274" };
    case "unionpay":
      return { icon: "credit-card-front", icon_type: "fad", color: "#327982" };
    default:
      return { icon: "credit-card", icon_type: "fad", color: null };
  }
};

export const extractNames = (obj) => {
  const names = [];
  for (const key in obj) {
    if (obj[key].hasOwnProperty("sub_items")) {
      const subItems = obj[key].sub_items;
      for (const subKey in subItems) {
        const page = subItems[subKey].page;
        if (page && page.show) {
          names.push(page.name);
        }
      }
    }
  }
  return names;
};

export const toDataURL = async (src) => {
  let blob = await fetch(src).then((r) => r.blob());
  let d = await new Promise((resolve) => {
    let reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
  return d;
};

export const getUserAge = (birthday) => {
  if (!birthday) return 0;
  const parsedBirthday = new Date(birthday);
  if (isNaN(parsedBirthday.getTime())) return 0;
  const ageDifMs = Date.now() - parsedBirthday.getTime();
  const ageDate = new Date(ageDifMs); // Convert the age difference to a date
  const age = Math.abs(ageDate.getUTCFullYear() - 1970); // Get the absolute difference in years
  return age;
};

export const getUserSurveyLanguage = (isProjectUser, pronouns) => {
  let language;
  if (!isProjectUser && pronouns === "female-pronoun") language = "hi";
  else if (!isProjectUser && pronouns === "male-pronoun") language = "eu";
  else if (!isProjectUser && pronouns === "nongender-pronoun") language = "default";
  else language = "default";
  if (isProjectUser) language = "pl";
  return language;
};

export const mergeArrays = (array1, array2, key) => {
  const resultArray = [...array1];
  for (const item of array2) {
    const index = resultArray.findIndex((element) => element[key] === item[key]);
    if (index !== -1) resultArray[index] = item;
    else resultArray.push(item);
  }
  return resultArray;
};

export const search = (fields, target, term, returning) => {
  if (!term) return [];
  const lowSearch = term.toLowerCase();
  const recursiveSearch = (item, keys) => {
    let index = 0;
    while (index < keys.length) {
      const key = keys[index];
      if (item[key]) {
        item = item[key];
        index++;
      } else {
        return false;
      }
    }
    return String(item).toLowerCase().includes(lowSearch);
  };
  return target.filter(function (item) {
    return fields.some((key) => {
      let string = item[key];
      if (key.includes(".")) {
        let nested_key = key.split(".");
        return recursiveSearch(item, nested_key);
      }
      return String(string).toLowerCase().includes(lowSearch);
    });
  }).map((item) => !returning ? item : item[returning]);
};

export const wordsToID = (string, delimiter = "_") => {
  if (string === null || string === undefined) {
    throw new Error("Input cannot be null or undefined");
  }
  
  if (typeof string !== "string") {
    throw new TypeError("Input must be a string");
  }
  
  const trimmedString = string.trim();
  const lowercaseString = trimmedString.toLowerCase();
  
  const replaced = lowercaseString.replace(/[^a-z0-9]/gi, delimiter);
  return replaced;
};

export const idToWords = (id, delimiter = "_") => {
  if (id === null || id === undefined) {
    throw new Error("Input cannot be null or undefined");
  }

  if (typeof id !== "string") {
    throw new TypeError("Input must be a string");
  }

  const replaced = id.replace(new RegExp(delimiter, "g"), " ");
  return replaced;
};

export const getRandomNeutralColor = () => {
  const min = 100; // Minimum value for each RGB component
  const max = 200; // Maximum value for each RGB component

  // Generate random values for red, green, and blue components
  const red = Math.floor(Math.random() * (max - min + 1)) + min;
  const green = Math.floor(Math.random() * (max - min + 1)) + min;
  const blue = Math.floor(Math.random() * (max - min + 1)) + min;

  // Construct the color string in the format 'rgb(r, g, b)'
  return `rgb(${red}, ${green}, ${blue})`;
};

const getMillisecondsPerUnit = (unit) => {
  const unitsInMilliseconds = {
    year: 31536000000,
    month: 2592000000,
    week: 604800000,
    day: 86400000,
    hour: 3600000,
    minute: 60000,
    second: 1000,
  };
  return unitsInMilliseconds[unit] || 1; // Default to 1 millisecond for unknown units
};

export const getURLParams = (key) => {
  const searchParams = new URLSearchParams(window.location.search);
  if (key) return searchParams.get(key);
  else return searchParams;
};

export const isTimeAgo = (amount, unit, timestamp) => {
  if (!timestamp) return false;
  const currentTimestamp = new Date().getTime();
  const targetTimestamp = new Date(timestamp).getTime();

  if (isNaN(targetTimestamp)) return false;
  const timeDifference = currentTimestamp - targetTimestamp;
  const millisecondsPerUnit = getMillisecondsPerUnit(unit);
  const timeAgoThreshold = amount * millisecondsPerUnit;
  return timeDifference > timeAgoThreshold;
};

export const checkOnlineStatus = () => {

  const runOffline = () => {
    store.dispatch({ type: UPDATE_ONLINE_STATUS, payload: false })
    toastr.error("You are offline.", "Looks like you're not connected to the internet. Please check your settings and try again.", {
      timeOut: null,
      attention: true,
      onAttentionClick: (id) => { },
      preventDuplicates: true,
      newestOnTop: true,
      showCloseButton: false,
      removeOnHover: false,
      closeOnToastrClick: false,
      closeOnShadowClick: false,
      tapToDismiss: false
    });
    toastr.removeByType("success");
  };

  const runOnline = () => {
    store.dispatch({ type: UPDATE_ONLINE_STATUS, payload: true })
    toastr.success("You are back online.", "Looks like you're back online and your connection is stable.", { timeOut: 10000 });
    toastr.removeByType("error");
  };

  window.addEventListener("online", () => runOnline(), { once: true });
  window.addEventListener("offline", () => runOffline(), { once: true });

  if (navigator?.onLine) {
    store.dispatch({ type: UPDATE_ONLINE_STATUS, payload: true });
    toastr.removeByType("error");
    return true;
  } else {
    runOffline();
    return false;
  }
};

export const surveys = {
  project_creation: "e85be630-5631-411f-bedf-9ecda1422e1b",
  registration: "a45c0fac-2310-4993-877a-105ab6e60092",
  invites: "d017bd03-4239-40e3-8e66-fc16443aed8a",
  forgot: "81a93236-4505-4b0e-8f20-4f4b88790728"
};

export const requestPermission = async (reset) => {
  const { user } = store.getState().authentication;
  const { account } = store.getState().session;

  if (reset) await unsubscribe(true);

  try {
    if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
      console.error("Service worker or Push manager not supported");
      return false;
    }

    const registration = await navigator.serviceWorker.register("service-worker.js");

    if (registration.active) {
      // Service worker is already active
    } else {
      // Wait for service worker to become active
      await new Promise(resolve => {
        registration.addEventListener('updatefound', () => {
          const newWorker = registration.installing;
          if (newWorker) {
            newWorker.addEventListener('statechange', () => {
              if (newWorker.state === 'activated') {
                resolve();
              }
            });
          }
        });
      });
    }

    store.dispatch({ type: WORKER_ACTIVE, payload: true });

    if (Notification.permission !== "granted") {
      console.error("Permission not granted, requesting permission: ", Notification.permission);
      const permissionResult = await Notification.requestPermission();
      if (permissionResult !== "granted") {
        console.error("Permission not granted: ", permissionResult);
        return false;
      }
      console.error("Permission granted: ", permissionResult);
    }

    const subscribeOptions = {
      userVisibleOnly: true,
      applicationServerKey: process.env.REACT_APP_PUBLIC_VAPID_KEY,
    };

    const device = { ...deviceDetect(), deviceType };
    const pushManager = registration.pushManager;
    const pushSubscription = await pushManager.subscribe(subscribeOptions);

    let device_id = store.getState().session.device_id;
    if (!device_id) {
      device_id = v4();
      store.dispatch({ type: UPDATE_DEVICE_ID, payload: device_id });
    }
    const created = await createPushSubscription(pushSubscription, { device, device_id });

    if (created.success) {
      await getUser(user.cognito_id, account.account_id, true);
      toastr.success("Push Notifications Active", "Push notifications successfully enabled on this device.");
    } else {
      console.error("Push Notifications Inactive", created.message);
      toastr.error("Push Notifications Inactive", created.message);
    }

    return created.success;
  } catch (error) {
    console.error("Service Worker Registration Error:", error.message);
    store.dispatch({ type: WORKER_ACTIVE, payload: false });
    return false;
  }
};

export const unsubscribe = async (force = false) => {
  const { user } = store.getState().authentication;
  const { account } = store.getState().session;

  try {
    if (!('serviceWorker' in navigator)) {
      console.error("Service worker not supported");
      return false;
    }

    if (!('PushManager' in window)) {
      console.error("Push manager not supported");
      return false;
    }

    if (Notification?.permission !== "granted") {
      console.error("Permission not granted: ", Notification.permission);
      return false;
    }

    const registrations = await navigator.serviceWorker.getRegistrations();

    if (!registrations.length) {
      console.log("No registered service workers. Registering...");
      await navigator.serviceWorker.register("service-worker.js");
    }
    
    const reg = await navigator.serviceWorker.ready;
    const subscription = await reg.pushManager.getSubscription();
    if (!subscription) {
      console.log("No push subscription found.");
      return false;
    }

    await subscription.unsubscribe();
    
    const updated = await updatePushSubscription(subscription, { status: "inactive" });
    await getUser(user.cognito_id, account.account_id, true);
    store.dispatch({ type: UPDATE_DEVICE_ID, payload: null });

    if (updated.success) {
      if (!force) toastr.error("Push Notifications Disabled", "Push notifications have been disabled on this device.");
    } else {
      console.error("Push Error", updated.message);
    }

    return updated;
  } catch (error) {
    console.error("Push Error", error);
    return false;
  }
};

export const checkPushSubscription = async () => {
  if (!('serviceWorker' in navigator)) {
    console.error("Service worker not supported");
    return false;
  }

  if (!('PushManager' in window)) {
    console.error("Push manager not supported");
    return false;
  }

  try {
    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration.pushManager.getSubscription();
    return subscription;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const NotificationIsSupported = 'Notification' in window || 'webkitNotifications' in window || 'mozNotification' in navigator;

export const isInStandaloneMode = () =>
  (window.matchMedia("(display-mode: standalone)").matches) || (window.navigator.standalone) || document.referrer.includes("android-app://");

export const fileToBlob = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.onerror = (error) => {
      console.error(error);
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

export const blobToDataURL = (blob, callback) => {
  var a = new FileReader();
  a.onload = function (e) { callback(e.target.result); }
  a.readAsDataURL(blob);
}

export const getCurrentTimezone = () => {
  const timeZoneOffset = new Date().getTimezoneOffset();
  const full = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const time_parts = new Intl.DateTimeFormat(undefined, { timeZoneName: "short" })?.formatToParts(new Date());
  const abbreviation = time_parts.find((part) => part.type === "timeZoneName").value;
  return { full, abbreviation, offset: timeZoneOffset };
};

export const dataURItoBlob = (dataurl) => {
  if (dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
  return null;
};

export const replaceFrom24To12Format = (match, hours, minutes, period) => {
  if (period) return `${hours}:${minutes} ${period}`;
  const periodToAdd = hours >= 12 ? " PM" : " AM";
  hours = (hours % 12) || 12;
  return `${hours}:${minutes}${periodToAdd}`;
};

export const convertFrom24To12Format = (time24) => {
  const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
  const period = +sHours < 12 ? "AM" : "PM";
  const hours = +sHours % 12 || 12;
  return `${hours}:${minutes} ${period}`;
};

export const isEmptyValue = (value) => {
  if (value === null || value === undefined) return true;
  if (typeof value === "string") return false;
  if (Array.isArray(value)) return false;
  if (typeof value === "number") return false;
  if (typeof value === "object") return false;
  if (typeof value === "boolean") return false;
  return false;
};

export const updateURLParams = (query_updates, callback) => {
  const currentSearchParams = new URLSearchParams(window.location.search);
  Object.keys(query_updates).forEach((key) => {
    const oldQuery = currentSearchParams.get(key) ?? '';
    const newQuery = query_updates[key];
    if (newQuery && oldQuery && (newQuery === oldQuery)) return;

    if (newQuery) {
      currentSearchParams.set(key, newQuery)
    } else {
      currentSearchParams.delete(key)
    }
  });
  const newUrl = [window.location.pathname, currentSearchParams.toString()]
    .filter(Boolean)
    .join('?')
  window.history.replaceState(null, '', newUrl);
  if (callback) callback();
};

export const scrollTo = (h, offset) => {
  if (h === "") {
    window.scrollTo(0, 0);
  } else {
    setTimeout(() => {
      const id = h.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center", offset });
        window.history.pushState("", document.title, window.location.pathname + window.location.search);
      }
    }, 120);
  }
};

export const clearObjectStore = (dbName, objectStoreName) => {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open(dbName);

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(objectStoreName, 'readwrite');
      const objectStore = transaction.objectStore(objectStoreName);

      const clearRequest = objectStore.clear();

      clearRequest.onsuccess = () => {
        console.log(`Cleared data in object store: ${objectStoreName}`);
        resolve();
      };

      clearRequest.onerror = (event) => {
        console.error(`Error clearing data in object store ${objectStoreName}:`, event.target.error);
        reject(event.target.error);
      };
    };

    request.onerror = (event) => {
      console.error(`Error opening database ${dbName} for clearing data:`, event.target.error);
      reject(event.target.error);
    };
  });
};

export const limitConcurrency = async (items, concurrencyLimit, asyncTask) => {
  const results = [];
  let currentIndex = 0;
  const processingQueue = [];

  const processNext = async () => {
    const item = items[currentIndex++];
    if (!item) return; // No more items to process

    console.log(`Processing item ${currentIndex} of ${items.length}`);

    const result = await asyncTask(item);
    results.push(result);

    // Remove the completed item from the processing queue
    const indexToRemove = processingQueue.indexOf(item);
    if (indexToRemove !== -1) {
      processingQueue.splice(indexToRemove, 1);
    }

    console.log(`Item ${currentIndex} completed`);

    // Start processing the next item
    await processNext();
  };

  const processItems = async () => {
    while (currentIndex < items.length || processingQueue.length > 0) {
      while (currentIndex < items.length && processingQueue.length < concurrencyLimit) {
        const item = items[currentIndex++];
        processingQueue.push(item);
        console.log(`Added item ${currentIndex} to the queue`);
        processNext(); // Start processing the item
      }
      await Promise.all(processingQueue.map(() => new Promise((resolve) => setTimeout(resolve, 100))));
    }
  };

  // Start processing the items
  await processItems();

  console.log("All items processed");

  return results;
};

export const waitForCondition = async (checkFunction, intervalMs, maxIterations, failureFunction) => {
  return new Promise((resolve, reject) => {
    let iterations = 0;

    const intervalId = setInterval(async () => {
      iterations++;

      if (await checkFunction()) {
        clearInterval(intervalId);
        resolve();
      } else if (maxIterations && iterations >= maxIterations) {
        clearInterval(intervalId);
        if (failureFunction) failureFunction();
        reject(new Error("Max iterations reached"));
      }
    }, intervalMs);
  });
};

export const inSequence = async (array) => {
  for (const promise of array) {
    await promise;
  }
};

export const checkUntil = (options) => {
  const {
    localStorageKey,
    maxIterations,
    intervalMs,
    startCondition,
    onSuccess,
  } = options;

  if (!startCondition(localStorage.getItem(localStorageKey))) {
    console.log("Condition not met. Exiting.");
    return;
  }

  let currentIteration = 0;

  const checkInterval = setInterval(() => {
    currentIteration++;
    const storedValue = localStorage.getItem(localStorageKey);

    if (storedValue) {
      clearInterval(checkInterval);
      if (onSuccess) onSuccess(storedValue);
    } else if (currentIteration >= maxIterations) {
      console.log("Maximum iterations reached. Exiting.");
      clearInterval(checkInterval);
    }
  }, intervalMs);
};

export const getJSONFromURL = async (url) => {
  const response = await fetch(url);
  return response.json();
};

export const sortByTime = (a, b) => {
  const timeA = a.raw.split(':').map(Number);
  const timeB = b.raw.split(':').map(Number);

  if (timeA[0] !== timeB[0]) {
    return timeA[0] - timeB[0]; // Compare hours
  } else {
    return timeA[1] - timeB[1]; // If hours are equal, compare minutes
  }
};

export const getOpenAIStatus = async () => {
  const url = "https://status.openai.com/api/v2/summary.json";
  const response = await getJSONFromURL(url);
  const { incidents = [], status } = response;
  const API = response.components.find((c) => c.id === "scd0s93nldpb")
  const latestIncident = incidents?.[0];
  if (!latestIncident) {
    return {
      API,
      last_check: Date.now(),
      latestIncident: null,
      status
    };
  }
  return {
    API,
    last_check: Date.now(),
    latestIncident,
    status
  };
};

export const getAblyStatus = async () => {
  const url = "https://ably-realtime.statuspage.io/api/v2/summary.json";
  const response = await getJSONFromURL(url);
  const { incidents = [], status } = response;
  const API = response.components.find((c) => c.id === "f7yl2s70zbtj")
  const latestIncident = incidents?.[0];
  if (!latestIncident) {
    return {
      API,
      last_check: Date.now(),
      latestIncident: null,
      status
    };
  }
  return {
    API,
    last_check: Date.now(),
    latestIncident,
    status
  };
};

export const resizeFile = (file, width = 300, height = 300, compression = "JPEG", quality = 100, rotation = 0, type = "base64") =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      compression,
      quality,
      rotation,
      (uri) => {
        resolve(uri);
      },
      type
    );
  });
export const checkConditions = (input, conditions) => {
  for (const condition of conditions) {
    if (!condition(input)) {
      return false;
    }
  }
  return true;
};

export const family = [
  { value: "brother", label: "Brother" },
  { value: "sister", label: "Sister" },
  { value: "father", label: "Father" },
  { value: "mother", label: "Mother" },
  { value: "son", label: "Son" },
  { value: "daughter", label: "Daughter" },
  { value: "grandfather", label: "Grandfather" },
  { value: "grandmother", label: "Grandmother" },
  { value: "grandson", label: "Grandson" },
  { value: "granddaughter", label: "Granddaughter" },
  { value: "uncle", label: "Uncle" },
  { value: "aunt", label: "Aunt" },
  { value: "cousin", label: "Cousin" },
  { value: "nephew", label: "Nephew" },
  { value: "niece", label: "Niece" },
  { value: "husband", label: "Husband" },
  { value: "wife", label: "Wife" },
  { value: "fiancé", label: "Fiancé" },
  { value: "partner", label: "Partner" },
  { value: "stepfather", label: "Stepfather" },
  { value: "stepmother", label: "Stepmother" },
  { value: "stepson", label: "Stepson" },
  { value: "stepdaughter", label: "Stepdaughter" },
  { value: "stepbrother", label: "Stepbrother" },
  { value: "stepsister", label: "Stepsister" },
  { value: "halfbrother", label: "Half-Brother" },
  { value: "halfsister", label: "Half-Sister" },
  { value: "father-in-law", label: "Father-in-Law" },
  { value: "mother-in-law", label: "Mother-in-Law" },
  { value: "brother-in-law", label: "Brother-in-Law" },
  { value: "sister-in-law", label: "Sister-in-Law" },
  { value: "son-in-law", label: "Son-in-Law" },
  { value: "daughter-in-law", label: "Daughter-in-Law" },
  { value: "godfather", label: "Godfather" },
  { value: "godmother", label: "Godmother" },
  { value: "godson", label: "Godson" },
  { value: "goddaughter", label: "Goddaughter" }
];
export const other = [
  { value: "friend", label: "Friend" },
  { value: "colleague", label: "Colleague" },
  { value: "boss", label: "Boss" },
  { value: "manager", label: "Manager" },
  { value: "work supervisor", label: "Work Supervisor" },
  { value: "employee", label: "Employee" },
  { value: "teacher", label: "Teacher" },
  { value: "student", label: "Student" },
  { value: "neighbor", label: "Neighbor" },
  { value: "roommate", label: "Roommate" },
  { value: "guardian", label: "Guardian" },
  { value: "attorney", label: "Attorney" },
  { value: "case manager", label: "Case Manager" },
  { value: "client", label: "Client" },
  { value: "patient", label: "Patient" },
  { value: "mentor", label: "Mentor" },
  { value: "mentee", label: "Mentee" },
  { value: "associate", label: "Associate" },
  { value: "supervisor", label: "Supervisor" },
  { value: "intern", label: "Intern" },
  { value: "coach", label: "Coach" },
  { value: "advisor", label: "Advisor" },
  { value: "consultant", label: "Consultant" },
  { value: "volunteer", label: "Volunteer" },
  { value: "vendor", label: "Vendor" },
  { value: "contractor", label: "Contractor" },
  { value: "landlord", label: "Landlord" },
  { value: "tenant", label: "Tenant" },
  { value: "caregiver", label: "Caregiver" },
  { value: "professional caregiver", label: "Professional Caregiver" },
  { value: "supervisee", label: "Supervisee" },
  { value: "patient advocate", label: "Patient Advocate" },
  { value: "caseworker", label: "Caseworker" },
  { value: "social worker", label: "Social Worker" },
  { value: "counselor", label: "Counselor" },
  { value: "nurse", label: "Nurse" },
  { value: "pharmacist", label: "Pharmacist" },
  { value: "care coordinator", label: "Care Coordinator" },
  { value: "peer support", label: "Peer Support" },
  { value: "instructor", label: "Instructor" },
  { value: "classmate", label: "Classmate" },
  { value: "volunteer coordinator", label: "Volunteer Coordinator" },
  { value: "teammate", label: "Teammate" },
  { value: "sponsor", label: "Sponsor" },
  { value: "nanny", label: "Nanny" },
  { value: "girlfriend", label: "Girlfriend" },
  { value: "employer", label: "Employer" },
  { value: "boyfriend", label: "Boyfriend" },
  { value: "babysitter", label: "Babysitter" },
  { value: "foster parent", label: "Foster Parent" },
  { value: "beneficiary", label: "Beneficiary" },
  { value: "recruiter", label: "Recruiter" },
  { value: "judge", label: "Judge" },
  { value: "mediator", label: "Mediator" },
  { value: "occupational therapist", label: "Occupational Therapist" },
  { value: "physical therapist", label: "Physical Therapist" },
  { value: "speech language pathologist", label: "Speech-Language Pathologist" },
  { value: "respiratory therapist", label: "Respiratory Therapist" },
  { value: "dietitian", label: "Dietitian" },
  { value: "chiropractor", label: "Chiropractor" },
  { value: "board certified behavioral analyst", label: "Board Certified Behavioral Analyst" },
  { value: "massage therapist", label: "Massage Therapist" },
  { value: "prosthetist", label: "Prosthetist" },
  { value: "recreational therapist", label: "Recreational Therapist" },
  { value: "art therapist", label: "Art Therapist" },
  { value: "music therapist", label: "Music Therapist" },
  { value: "cognitive behavioral therapist", label: "Cognitive Behavioral Therapist" },
  { value: "dialectical behavior therapist", label: "Dialectical Behavior Therapist" },
  { value: "tutor", label: "Tutor" }
];
export const physicians = [
  { label: "Primary Care Physician", value: "primary care physician" },
  { label: "Cardiologist", value: "cardiologist" },
  { label: "Dentist", value: "dentist" },
  { label: "Dermatologist", value: "dermatologist" },
  { label: "Endocrinologist", value: "endocrinologist" },
  { label: "Gastroenterologist", value: "gastroenterologist" },
  { label: "Hematologist", value: "hematologist" },
  { label: "Immunologist", value: "immunologist" },
  { label: "Nephrologist", value: "nephrologist" },
  { label: "Neurologist", value: "neurologist" },
  { label: "Obstetrician", value: "obstetrician" },
  { label: "Oncologist", value: "oncologist" },
  { label: "Ophthalmologist", value: "ophthalmologist" },
  { label: "Orthopedist", value: "orthopedist" },
  { label: "Otolaryngologist", value: "otolaryngologist" },
  { label: "Pediatrician", value: "pediatrician" },
  { label: "Psychiatrist", value: "psychiatrist" },
  { label: "Therapist", value: "therapist" },
  { label: "Pulmonologist", value: "pulmonologist" },
  { label: "Radiologist", value: "radiologist" },
  { label: "Rheumatologist", value: "rheumatologist" },
  { label: "Urologist", value: "urologist" },
  { label: "Allergist", value: "allergist" },
  { label: "Anesthesiologist", value: "anesthesiologist" },
  { label: "Cardiothoracic Surgeon", value: "cardiothoracic surgeon" },
  { label: "Colon and Rectal Surgeon", value: "colon and rectal surgeon" },
  { label: "Critical Care Specialist", value: "critical care specialist" },
  { label: "Emergency Medicine Physician", value: "emergency medicine physician" },
  { label: "Family Medicine Physician", value: "family medicine physician" },
  { label: "Geriatrician", value: "geriatrician" },
  { label: "Gynecologist", value: "gynecologist" },
  { label: "Infectious Disease Specialist", value: "infectious disease specialist" },
  { label: "Intensivist", value: "intensivist" },
  { label: "Interventional Radiologist", value: "interventional radiologist" },
  { label: "Medical Geneticist", value: "medical geneticist" },
  { label: "Neonatologist", value: "neonatologist" },
  { label: "Neurosurgeon", value: "neurosurgeon" },
  { label: "Occupational Medicine Physician", value: "occupational medicine physician" },
  { label: "Pathologist", value: "pathologist" },
  { label: "Physiatrist", value: "physiatrist" },
  { label: "Plastic Surgeon", value: "plastic surgeon" },
  { label: "Podiatrist", value: "podiatrist" },
  { label: "Proctologist", value: "proctologist" },
  { label: "Sleep Medicine Specialist", value: "sleep medicine specialist" },
  { label: "Sports Medicine Physician", value: "sports medicine physician" },
  { label: "Thoracic Surgeon", value: "thoracic surgeon" },
  { label: "Vascular Surgeon", value: "vascular surgeon" },
  { label: "Adolescent Medicine Specialist", value: "adolescent medicine specialist" },
  { label: "Clinical Pharmacologist", value: "clinical pharmacologist" },
  { label: "Cytopathologist", value: "cytopathologist" },
  { label: "Developmental-Behavioral Pediatrician", value: "developmental-behavioral pediatrician" },
  { label: "Diabetologist", value: "diabetologist" },
  { label: "Emergency Medical Specialist", value: "emergency medical specialist" },
  { label: "Hepatologist", value: "hepatologist" },
  { label: "Internal Medicine Specialist", value: "internal medicine specialist" },
  { label: "Maxillofacial Surgeon", value: "maxillofacial surgeon" },
  { label: "Nuclear Medicine Specialist", value: "nuclear medicine specialist" },
  { label: "Pain Management Specialist", value: "pain management specialist" },
  { label: "Sleep Specialist", value: "sleep specialist" },
  { label: "Transplant Surgeon", value: "transplant surgeon" },
  { label: "Travel Medicine Specialist", value: "travel medicine specialist" },
  { label: "Virologist", value: "virologist" },
  { label: "Bariatrician", value: "bariatrician" },
  { label: "Chemical Pathologist", value: "chemical pathologist" },
  { label: "Child and Adolescent Psychiatrist", value: "child and adolescent psychiatrist" },
  { label: "Clinical Neurophysiologist", value: "clinical neurophysiologist" },
  { label: "Gynecologic Oncologist", value: "gynecologic oncologist" },
  { label: "Hand Surgeon", value: "hand surgeon" },
  { label: "Hospice and Palliative Medicine Specialist", value: "hospice and palliative medicine specialist" },
  { label: "Infectious Disease Physician", value: "infectious disease physician" },
  { label: "Interventional Cardiologist", value: "interventional cardiologist" },
  { label: "Maternal-Fetal Medicine Specialist", value: "maternal-fetal medicine specialist" },
  { label: "Molecular Geneticist", value: "molecular geneticist" },
  { label: "Neurodevelopmental Disabilities Specialist", value: "neurodevelopmental disabilities specialist" },
  { label: "Osteopathic Physician", value: "osteopathic physician" },
  { label: "Pediatric Allergist/Immunologist", value: "pediatric allergist/immunologist" },
  { label: "Pediatric Cardiologist", value: "pediatric cardiologist" },
  { label: "Pediatric Critical Care Specialist", value: "pediatric critical care specialist" },
  { label: "Pediatric Emergency Medicine Specialist", value: "pediatric emergency medicine specialist" },
  { label: "Pediatric Endocrinologist", value: "pediatric endocrinologist" },
  { label: "Pediatric Gastroenterologist", value: "pediatric gastroenterologist" },
  { label: "Pediatric Hematologist-Oncologist", value: "pediatric hematologist-oncologist" },
  { label: "Pediatric Infectious Disease Specialist", value: "pediatric infectious disease specialist" },
  { label: "Pediatric Nephrologist", value: "pediatric nephrologist" },
  { label: "Pediatric Neurologist", value: "pediatric neurologist" },
  { label: "Pediatric Pulmonologist", value: "pediatric pulmonologist" },
  { label: "Pediatric Rheumatologist", value: "pediatric rheumatologist" },
  { label: "Pediatric Surgeon", value: "pediatric surgeon" },
  { label: "Reproductive Endocrinologist", value: "reproductive endocrinologist" },
  { label: "Cardiac Electrophysiologist", value: "cardiac electrophysiologist" },
  { label: "Dental Surgeon", value: "dental surgeon" },
  { label: "Gynecologic Surgeon", value: "gynecologic surgeon" },
  { label: "Ophthalmic Surgeon", value: "ophthalmic surgeon" },
  { label: "Orthopedic Surgeon", value: "orthopedic surgeon" },
  { label: "Trauma Surgeon", value: "trauma surgeon" },
  { label: "Spinal Cord Injury Specialist", value: "spinal cord injury specialist" }
];

export const states = [
  { value: "Alabama", label: "Alabama" },
  { value: "Alaska", label: "Alaska" },
  { value: "Arizona", label: "Arizona" },
  { value: "Arkansas", label: "Arkansas" },
  { value: "California", label: "California" },
  { value: "Colorado", label: "Colorado" },
  { value: "Connecticut", label: "Connecticut" },
  { value: "Delaware", label: "Delaware" },
  { value: "District Of Columbia", label: "District Of Columbia" },
  { value: "Florida", label: "Florida" },
  { value: "Georgia", label: "Georgia" },
  { value: "Hawaii", label: "Hawaii" },
  { value: "Idaho", label: "Idaho" },
  { value: "Illinois", label: "Illinois" },
  { value: "Indiana", label: "Indiana" },
  { value: "Iowa", label: "Iowa" },
  { value: "Kansas", label: "Kansas" },
  { value: "Kentucky", label: "Kentucky" },
  { value: "Louisiana", label: "Louisiana" },
  { value: "Maine", label: "Maine" },
  { value: "Maryland", label: "Maryland" },
  { value: "Massachusetts", label: "Massachusetts" },
  { value: "Michigan", label: "Michigan" },
  { value: "Minnesota", label: "Minnesota" },
  { value: "Mississippi", label: "Mississippi" },
  { value: "Missouri", label: "Missouri" },
  { value: "Montana", label: "Montana" },
  { value: "Nebraska", label: "Nebraska" },
  { value: "Nevada", label: "Nevada" },
  { value: "New Hampshire", label: "New Hampshire" },
  { value: "New Jersey", label: "New Jersey" },
  { value: "New Mexico", label: "New Mexico" },
  { value: "New York", label: "New York" },
  { value: "North Carolina", label: "North Carolina" },
  { value: "North Dakota", label: "North Dakota" },
  { value: "Ohio", label: "Ohio" },
  { value: "Oklahoma", label: "Oklahoma" },
  { value: "Oregon", label: "Oregon" },
  { value: "Pennsylvania", label: "Pennsylvania" },
  { value: "Rhode Island", label: "Rhode Island" },
  { value: "South Carolina", label: "South Carolina" },
  { value: "South Dakota", label: "South Dakota" },
  { value: "Tennessee", label: "Tennessee" },
  { value: "Texas", label: "Texas" },
  { value: "Utah", label: "Utah" },
  { value: "Vermont", label: "Vermont" },
  { value: "Virginia", label: "Virginia" },
  { value: "Washington", label: "Washington" },
  { value: "West Virginia", label: "West Virginia" },
  { value: "Wisconsin", label: "Wisconsin" },
  { value: "Wyoming", label: "Wyoming" }
];

export const units = [
  { label: 'Milligrams (mg)', value: 'mg' },
  { label: 'Micrograms (mcg)', value: 'mcg' },
  { label: 'Grams (g)', value: 'g' },
  { label: 'Milliliters (ml)', value: 'ml' },
  { label: 'MicroLiters (μl)', value: 'μl' },
  { label: 'Units', value: 'units' },
  { label: 'International Units (IU)', value: 'IU' },
  { label: 'Teaspoons (tsp)', value: 'tsp' },
  { label: 'Tablespoons (tbsp)', value: 'tbsp' },
  { label: 'Drops', value: 'drops' },
  { label: 'Puffs', value: 'puffs' },
  { label: 'Sprays', value: 'sprays' },
  { label: 'Pieces', value: 'pieces' },
  { label: 'Capsules', value: 'capsules' },
];

export const routes = [
  { label: 'Oral', value: 'oral' },
  { label: 'Sublingual', value: 'sublingual' },
  { label: 'Topical', value: 'topical' },
  { label: 'Transdermal', value: 'transdermal' },
  { label: 'Intravenous', value: 'intravenous' },
  { label: 'Intramuscular', value: 'intramuscular' },
  { label: 'Subcutaneous', value: 'subcutaneous' },
  { label: 'Inhalation', value: 'inhalation' },
  { label: 'Rectal', value: 'rectal' },
  { label: 'Vaginal', value: 'vaginal' },
  { label: 'Ophthalmic', value: 'ophthalmic' },
  { label: 'Otic', value: 'otic' },
  { label: 'Nasal', value: 'nasal' },
];

export const forms = [
  { label: 'Tablet', value: 'tablet' },
  { label: 'Capsule', value: 'capsule' },
  { label: 'Liquid', value: 'liquid' },
  { label: 'Solution', value: 'solution' },
  { label: 'Suspension', value: 'suspension' },
  { label: 'Drops', value: 'drops' },
  { label: 'Cream', value: 'cream' },
  { label: 'Ointment', value: 'ointment' },
  { label: 'Gel', value: 'gel' },
  { label: 'Patch', value: 'patch' },
  { label: 'Inhaler', value: 'inhaler' },
  { label: 'Injection', value: 'injection' },
  { label: 'Suppository', value: 'suppository' },
  { label: 'Lozenge', value: 'lozenge' },
  { label: 'Powder', value: 'powder' },
  { label: 'Granules', value: 'granules' },
  { label: 'Lotion', value: 'lotion' },
  { label: 'Spray', value: 'spray' },
];

export const frequencies = [
  { value: "daily", label: "Daily" },
  { value: "qod", label: "QOD (Every other day)" },
  { value: "weekly", label: "Weekly" },
  { value: "bi-weekly", label: "Bi-Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "as needed", label: "As Needed" },
  { value: "custom", label: "Custom" }
];

export const custom_frequencies = [
  { value: "hours", label: "Hours" },
  { value: "days", label: "Days" },
  { value: "months", label: "Months" },
  { value: "years", label: "Years" }
];

export const pharmacies = [
  { label: 'Walgreens', value: 'walgreens' },
  { label: 'CVS Pharmacy', value: 'cvs' },
  { label: 'Rite Aid', value: 'rite aid' },
  { label: 'Walmart Pharmacy', value: 'walmart' },
  { label: 'Target (CVS)', value: 'target cvs' },
  { label: 'Kroger Pharmacy', value: 'kroger' },
  { label: 'Publix Pharmacy', value: 'publix' },
  { label: 'Costco Pharmacy', value: 'costco' },
  { label: 'Sam\'s Club Pharmacy', value: 'sams club' },
  { label: 'Health Mart', value: 'health mart' },
  { label: 'Dollar General', value: 'dollar general' },
  { label: 'H-E-B', value: 'h-e-b' },
  { label: 'Meijer Pharmacy', value: 'meijer' },
  { label: 'Kaiser Permanente', value: 'kaiser permanente' },
  { label: 'Fred Meyer', value: 'fred meyer' },
  { label: 'Giant Eagle', value: 'giant eagle' },
  { label: 'Ahold Delhaize', value: 'ahold delhaize' },
  { label: 'Safeway', value: 'safeway' },
  { label: 'Albertsons', value: 'albertsons' },
  { label: 'Raley\'s', value: 'raley\'s' },
  { label: 'Wegmans', value: 'wegmans' },
  { label: 'Hy-Vee', value: 'hy-vee' },
  { label: 'Duane Reade', value: 'duane reade' },
  { label: 'Bartell Drugs', value: 'bartell drugs' },
  { label: 'Good Neighbor Pharmacy', value: 'good neighbor' },
  { label: 'Medicine Shoppe', value: 'medicine shoppe' },
  { label: 'Kinney Drugs', value: 'kinney drugs' },
  { label: 'Acme Sav-on', value: 'acme sav-on' },
  { label: 'Cerberus', value: 'cerberus' },
  { label: 'Rx30', value: 'rx30' },
  { label: 'MedImpact', value: 'medimpact' },
  { label: 'OptumRx', value: 'optumrx' },
  { label: 'Express Scripts', value: 'express scripts' },
  { label: 'Aetna Rx Home Delivery', value: 'aetna rx home delivery' },
  { label: 'Humana', value: 'humana' },
  { label: 'Anthem Prescription Management', value: 'anthem prescription management' },
  { label: 'Caremark', value: 'caremark' },
  { label: 'Cigna Home Delivery', value: 'cigna home delivery' },
  { label: 'UnitedHealthcare', value: 'united healthcare' },
  { label: 'Kaiser Permanente Mail Order', value: 'kaiser permanente mail order' },
  { label: 'Navitus Health Solutions', value: 'navitus health solutions' },
  { label: 'Magellan Rx Management', value: 'magellan rx management' },
  { label: 'Catamaran Corporation', value: 'catamaran corporation' },
  { label: 'Prime Therapeutics', value: 'prime therapeutics' },
  { label: 'Maxor Specialty', value: 'maxor specialty' },
  { label: 'Diplomat Specialty', value: 'diplomat specialty' },
  { label: 'Curant Health', value: 'curant health' },
  { label: 'BioScrip', value: 'bioscrip' },
  { label: 'Option Care Health', value: 'option care health' },
  { label: 'Soleo Health', value: 'soleo health' },
  { label: 'Accredo', value: 'accredo' },
  { label: 'AllianceRx Walgreens Prime', value: 'alliancerx walgreens prime' },
  { label: 'Amber Pharmacy', value: 'amber pharmacy' },
  { label: 'CVS Specialty', value: 'cvs specialty' },
  { label: 'Diplomat Pharmacy', value: 'diplomat pharmacy' },
  { label: 'US Bioservices', value: 'us bioservices' }
];

export const side_effects = [
  { label: 'Abdominal pain', value: 'abdominal pain' },
  { label: 'Abnormal dreams', value: 'abnormal dreams' },
  { label: 'Abnormal taste', value: 'abnormal taste' },
  { label: 'Allergic reactions', value: 'allergic reactions' },
  { label: 'Anxiety', value: 'anxiety' },
  { label: 'Back pain', value: 'back pain' },
  { label: 'Blurred vision', value: 'blurred vision' },
  { label: 'Blood clots', value: 'blood clots' },
  { label: 'Chest pain', value: 'chest pain' },
  { label: 'Chest tightness', value: 'chest tightness' },
  { label: 'Chills', value: 'chills' },
  { label: 'Confusion', value: 'confusion' },
  { label: 'Constipation', value: 'constipation' },
  { label: 'Cough', value: 'cough' },
  { label: 'Depression', value: 'depression' },
  { label: 'Diarrhea', value: 'diarrhea' },
  { label: 'Difficulty concentrating', value: 'difficulty concentrating' },
  { label: 'Difficulty speaking', value: 'difficulty speaking' },
  { label: 'Difficulty swallowing', value: 'difficulty swallowing' },
  { label: 'Dizziness', value: 'dizziness' },
  { label: 'Dry mouth', value: 'dry mouth' },
  { label: 'Excessive thirst', value: 'excessive thirst' },
  { label: 'Fatigue', value: 'fatigue' },
  { label: 'Feeling hot', value: 'feeling hot' },
  { label: 'Fever', value: 'fever' },
  { label: 'Flatulence', value: 'flatulence' },
  { label: 'Flu-like symptoms', value: 'flu-like symptoms' },
  { label: 'Flushing', value: 'flushing' },
  { label: 'Gastrointestinal bleeding', value: 'gastrointestinal bleeding' },
  { label: 'Hallucinations', value: 'hallucinations' },
  { label: 'Headache', value: 'headache' },
  { label: 'Heart palpitations', value: 'heart palpitations' },
  { label: 'Hearing loss', value: 'hearing loss' },
  { label: 'Hot flashes', value: 'hot flashes' },
  { label: 'Hypertension', value: 'hypertension' },
  { label: 'Hypotension', value: 'hypotension' },
  { label: 'Increased appetite', value: 'increased appetite' },
  { label: 'Increased heart rate', value: 'increased heart rate' },
  { label: 'Increased sweating', value: 'increased sweating' },
  { label: 'Increased thirst', value: 'increased thirst' },
  { label: 'Increased urination', value: 'increased urination' },
  { label: 'Indigestion', value: 'indigestion' },
  { label: 'Insomnia', value: 'insomnia' },
  { label: 'Irritability', value: 'irritability' },
  { label: 'Itching', value: 'itching' },
  { label: 'Joint pain', value: 'joint pain' },
  { label: 'Kidney problems', value: 'kidney problems' },
  { label: 'Loss of appetite', value: 'loss of appetite' },
  { label: 'Malaise', value: 'malaise' },
  { label: 'Memory problems', value: 'memory problems' },
  { label: 'Migraines', value: 'migraines' },
  { label: 'Mood swings', value: 'mood swings' },
  { label: 'Muscle pain', value: 'muscle pain' },
  { label: 'Nasal congestion', value: 'nasal congestion' },
  { label: 'Nasal dryness', value: 'nasal dryness' },
  { label: 'Nausea', value: 'nausea' },
  { label: 'Nervousness', value: 'nervousness' },
  { label: 'Painful urination', value: 'painful urination' },
  { label: 'Palpitations', value: 'palpitations' },
  { label: 'Panic attacks', value: 'panic attacks' },
  { label: 'Peripheral edema', value: 'peripheral edema' },
  { label: 'Photosensitivity', value: 'photosensitivity' },
  { label: 'Rash', value: 'rash' },
  { label: 'Restlessness', value: 'restlessness' },
  { label: 'Runny nose', value: 'runny nose' },
  { label: 'Seizures', value: 'seizures' },
  { label: 'Sexual dysfunction', value: 'sexual dysfunction' },
  { label: 'Shortness of breath', value: 'shortness of breath' },
  { label: 'Sneezing', value: 'sneezing' },
  { label: 'Sore throat', value: 'sore throat' },
  { label: 'Speech difficulties', value: 'speech difficulties' },
  { label: 'Stomach cramps', value: 'stomach cramps' },
  { label: 'Sweating', value: 'sweating' },
  { label: 'Swelling', value: 'swelling' },
  { label: 'Taste disturbances', value: 'taste disturbances' },
  { label: 'Tingling sensations', value: 'tingling sensations' },
  { label: 'Tinnitus', value: 'tinnitus' },
  { label: 'Tiredness', value: 'tiredness' },
  { label: 'Urinary retention', value: 'urinary retention' },
  { label: 'Vision changes', value: 'vision changes' },
  { label: 'Watery eyes', value: 'watery eyes' },
  { label: 'Weight gain', value: 'weight gain' },
  { label: 'Weight loss', value: 'weight loss' },
  { label: 'Wind', value: 'wind' },
  { label: 'Withdrawal symptoms', value: 'withdrawal symptoms' },
];

export const fetchDiagnoses = async (term) => {
  const baseUrl = 'https://clinicaltables.nlm.nih.gov/api/conditions/v3/search';
  const parameters = {
    terms: term,
    df: 'key_id,info_link_data,icd10cm_codes,icd10cm,consumer_name,primary_name,word_synonyms,synonyms,term_icd9_code,term_icd9_text'
  };
  try {
    const url = new URL(baseUrl);
    url.search = new URLSearchParams(parameters).toString();
    const response = await fetch(url);
    const data = await response.json();
    const normalizedData = [];
    data[3].forEach((item) => {
      if (Array.isArray(item)) {
        normalizedData.push({
          id: item[0],
          link: item[1]?.split(',')[0],
          consumerName: item[4],
          primaryName: item[5],
          synonyms: item[6]?.split(";"),
          other_name: item[7],
          icd10: item[2]
        });
      }
    });
    return normalizedData.map((e) => e.primaryName || e.consumerName || e.other_name);
  } catch (error) {
    console.log(error)
    return [];
  }
};

export const convertGigabytesToBytes = (gigabytes) => {
  const bytesInAGigabyte = 1024 * 1024 * 1024;
  const bytes = gigabytes * bytesInAGigabyte;
  return Math.round(bytes); // Round the result to the nearest integer
};

export const convertBytesToGigabytes = (bytes) => {
  const bytesInAGigabyte = 1024 * 1024 * 1024;
  const gigabytes = bytes / bytesInAGigabyte;
  return Math.round(gigabytes);
};

export const trackEvent = (name, data, action, category) => {
  if (!process.env.REACT_APP_LOCAL) LogRocket.track(name, data);
  if (name && action && category && process.env.REACT_APP_STAGE === 'production') {
    ReactGA.event({
      category,
      action,
      label: name
    });
  }
};

export const uploadToImgur = async (image, account_id) => {
  let myHeaders = new Headers();
  myHeaders.append("Authorization", "Client-ID a0b667f24a45ad8");

  let formdata = new FormData();
  formdata.append("image", image.data.replace(/^data:image\/(png|jpg);base64,/, ""));
  formdata.append("name", `${account_id}_${image.name}`);
  formdata.append("type", "base64");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow"
  };

  return fetch("https://api.imgur.com/3/image", requestOptions)
    .then(response => response.json())
    .then(result => result.data.link)
    .catch(error => console.log('error', error));
};
export const notifyToSlackChannel = async (
  webHookURL,
  message,
  images = [],
  account_id,
  channel,
  userName,
  botIconURL
) => {
  if (webHookURL && webHookURL.trim() && message && message.trim()) {
    let payload = {
      blocks: [
        {
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": message
          }
        }
      ]
    };
    if (channel && channel.trim()) {
      payload.channel = channel
    }
    if (userName && userName.trim()) {
      payload.username = userName
    }
    if (botIconURL && botIconURL.trim()) {
      payload.icon_url = botIconURL
    }
    if (images.length) {
      for (let i = 0; i < images.length; i++) {
        const url = await uploadToImgur(images[i], account_id);
        if (url) {
          payload.blocks.push({
            type: "image",
            title: {
              type: "plain_text",
              text: images[i].name
            },
            block_id: `${account_id}_${images[i].name}`,
            image_url: url,
            alt_text: `${account_id}_${images[i].name}`
          });
        }
      }
    }

    // eslint-disable-next-line no-undef
    return fetch(webHookURL, {
      method: "POST",
      body: JSON.stringify(payload)
    })
      .then((response) => response)
      .then((response) => response)
      .catch((error) => error)
  }
  return null
};

export const getRatingFaces = () => {
  const AngryFace = (
    <path d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm82.7-116.1c6.6-5.9 7.1-16 1.2-22.6C323.8 355.4 295.7 336 256 336s-67.8 19.4-83.9 37.3c-5.9 6.6-5.4 16.7 1.2 22.6s16.7 5.4 22.6-1.2c11.7-13 31.6-26.7 60.1-26.7s48.4 13.7 60.1 26.7c5.9 6.6 16 7.1 22.6 1.2zM176.4 264c13.3 0 24-10.7 24-24c0-2.6-.4-5.1-1.2-7.4l19.7 6.6c8.4 2.8 17.4-1.7 20.2-10.1s-1.7-17.4-10.1-20.2l-96-32c-8.4-2.8-17.4 1.7-20.2 10.1s1.7 17.4 10.1 20.2l39.6 13.2c-6.2 4.3-10.2 11.5-10.2 19.6c0 13.3 10.7 24 24 24zm184-24c0-8.2-4.1-15.5-10.4-19.8l39.1-13c8.4-2.8 12.9-11.9 10.1-20.2s-11.9-12.9-20.2-10.1l-96 32c-8.4 2.8-12.9 11.9-10.1 20.2s11.9 12.9 20.2 10.1l20.6-6.9c-.8 2.4-1.3 5-1.3 7.7c0 13.3 10.7 24 24 24s24-10.7 24-24z" strokeWidth="9px" />
  );


  const SadFace = (
    <path d="M12.0000002,1.99896738 C17.523704,1.99896738 22.0015507,6.47681407 22.0015507,12.0005179 C22.0015507,17.5242217 17.523704,22.0020684 12.0000002,22.0020684 C6.47629639,22.0020684 1.99844971,17.5242217 1.99844971,12.0005179 C1.99844971,6.47681407 6.47629639,1.99896738 12.0000002,1.99896738 Z M12.0000002,3.49896738 C7.30472352,3.49896738 3.49844971,7.30524119 3.49844971,12.0005179 C3.49844971,16.6957946 7.30472352,20.5020684 12.0000002,20.5020684 C16.6952769,20.5020684 20.5015507,16.6957946 20.5015507,12.0005179 C20.5015507,7.30524119 16.6952769,3.49896738 12.0000002,3.49896738 Z M12.0000001,13.4979816 C13.6312483,13.4979816 15.1603686,14.1528953 16.2810488,15.2934358 C16.5713583,15.5888901 16.5671876,16.0637455 16.2717333,16.354055 C15.976279,16.6443646 15.5014236,16.6401939 15.211114,16.3447396 C14.3696444,15.4883577 13.2246935,14.9979816 12.0000001,14.9979816 C10.7726114,14.9979816 9.62535029,15.4905359 8.78347552,16.3502555 C8.49366985,16.6462041 8.01882223,16.6511839 7.72287367,16.3613782 C7.4269251,16.0715726 7.4219453,15.5967249 7.71175097,15.3007764 C8.83296242,14.155799 10.3651558,13.4979816 12.0000001,13.4979816 Z M9.00044779,8.75115873 C9.69041108,8.75115873 10.2497368,9.3104845 10.2497368,10.0004478 C10.2497368,10.6904111 9.69041108,11.2497368 9.00044779,11.2497368 C8.3104845,11.2497368 7.75115873,10.6904111 7.75115873,10.0004478 C7.75115873,9.3104845 8.3104845,8.75115873 9.00044779,8.75115873 Z M15.0004478,8.75115873 C15.6904111,8.75115873 16.2497368,9.3104845 16.2497368,10.0004478 C16.2497368,10.6904111 15.6904111,11.2497368 15.0004478,11.2497368 C14.3104845,11.2497368 13.7511587,10.6904111 13.7511587,10.0004478 C13.7511587,9.3104845 14.3104845,8.75115873 15.0004478,8.75115873 Z" />
  );

  const NeutralFace = (
    <>
      <path d="M9.00051 8.75122C9.69047 8.75122 10.2498 9.31055 10.2498 10.0005C10.2498 10.6905 9.69047 11.2498 9.00051 11.2498C8.31055 11.2498 7.75122 10.6905 7.75122 10.0005C7.75122 9.31055 8.31055 8.75122 9.00051 8.75122Z" />
      <path d="M15.0005 8.75122C15.6905 8.75122 16.2498 9.31055 16.2498 10.0005C16.2498 10.6905 15.6905 11.2498 15.0005 11.2498C14.3105 11.2498 13.7512 10.6905 13.7512 10.0005C13.7512 9.31055 14.3105 8.75122 15.0005 8.75122Z" />
      <path d="M8.25 15C7.83579 15 7.5 15.3358 7.5 15.75C7.5 16.1642 7.83579 16.5 8.25 16.5H15.75C16.1642 16.5 16.5 16.1642 16.5 15.75C16.5 15.3358 16.1642 15 15.75 15H8.25Z" />
      <path d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5Z" />
    </>
  );

  const SmilingFace = (
    <path d="M12.0000002,1.99896738 C17.523704,1.99896738 22.0015507,6.47681407 22.0015507,12.0005179 C22.0015507,17.5242217 17.523704,22.0020684 12.0000002,22.0020684 C6.47629639,22.0020684 1.99844971,17.5242217 1.99844971,12.0005179 C1.99844971,6.47681407 6.47629639,1.99896738 12.0000002,1.99896738 Z M12.0000002,3.49896738 C7.30472352,3.49896738 3.49844971,7.30524119 3.49844971,12.0005179 C3.49844971,16.6957946 7.30472352,20.5020684 12.0000002,20.5020684 C16.6952769,20.5020684 20.5015507,16.6957946 20.5015507,12.0005179 C20.5015507,7.30524119 16.6952769,3.49896738 12.0000002,3.49896738 Z M8.46174078,14.7838355 C9.31087697,15.8615555 10.6018926,16.5020843 11.9999849,16.5020843 C13.396209,16.5020843 14.6856803,15.8632816 15.5349376,14.7880078 C15.7916692,14.4629512 16.2633016,14.4075628 16.5883582,14.6642944 C16.9134148,14.9210259 16.9688032,15.3926584 16.7120717,15.717715 C15.5813083,17.1494133 13.8601276,18.0020843 11.9999849,18.0020843 C10.1373487,18.0020843 8.41411759,17.1471146 7.28351576,15.7121597 C7.02716611,15.3868018 7.08310832,14.9152347 7.40846617,14.6588851 C7.73382403,14.4025354 8.20539113,14.4584777 8.46174078,14.7838355 Z M9.00044779,8.75115873 C9.69041108,8.75115873 10.2497368,9.3104845 10.2497368,10.0004478 C10.2497368,10.6904111 9.69041108,11.2497368 9.00044779,11.2497368 C8.3104845,11.2497368 7.75115873,10.6904111 7.75115873,10.0004478 C7.75115873,9.3104845 8.3104845,8.75115873 9.00044779,8.75115873 Z M15.0004478,8.75115873 C15.6904111,8.75115873 16.2497368,9.3104845 16.2497368,10.0004478 C16.2497368,10.6904111 15.6904111,11.2497368 15.0004478,11.2497368 C14.3104845,11.2497368 13.7511587,10.6904111 13.7511587,10.0004478 C13.7511587,9.3104845 14.3104845,8.75115873 15.0004478,8.75115873 Z" />
  );

  const HappyFace = (
    <>
      <path d="M6.74927 12C6.53852 12 6.33749 12.0887 6.19539 12.2443C6.05329 12.4 5.98323 12.6082 6.00237 12.8181C6.28259 15.8916 8.55224 18.5 12 18.5C15.4478 18.5 17.7174 15.8916 17.9977 12.8181C18.0168 12.6082 17.9468 12.4 17.8047 12.2443C17.6626 12.0887 17.4616 12 17.2508 12H6.74927ZM12 17C9.74286 17 8.12852 15.5205 7.63237 13.5H16.3677C15.8715 15.5205 14.2571 17 12 17Z" />
      <path d="M15.2501 8.75C14.8416 8.75 14.5398 9.03719 14.492 9.35982C14.4314 9.76957 14.05 10.0526 13.6403 9.99192C13.2305 9.93126 12.9475 9.54993 13.0082 9.14018C13.1696 8.0495 14.1313 7.25 15.2501 7.25C16.3689 7.25 17.3306 8.0495 17.492 9.14018C17.5527 9.54993 17.2697 9.93126 16.8599 9.99192C16.4502 10.0526 16.0688 9.76957 16.0082 9.35982C15.9604 9.03719 15.6586 8.75 15.2501 8.75Z" />
      <path d="M7.99202 9.35982C8.03977 9.03719 8.34157 8.75 8.7501 8.75C9.15863 8.75 9.46043 9.03719 9.50818 9.35982C9.56884 9.76957 9.95017 10.0526 10.3599 9.99192C10.7697 9.93126 11.0527 9.54993 10.992 9.14018C10.8306 8.0495 9.86892 7.25 8.7501 7.25C7.63128 7.25 6.66963 8.0495 6.50818 9.14018C6.44753 9.54993 6.73053 9.93126 7.14028 9.99192C7.55003 10.0526 7.93136 9.76957 7.99202 9.35982Z" />
      <path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12Z" />
    </>
  );

  const customStyles = {
    itemShapes: [AngryFace, SadFace, NeutralFace, SmilingFace, HappyFace],
    activeFillColor: ["#da1600", "#dcb000", "#ee6c4c", "#61bb00", "#009664"],
    inactiveFillColor: "#a8a8a8",
  };
  return customStyles;
};

export const generateCredentials = async (user) => {
  try {
    const origin = window.location.origin;
    const response = await getAuthChallenge();
    const challenge = response.payload;

    const credential = await client.register(`${user.first_name} ${user.last_name}`, challenge, {
      authenticatorType: "both",
      userVerification: "required",
      timeout: 60000,
      attestation: false,
      userHandle: user.cognito_id,
      debug: false,
      origin
    })

    if (credential) {
      const registered = await storeCredential(credential, challenge);
      if (registered.success) {
        await getUser(user.cognito_id, store.getState().session.account.account_id, true);
        return true;
      }
      return false;
    }
    return false;
  } catch (error) {
    console.error('Error generating credentials:', error);
    return false;
  }
};

export const passkeyAuth = async () => {
  const origin = window.location.origin;
  const passkeys = store.getState().authentication?.user?.passkeys || [];
  const valid_keys = passkeys?.filter((p) => p.origin === origin);
  console.log("Validating passkeys for origin:", origin);
  if (!valid_keys || !valid_keys.length) return true;
  const response = await getAuthChallenge(true);
  const challenge = response.payload;
  return client.authenticate(valid_keys?.map((p) => p.id), challenge, {
    "authenticatorType": "both",
    "userVerification": "required",
    "timeout": 60000
  })
  .then(async (authentication) => {
    if (authentication) {
      const is_authenticated = await verifyCredential(authentication, challenge);
      if (is_authenticated.success) return true;
      store.dispatch(showNotification("error", "Verification Error", "Could not verify passkey."));
      store.dispatch({ type: SET_LOCKED, payload: { show: true, inactive: false } });
      return false;
    }
    store.dispatch({ type: SET_LOCKED, payload: { show: true, inactive: false } });
    return false;
  })
  .catch(() => {
    store.dispatch(showNotification("error", "Authorization Error", "The authorization could not be completed. Please try again."));
    return false;
  });
};

export const checkSmartScheduleDays = (questionObjects, timespanInDays) => {
  const sortedQuestions = sortQuestionsByCreationTime(questionObjects);
  const startDate = calculateStartDate(timespanInDays);
  const questionsPerDayMap = new Map();
  sortedQuestions.forEach((question) => {
    const questionDate = moment.utc(question.created_at);
    if (questionDate.isSameOrAfter(startDate, "day")) {
      const formattedDate = questionDate.format("YYYY-MM-DD");
      questionsPerDayMap.set(formattedDate, (questionsPerDayMap.get(formattedDate) || 0) + 1);
    }
  });
  return questionsPerDayMap;
};

export const calculateAverageHour = (objectsArray = []) => {
  if (!objectsArray.length) return null;

  // Calculate the total hours
  const totalHours = objectsArray.reduce((sum, obj) => {
    if (obj && obj.created_at) {
      const date = new Date(obj.created_at);
      return sum + date.getHours();
    }
    return sum;
  }, 0);

  // Calculate the average hour
  const averageHour = Math.round(totalHours / objectsArray.length);

  return averageHour;
};

export const adaptPromptCadence = (questionObjects, timespanInDays, currentCadence, currentTime, cutOffTime) => {
  try {
    const MIN_HOURS_BEFORE_CUTOFF = 5;
    const MAX_PROMPTS_PER_DAY = 4;
    if (isCutoffTimeImminent(currentTime, cutOffTime, MIN_HOURS_BEFORE_CUTOFF)) return 1;

    const questionsPerDayMap = checkSmartScheduleDays(questionObjects, timespanInDays);
    console.log(questionsPerDayMap)

    if (isBelowMinimumSampleSize(questionsPerDayMap, SMART_SCHEDULE_THRESHOLD)) return 1;

    const topValues = getTopQuestionCounts(questionsPerDayMap);
    const averageTopValues = calculateAverage(topValues);

    const adaptedCadence = calculateAdaptedCadence(averageTopValues, currentCadence, MAX_PROMPTS_PER_DAY);
    return adaptedCadence;
  } catch (error) {
    console.error('Error in adaptPromptCadence:', error.message);
    return 1;
  }
};

// Helper functions

const isCutoffTimeImminent = (currentTime, cutOffTime, minHoursBeforeCutoff) => {
  return (cutOffTime - currentTime) < minHoursBeforeCutoff;
};

const sortQuestionsByCreationTime = (questionObjects) => {
  return questionObjects.slice().sort((a, b) => moment.utc(a.created_at) - moment.utc(b.created_at));
};

const calculateStartDate = (timespanInDays) => {
  return moment.utc().subtract(timespanInDays, "days");
};

export const isBelowMinimumSampleSize = (questionsPerDayMap, minSampleSize) => {
  return questionsPerDayMap.size < minSampleSize;
};

const getTopQuestionCounts = (questionsPerDayMap) => {
  return Array.from(questionsPerDayMap.values()).sort((a, b) => b - a);
};

const calculateAverage = (values) => {
  return values.reduce((sum, value) => sum + value, 0) / values.length;
};

const calculateAdaptedCadence = (averageTopValues, currentCadence, maxPromptsPerDay) => {
  const adaptedCadence = Math.max(1, Math.min(maxPromptsPerDay, Math.round(averageTopValues)));
  return (currentCadence > 1) ? Math.min(currentCadence, adaptedCadence) : adaptedCadence;
};

export const generateQuestionTimes = (adaptedCadence, notificationTime, cutoffTime) => {
  // Parse the notification and cutoff times
  const parsedNotificationTime = moment(notificationTime, "HH");
  const parsedCutoffTime = moment(cutoffTime, "HH");

  // Start with the first question time as the notification time
  let questionTimes = [parsedNotificationTime.hour()];

  // If adaptedCadence is 1, return the initial question time
  if (adaptedCadence === 1) return questionTimes;

  // Calculate the available hours for questions
  const availableHours = parsedCutoffTime.diff(parsedNotificationTime, "hours");

  // Calculate the time interval between questions
  const timeInterval = Math.floor(availableHours / (adaptedCadence));

  // Generate the next question times until reaching adaptedCadence or exceeding the cutoff time
  for (let i = 1; i < adaptedCadence; i++) {
    let nextQuestionTime = parsedNotificationTime.clone().add(i * timeInterval, "hours");

    // If the next question time is after the cutoff time, break the loop
    if (nextQuestionTime.isSameOrAfter(parsedCutoffTime)) break;

    // Add the next question time to the array
    if (!questionTimes.includes(nextQuestionTime.hour())) questionTimes.push(nextQuestionTime.hour());
  }

  // Check if the last question time is too close to the cutoffTime
  const lastQuestionTime = moment(questionTimes[questionTimes.length - 1], "HH");
  const timeDifference = parsedCutoffTime.diff(lastQuestionTime, "hours");

  // If the last question time is too close, remove it
  if (timeDifference < timeInterval) questionTimes.pop();

  return questionTimes.sort((a, b) => a - b); // Sort the array in ascending order
};

export const clearLocalStorageItems = (prefix) => {
  Object.keys(localStorage)
    .filter((x) => x.startsWith(prefix)).forEach((x) => localStorage.removeItem(x))
};

export const rgbaToHex = (rgbaString) => {
  const rgbaValues = rgbaString.match(/\d+(\.\d+)?/g);
  const r = parseInt(rgbaValues[0]);
  const g = parseInt(rgbaValues[1]);
  const b = parseInt(rgbaValues[2]);
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};

export const calculateBMI = (heightFeet, heightInches, weightPounds) => {
  let totalInches = (heightFeet * 12) + heightInches;
  let bmi = (weightPounds / (totalInches * totalInches)) * 703;
  return bmi.toFixed(2);
};

export const calculateBMR = (weight, feet, inches, age, gender) =>{
  let bmr = 0;
  const totalHeightInInches = feet * 12 + inches;
  if (gender.toLowerCase() === 'male') {
    bmr = 66 + (6.23 * weight) + (12.7 * totalHeightInInches) - (6.8 * age);
  } else if (gender.toLowerCase() === 'female') {
    bmr = 655 + (4.35 * weight) + (4.7 * totalHeightInInches) - (4.7 * age);
  } else {
    throw new Error('Invalid gender. Please specify "male" or "female".');
  }
  return Math.round(bmr);
};

export const markdownToHtml = (markdown) => {
  // Bold: **text**
  markdown = markdown.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

  // Italic: *text*
  markdown = markdown.replace(/\*(.*?)\*/g, "<em>$1</em>");

  // Underline: __text__
  markdown = markdown.replace(/__(.*?)__/g, "<u>$1</u>");

  // Strikethrough: ~~text~~
  markdown = markdown.replace(/~~(.*?)~~/g, "<s>$1</s>");

  // Headers: # text, ## text, ### text, #### text
  markdown = markdown.replace(/#{1,4} (.*?)(?:\n|$)/g, (match, p1) => {
    const level = match.split(" ")[0].length;
    return `<h${level}>${p1}</h${level}>`;
  });

  // Links: [text](url)
  markdown = markdown.replace(/\[(.*?)\]\((.*?)\)/g, "<a target='_blank' href='$2'>$1</a>");

  return markdown;
};

export const removeMarkdown = (text) => {
  // Remove headings
  text = text.replace(/^#+\s*(.*?)\s*#*$/gm, '$1');
  // Remove bold and italic
  text = text.replace(/(?:\*|_){1,3}(.*?)(?:\*|_){1,3}/g, '$1');
  // Remove inline code
  text = text.replace(/`([^`]+)`/g, '$1');
  // Remove links
  text = text.replace(/\[(.*?)\]\((.*?)\)/g, '$1');
  // Remove images
  text = text.replace(/!\[(.*?)\]\((.*?)\)/g, '');
  // Remove blockquotes
  text = text.replace(/^\s*>\s*/gm, '');
  // Remove unordered lists
  text = text.replace(/^\s*-\s*/gm, '');
  // Remove ordered lists
  text = text.replace(/^\s*\d+\.\s*/gm, '');
  return text;
};