import { createGlobalStyle, keyframes } from "styled-components";
import { darken, lighten } from "polished";
import { store } from "./store";
import media from "styled-media-query";

export const themeBuilder = () => {
  const current_theme = store.getState().session.theme;
  const defaults = {
    fadedBlack: "rgba(52, 55, 64, 1)",
    fadedGrey: "rgba(105, 107, 116, 1)",
    teal: "rgba(0, 150, 177, 1)",
    rowGrey: "#EDEEF1",
    disabled: "#EEEEEE",
    activeTextGrey: "#343D46",
    lineGrey: "#DCDBDB",
    lightGrey: "rgba(0,0,0,0.1)",
    labelGrey: "#9d9b9b",
    notificationYellow: "#FFD700",
    gold: "#FFC000",
    noticeYellow: "#856404",
    noticeYellowBackground: "#fff3cd",
    notificationOrange: "#ff7029",
    button_accent: "rgba(238, 108, 76, 1)",
    button_accent2: "#A0BFD7",
    metadataGrey: "#717171",
    white: "rgba(255,255,255,1)",
    black: "#000000",
    pink: "#B8207E",
    purple: "#6320B8",
    success: "rgba(40, 167, 69, 1)",
    neutral_blue: "rgb(0, 123, 255)",
    default_background: "#2B3240",
    heading_grey: "#535353",
    fontGrey: "#4A4A4A",
    red: "#8B0000",
    grey: "rgba(243, 243, 243, 1)",
    errorRed: "#F44337",
    boxShadowLight: "rgba(0, 0, 0, 0.1)",
    boxShadowHover: "rgba(0, 0, 0, 0.2)",
    boxShadowDefault: "0 4px 6px rgba(50,50,93,.06), 0 1px 3px rgba(0,0,0,.05)",
    defaultFont: "Roboto,sans-serif",
    defaultBorderRadius: "6px",
    slate: "#2B3240",
    backgroundColor: "#F5F6FA",
    headerTextColor: "#000000",
    footerColor: "#DCDBDB",
    buttonBackground: "#DCDBDB",
    hintColor: "rgba(0,0,0,0.6)",
    lightBlue: "rgba(99, 184, 255, 1)",
    themedDisabledText: "#EEEEEE"
  }
  
  const themes = {
    default: defaults,
    dark: {
      ...defaults,
      QRFgColor: "rgba(33, 36, 52, 1)",
      QRBgColor: "rgba(99, 184, 255, 1)",
      coreBackground: "rgba(33, 36, 52, 1)",
      blue: "rgba(40, 60, 85, 1)",
      backgroundColor: "rgba(43, 47, 65, 1)",
      backgroundColorOpaque: "rgba(43, 47, 65, 0.5)",
      inputBackground: "rgba(36, 40, 56, 1)",
      inputColor: "rgba(249, 249, 249, 1)",
      default_background: "rgba(43, 47, 65, 1)",
      headerTextColor: "rgba(255,255,255,1)",
      footerColor: "rgba(43, 47, 65, 1)",
      buttonBackground: "rgba(99, 184, 255, 1)",
      buttonTextColor: "rgba(33, 36, 52, 1)",
      hintColor: "rgba(255,255,255,1)",
      questionElementBackground: "rgba(43, 47, 65, 1)",
      widgetBackground: "rgba(43, 47, 65, 1)",
      modalBackground: "rgba(33, 36, 52, 1)",
      link: "rgba(238, 108, 76, 1)",
      faintBorder: "rgba(255,255,255,0.1)",
      themeBarColor: "rgba(43, 47, 65, 1)",
      themedDisabledText: darken(0.7, "rgba(255,255,255,1)"),
      blurBackground: "rgba(0, 0, 0, 0.20)"
    },
    light: {
      ...defaults,
      QRFgColor: "rgba(255,255,255,1)",
      QRBgColor: "rgba(40, 60, 85, 1)",
      coreBackground: "rgba(249, 249, 249, 1)",
      blue: "rgba(40, 60, 85, 1)",
      backgroundColor: "rgba(255,255,255,1)",
      backgroundColorOpaque: "rgba(255,255,255,0.5)",
      inputBackground: "rgba(255,255,255,1)",
      link: "rgba(238, 108, 76, 1)",
      inputColor: "#000",
      default_background: "rgba(40, 60, 85, 1)",
      headerTextColor: "rgba(105, 107, 116, 1)",
      footerColor: "rgba(40, 60, 85, 1)",
      buttonBackground: "rgba(238, 108, 76, 1)",
      buttonTextColor: "rgba(255,255,255,1)",
      hintColor: "rgba(0,0,0,0.6)",
      questionElementBackground: "rgba(255,255,255,1)",
      modalBackground: "rgba(255,255,255,1)",
      widgetBackground: "rgba(255,255,255,1)",
      faintBorder: "rgba(105, 107, 116, 0.2)",
      themeBarColor: "rgba(40, 60, 85, 1)",
      themedDisabledText: lighten(0.3, "rgba(105, 107, 116, 1)"),
      blurBackground: "rgba(190, 190, 190, 0.26)"
    },
    get buildTheme() {
      document.querySelector('meta[name="theme-color"]')?.setAttribute('content', this[current_theme].themeBarColor);
      document.querySelector('body')?.setAttribute('class', current_theme);
      return this[current_theme];
    },
    get reset() {
      return () => {
        setTimeout(() => document.querySelector('meta[name="theme-color"]')?.setAttribute('content', this[current_theme].themeBarColor), 100);
      };
    }
  };
  return themes;
};

export const coreTheme = themeBuilder().buildTheme;

export const highlightAnimation = keyframes`
  to {
    background-position: 0;
    color: black;
    background: ${({ theme }) => theme.gold};
  }
`;

export const GlobalStyle = createGlobalStyle`
html {
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;
  font-family: ${({ theme }) => theme.defaultFont};
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.coreBackground};
}

#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    color: ${({ theme }) => lighten(0.1, theme.default_background)};
  }

* {
  background: transparent;
  box-sizing: border-box;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  -webkit-overflow-scrolling: touch;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

input:not(.sv-list__input):not(.pin-field):not(.react-select-hidden):not([id^=react-select-hidden]):not(.sd-btn):not(.sd-dropdown__filter-string-input):not(.sd-text), textarea {
  background: ${({ theme }) => theme.inputBackground} !important;
  background-color: ${({ theme }) => theme.inputBackground} !important;
  color: ${({ theme }) => theme.inputColor} !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.sd-input.sd-dropdown:focus-within .sd-dropdown__filter-string-input {
  color: ${({ theme }) => theme.inputColor} !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
}

input[type="text"]:not(.sd-input), textarea {
  -webkit-appearance: textfield;
  color: ${({ theme }) => theme.inputColor} !important
}

input:disabled {
  color: ${({ theme }) => darken(0.2, theme.lineGrey)} !important;
  cursor: no-drop;
  font-weight: 300;
  font-size: 13px;
}

input[type="text"]:read-only,
input[type="password"]:read-only,
input[type="search"]:read-only,
input[type="tel"]:read-only,
input[type="email"]:read-only {
  color: ${({ theme }) => darken(0.2, theme.lineGrey)} !important;
  cursor: no-drop;
  font-weight: 300;
  font-size: 13px;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #aaa7a7;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 400;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #aaa7a7;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 400;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #aaa7a7;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 400;
}
:-moz-placeholder { /* Firefox 18- */
  color: #aaa7a7;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 400;
}

.password_strength_word {
  font-size: 12px !important;
}

.pin-field-container {
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  justify-content: center;
  margin: 0;
}
.pin-field {
  background-color: rgb(248, 249, 250);
  border: 1px solid rgb(204, 204, 204);
  font-size: 2rem;
  height: 4rem;
  outline: none;
  text-align: center;
  transition-duration: .25s;
  transition-property: color,border,box-shadow,-webkit-transform;
  transition-property: color,border,box-shadow,transform;
  transition-property: color,border,box-shadow,transform,-webkit-transform;
  width: 3.1rem;
  padding: 1px 2px;

  &:first-of-type {
    border-radius: 0.5rem 0 0 0.5rem;
  }
  &:last-of-type {
    border-radius: 0 0.5rem 0.5rem 0;
    border-right: 1px solid rgb(204, 204, 204);
  }

  &:focus {
    border-color: rgb(0, 123, 255);
    box-shadow: 0 0 0.25rem rgba(0, 123, 255, 0.5);
    outline: none;
  }

  &:invalid {
    animation: shake 3 linear 75ms;
    border-color: rgb(220, 53, 69);
    box-shadow: 0 0 0.25rem rgba(220, 53, 69, 0.5);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.pin-field.small {
  height: 2.5rem;
  width: 100%;

  &:first-of-type {
    border-radius: 4px 0 0 4px;
  }
  &:last-of-type {
    border-radius: 0 4px 4px 0;
  }
}

/* Chrome, Safari, Edge, Opera */
input.pin-field::-webkit-outer-spin-button,
input.pin-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.pin-field[type=number] {
  -moz-appearance: textfield;
}

[data-tooltip][data-tooltip-content] {
	 position: relative;
   z-index: 1;
}
 [data-tooltip][data-tooltip-content]::before, [data-tooltip][data-tooltip-content]::after {
	 left: 50%;
	 opacity: 0;
	 position: absolute;
	 top: calc(100% + 0.5rem);
	 transition: opacity 0.15s ease-in-out, visibility 0s 0.15s ease-in-out;
	 visibility: hidden;
}
 [data-tooltip][data-tooltip-content]::before {
	 border-bottom: 8px solid ${({ theme }) => theme.teal};
	 border-left: 8px solid transparent;
	 border-right: 8px solid transparent;
	 content: '';
	 height: 0;
	 transform: translateX(-50%) translateY(calc(-100% + 1px));
	 width: 0;
	 z-index: 1;
}
 [data-tooltip][data-tooltip-content]::after {
	 background-color: ${({ theme }) => theme.teal};
	 border-radius: 5px;
	 color: ${({ theme }) => theme.headerTextColor};
	 content: attr(data-tooltip-content);
	 font-size: 0.7rem;
	 font-weight: 400;
	 padding: 10px 0.75em;
	 transform: translate3d(-50%, 0, 0);
   white-space: pre-wrap;
	 z-index: 2;
   line-height: 15px;
   max-width: 200px;
   width: max-content;
}
[data-tooltip][data-tooltip-content].tooltip.theme::before {
  border-bottom: 8px solid ${({ theme }) => theme.buttonBackground};
  color: ${({ theme }) => theme.buttonTextColor};
}
[data-tooltip][data-tooltip-content].tooltip.theme::after {
  background-color: ${({ theme }) => theme.buttonBackground};
  color: ${({ theme }) => theme.buttonTextColor};
}
[data-tooltip][data-tooltip-content].tooltip.error::before {
  border-bottom: 8px solid ${({ theme }) => theme.errorRed};
}
[data-tooltip][data-tooltip-content].tooltip.error::after {
  background-color: ${({ theme }) => theme.errorRed};
}
[data-tooltip][data-tooltip-content].tooltip.neutral::before {
  border-bottom: 8px solid ${({ theme }) => theme.coreBackground};
}
[data-tooltip][data-tooltip-content].tooltip.neutral::after {
  background-color: ${({ theme }) => theme.coreBackground};
}
[data-tooltip][data-tooltip-content].tooltip.disabled::before {
  border-bottom: 8px solid ${({ theme }) => theme.labelGrey};
}
[data-tooltip][data-tooltip-content].tooltip.disabled::after {
  background-color: ${({ theme }) => theme.labelGrey};
}
[data-tooltip][data-tooltip-content].tooltip.success::before {
  border-bottom: 8px solid ${({ theme }) => theme.success};
  color: ${({ theme }) => theme.white};
}
[data-tooltip][data-tooltip-content].tooltip.success::after {
  background-color: ${({ theme }) => theme.success};
  color: ${({ theme }) => theme.white};
}
 [data-tooltip][data-tooltip-content]:hover::before, [data-tooltip][data-tooltip-content]:focus::before, [data-tooltip][data-tooltip-content]:hover::after, [data-tooltip][data-tooltip-content]:focus::after {
	 opacity: 1;
	 transition: opacity 0.15s ease-in-out;
	 visibility: visible;
}
 [data-tooltip][data-tooltip-content][data-tooltip-position="top"]::before, [data-tooltip][data-tooltip-content][data-tooltip-position="top"]::after {
	 bottom: calc(100% + 0.8rem);
	 top: auto;
}
 [data-tooltip][data-tooltip-content][data-tooltip-position="top"]::before {
	 transform: translateX(-50%) translateY(calc(100% - 1px)) rotateZ(180deg);
}
 [data-tooltip][data-tooltip-content][data-tooltip-position="left"]::before, [data-tooltip][data-tooltip-content][data-tooltip-position="left"]::after {
	 left: 0;
	 top: 50%;
}
 [data-tooltip][data-tooltip-content][data-tooltip-position="left"]::before {
	 transform: translateX(calc(-100% - 1px)) translateY(-50%) rotateZ(90deg);
}
 [data-tooltip][data-tooltip-content][data-tooltip-position="left"]::after {
	 transform: translateX(calc(-100% - 1rem + 4px)) translateY(-50%);
}
 [data-tooltip][data-tooltip-content][data-tooltip-position="right"]::before, [data-tooltip][data-tooltip-content][data-tooltip-position="right"]::after {
	 left: auto;
	 right: 0;
	 top: 50%;
}
 [data-tooltip][data-tooltip-content][data-tooltip-position="right"]::before {
	 transform: translateX(calc(100% + 5px)) translateY(-50%) rotateZ(270deg);
}
 [data-tooltip][data-tooltip-content][data-tooltip-position="right"]::after {
	 transform: translateX(calc(100% + 1rem)) translateY(-50%);
}
 @media (hover: none) {
	 [data-tooltip][data-tooltip-content]::before, [data-tooltip][data-tooltip-content]::after {
		 content: none;
	}
}

.redux-toastr .toastr {
  z-index: 2147483647 !important;
}

.redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center, .redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
  z-index: 2147483647 !important;
}

.sb-avatar__image {
  object-fit: cover;
  border: 1px solid ${({ theme }) => theme.faintBorder};
}

.react-responsive-modal-modal {
  overflow-y: visible !important;
  padding: 20px;
  background: ${({ theme }) => theme.default_background};
}

// misc. styles
.sd-container-modern {
  margin: 0;
}

.sd-remaining-character-counter {
  bottom: -30px;
  right: 0;
}

.sd-matrixdynamic .sd-remaining-character-counter {
  bottom: 0;
  right: 10px;
  top: 0;
  margin: auto;
  align-items: center;
  height: 30px;
}

.sd-matrixdynamic:focus-within .sd-remaining-character-counter {
  display: flex;
  background: ${({ theme }) => theme.inputBackground};
  padding: 0 0 0 10px;
}

.sd-table-wrapper:after {
  background: transparent !important;
}

.sd-title:not(.sd-question__title) {
  background-color: transparent !important;
  background: transparent;
  color: ${({ theme }) => theme.headerTextColor} !important;
  box-shadow: none !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  font-size: 1.5rem !important;
}

.sd-progress {
  height: 5px;
}

.sd-root-modern {
  height: 100%;
  background-color: transparent !important;
  .sd-container-modern {
    height: 100%;
  }
  form {
    height: 100%;
  }
  .sd-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 10px !important;
  }
  .sv-components-row {
    height: 100%;
  }
  ${media.lessThan("medium")`
    .sd-body {
      padding: 0 !important;
    }
  `};
}

// btn styles

.sd-btn {
  color: ${({ theme }) => theme.buttonBackground} !important;
  outline: none;
}

.sd-btn:not(.sv-popup__button) {
  background-color: ${({ theme }) => theme.buttonBackground} !important;
  background: ${({ theme }) => theme.buttonBackground} !important;
  color: ${({ theme }) => theme.buttonTextColor} !important;
}

.sd-btn:focus {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.buttonTextColor};
}

.sd-btn--action {
  background-color: ${({ theme }) => theme.buttonBackground} !important;
  color: ${({ theme }) => theme.buttonTextColor} !important;
  outline: none;
}

.sd-action {
  color: ${({ theme }) => theme.buttonBackground};
}

.sd-question:not(.sd-question--html) {
  background-color: ${({ theme }) => theme.questionElementBackground};
  background: ${({ theme }) => theme.questionElementBackground};
}

.sd-question--html {
  background: transparent;
   
  a {
    color: ${({ theme }) => theme.buttonBackground} !important;
  }
}

.sd-title {
  color: ${({ theme }) => theme.headerTextColor} !important;
}

.sd-input {
  background: ${({ theme }) => theme.inputBackground} !important;
  background-color: ${({ theme }) => theme.inputBackground} !important;
  color: ${({ theme }) => theme.inputColor} !important;
}

.sd-action:not(.sd-action--pressed):hover, .sd-action:not(.sd-action--pressed):focus {
  background-color: ${({ theme }) => lighten(0.4, theme.buttonBackground)};
}

// input styles
.sd-input:focus {
  box-shadow: 0 0 0 2px ${({ theme }) => theme.buttonBackground};
}

.sd-multipletext__item .sd-input:focus {
  box-shadow: none;
}

.sd-multipletext__item-container.sd-input:focus-within {
  box-shadow: inset 0 0 0 2px ${({ theme }) => theme.buttonBackground};
}

.sd-table__cell--header {
  color: ${({ theme }) => theme.headerTextColor} !important;
}

// radio styles
.sd-item--checked .sd-item__decorator {
  background: ${({ theme }) => theme.buttonBackground};
}

.sd-item__control:focus+.sd-item__decorator {
  box-shadow: 0 0 0 2px ${({ theme }) => theme.buttonBackground};
  background: ${({ theme }) => theme.buttonTextColor};
  outline: none;
}

.sd-item__control-label {
  color: ${({ theme }) => theme.headerTextColor} !important;
}

.sd-table__cell--row-text {
  color: ${({ theme }) => theme.headerTextColor} !important;
}

.sd-matrix__responsive-title {
  color: ${({ theme }) => theme.headerTextColor} !important;
}

.sd-table__cell.sd-matrix__cell.sd-table__cell--row-text {
  ${media.lessThan("768px")`
    border-top: 1px solid ${({ theme }) => theme.faintBorder} !important;
  `};
}

.sd-table__cell--actions:not(.sd-table__cell--vertical), .sd-table__cell--empty, .sd-table__cell--row-text, .sd-matrix__cell:first-of-type, .sd-matrix tr>td:first-of-type {
  z-index: 1;
}

.sd-matrix__table .sd-table__row:first-child .sd-table__cell.sd-matrix__cell.sd-table__cell--row-text {
  ${media.lessThan("768px")`
    border-top: none !important;
  `};
}

.sd-description {
  color: ${({ theme }) => theme.headerTextColor} !important;
  font-size: 0.8rem;
  padding-top: 5px;
  padding-left: 2px;
}

.sd-radio--checked .sd-radio__control:focus+.sd-radio__decorator:after {
  background: ${({ theme }) => theme.buttonBackground};
}

.sd-item__control:focus+.sd-item__decorator:after {
  background: ${({ theme }) => theme.white};
}

.sd-radio--checked .sd-radio__decorator:after {
  background: ${({ theme }) => theme.white};
}

.sd-item__control:active+.sd-radio__decorator:after {
  background: ${({ theme }) => theme.buttonBackground};
}

// yes/no styles
.sd-boolean-root {
  border-radius: 4px;
}

.sd-boolean.sd-boolean--allowhover:focus-within {
  box-shadow: none;
}


.sd-boolean {
  border-radius: 4px;
  background-color: ${({ theme }) => theme.coreBackground};
  background: ${({ theme }) => theme.coreBackground};
}

.sd-boolean__thumb {
  color: ${({ theme }) => theme.buttonBackground};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.questionElementBackground};
  background: ${({ theme }) => theme.questionElementBackground};
}

.sd-boolean__switch {
  border-radius: 4px;
}

.sd-boolean__control:focus~.sd-boolean__switch {
  box-shadow: inset 0 0 0 2px ${({ theme }) => theme.buttonBackground};
}

.sd-boolean.sd-boolean--allowhover .sd-boolean__thumb-ghost:hover {
  border-radius: 4px;
  background-color: ${({ theme }) => theme.coreBackground};
}

// select styles
.sd-input.sd-dropdown:focus-within {
  box-shadow: 0 0 0 2px ${({ theme }) => theme.buttonBackground};
}

.sd-dropdown__value {
  color: ${({ theme }) => theme.headerTextColor} !important;
}

.sv-list__item.sv-list__item--selected .sv-list__item-body, .sv-list__item.sv-list__item--selected:hover .sv-list__item-body, .sv-list__item.sv-list__item--selected.sv-list__item--focused .sv-list__item-body, .sv-multi-select-list .sv-list__item.sv-list__item--selected.sv-list__item--focused .sv-list__item-body, li:focus .sv-list__item.sv-list__item--selected .sv-list__item-body {
  background-color: ${({ theme }) => theme.buttonBackground};
  color: ${({ theme }) => theme.white};
}

.sv-tagbox__item {
  background-color: ${({ theme }) => theme.buttonBackground};
  min-height: 35px;
  height: auto;
  max-width: 190px;
}

.sv-tagbox__item:hover {
  max-width: 100%;
  transition: max-width 0.5s ease-in-out;
}

.sv-tagbox__item .sv-string-viewer {
  white-space: nowrap;
}

.sd-input.sd-dropdown:focus-within .sv-tagbox__item .sv-string-viewer {
  white-space: pre-wrap;
}

.sd-input.sd-dropdown:focus-within .sv-tagbox__item {
  max-width: 100%;
  width: auto;
}

.sv-tagbox__item-text {
  color: ${({ theme }) => theme.buttonTextColor};
}

.sd-tagbox-item_clean-button {
  background: linear-gradient(270deg, var(--sjs-primary-backcolor, var(--primary, ${({ theme }) => theme.buttonBackground})) 53.12%, rgba(25, 179, 148, 0) 100%);
}

.sd-tagbox_clean-button {
  height: auto;
}

.sd-progress__bar {
  background-color: ${({ theme }) => theme.buttonBackground};
}

.sd-table-wrapper:after, .sd-table-wrapper:before {
  background-color: ${({ theme }) => theme.backgroundColor};
}

.sd-table__cell--actions:not(.sd-table__cell--vertical), .sd-table__cell--empty, .sd-table__cell--row-text, .sd-matrix__cell:first-of-type, .sd-matrix tr>td:first-of-type {
  background-color: ${({ theme }) => theme.backgroundColor};
}

.sd-table__cell--actions:not(.sd-table__cell--vertical):last-child .sd-action-bar {
  background-color: ${({ theme }) => theme.backgroundColor};
}

.sd-progress {
  display: none;
}

.sd-table {
  background-color: ${({ theme }) => theme.backgroundColor};
}

.sd-body.sd-body--responsive .sd-body__timer, .sd-body.sd-body--responsive .sd-body__navigation {
  padding: 10px 0;
}

.sd-body--responsive .sd-page {
  padding: 0;
  min-width: auto;
}

.sv-popup.sv-popup--dropdown .sv-popup__body-content {
  ${media.greaterThan("medium")`
    max-height: 400px;
  `};
}

.sv-popup {
  position: absolute !important;
  width: 100% !important;
  z-index: 2147483647 !important;
}

.sv-popup__container {
  width: 100% !important;
}

${media.lessThan("medium")`
  .sv-popup {
    position: fixed !important;
    width: 100vw !important;
    z-index: 2147483647 !important;
  }

  .sv-popup__container {
    width: 100% !important;
  }
`};

.sd-body--responsive .sd-page.sd-page__empty-header {
  padding-top: 0 !important;
}

.sv-dropdown-popup.sv-popup--overlay .sv-list__item:hover.sv-list__item--selected .sv-list__item-body, .sv-dropdown-popup.sv-popup--overlay .sv-list__item:focus.sv-list__item--selected .sv-list__item-body, .sv-dropdown-popup.sv-popup--overlay .sv-list__item--focused.sv-list__item--selected .sv-list__item-body {
  background: ${({ theme }) => theme.buttonBackground};
}

.sd-selectbase {
  position: relative;
}

.sv-string-viewer {
  white-space: pre-wrap;
}

.rrt-confirm-holder {
  z-index: 12;
}

.matched_text {
  color: ${({ theme }) => theme.black};
  background: ${({ theme }) => theme.gold};
}

${media.lessThan("medium")`
  .react-responsive-modal-modal {
    height: 100%;
    border-radius: 0 !important;
    -webkit-overflow-scrolling: touch !important;
  }
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px !important;
  }
`};

input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="time"] {
  box-shadow: 0 0.125em 0.313em rgba(50,50,93,.09), 0 0.063em 0.125em rgba(0,0,0,.07);
  -webkit-min-logical-width: calc(100% - 16px);
  display:block;
  -webkit-appearance: none;
  -moz-appearance: none;
  min-height: 1.2em;
}

.overlay {
  z-index: 2147483646 !important;
}

.szh-menu {
  background: ${({ theme }) => theme.backgroundColor};
  z-index: 2147483645 !important;
}

.szh-menu__submenu {
  width: 100%;
}

.szh-menu__item {
  padding: 0.375rem 1rem
  color: ${({ theme }) => theme.headerTextColor};

  &:hover {
    background: ${({ theme }) => theme.coreBackground};
  }
}

.rrt-confirm-holder {
  z-index: 2147483647 !important;
}

.crosshairs::before, .crosshairs::after {
  background: ${({ theme }) => theme.buttonBackground};
}

#question_widgets {
  position: relative;
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

div[id^="eleplan-select"] div[class$="-control"] {
  box-shadow: 0 0.125em 0.313em rgba(50,50,93,.09), 0 0.063em 0.125em rgba(0,0,0,.07);
  background: ${({ theme }) => theme.inputBackground} !important;
  div[class$="-singleValue"] {
    color: ${({ theme }) => theme.headerTextColor} !important;
  }
  div[class$="-ValueContainer"] {
    color: ${({ theme }) => theme.headerTextColor} !important;
    cursor: pointer;
  }
  input {
    background: 0px center !important;
    color: ${({ theme }) => theme.headerTextColor} !important;
  }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.faintBorder} !important;
}

.react-tabs__tab {
  padding: 15px 20px 10px 20px;
  width: 100%;
  color: ${({ theme }) => theme.headerTextColor} !important;
  background: ${({ theme }) => theme.coreBackground} !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 15px;
  font-size: 1rem;
  font-weight: 300;

  svg {
    color: ${({ theme }) => theme.buttonBackground} !important;
  }
}

.react-tabs__tab--disabled {
  color: ${({ theme }) => theme.lineGrey} !important;

  svg {
    color: ${({ theme }) => theme.lineGrey} !important;
  }
}

.react-tabs__tab.react-tabs__tab--selected {
  color: ${({ theme }) => theme.headerTextColor} !important;
  background: ${({ theme }) => theme.coreBackground} !important;
  border-color: ${({ theme }) => theme.faintBorder} !important;
}

.react-tabs__tab:focus:after {
  background: ${({ theme }) => theme.faintBorder} !important;
  height: 2px !important;
  display: none;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  pointer-events: none;
  fill: ${({ theme }) => theme.headerTextColor};
}

input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: .3;
  pointer-events: all;
}

.dark input[type="search"]::-webkit-search-cancel-button {
  filter: invert(1);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.rrt-confirm-holder .rrt-confirm .rrt-message {
  white-space: pre-wrap;
  line-height: 1.5em;
}

mark {
  color: ${({ theme }) => theme.headerTextColor};
  animation: ${highlightAnimation} 1s linear forwards;
	animation-timeline: view(60% 20%);
	background: linear-gradient(
		to right,
		oklch(0.86 0.19 84.89 / 1) 50%,
		oklch(0.86 0.19 84.89 / 0) 50%
	);
	background-position: 100%;
	background-size: 200% 100%;
	border-radius: 0.2rem;
	padding-inline: 0.2rem;
}

.chat_bold_themed {
  color: ${({ theme }) => theme.buttonBackground};
}

.reactour__popover {
  white-space: pre-line;
  z-index: 2147483647 !important;
}
`;