import { API } from "aws-amplify";
import { apiGateway } from "../../config";
import { store } from "../";
import {
  ADD_PROJECTS,
  ADD_PROJECT,
  ADD_PROMPTS,
  UPDATE_PROJECT,
  REMOVE_PROJECT,
  FETCHING_PROJECTS,
  FETCHED_PROJECTS,
  FETCHING_PROJECT,
  FETCHED_PROJECT,
  FETCHING_PROMPTS,
  FETCHED_PROMPTS,
  OPEN_SURVEY,
  CLOSE_SURVEY,
  LOADING_BLOCKS,
  SET_DOCUMENT,
  STORE_PROJECT_SETTINGS,
  SET_ADAPTIVE
} from "./constants";
import { getAccountUsers } from "./users";
import axios from "axios";
import { getUser } from "./user";
import { fetchDiagnoses } from "../../utilities";
const Gateway = apiGateway.find((gateway) => gateway.name === "client");

export const getProjects = (override) => async (dispatch) => {
  if ((!store.getState().projects.fetching && !store.getState().projects.fetched) || override) {
    dispatch({ type: FETCHING_PROJECTS, payload: true });
    return API.get(Gateway.name, `/projects/get-all/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`,
      {
        headers: {
          Authorization: store.getState().session.token
        }
      })
      .then((data) => {
        dispatch({ type: ADD_PROJECTS, payload: data.payload });
        dispatch({ type: FETCHING_PROJECTS, payload: false });
        dispatch({ type: FETCHED_PROJECTS, payload: true });
        return data;
      })
      .catch((error) => {
        dispatch({ type: FETCHING_PROJECTS, payload: false });
        dispatch({ type: FETCHED_PROJECTS, payload: true });
        return {
          success: false,
          message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
        };
      });
  }
};

export const createProject = (project) => async (dispatch) => {
  return API.post(Gateway.name, `/projects/create/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`, {
    headers: {
      Authorization: store.getState().session.token,
    },
    body: {
      project
    },
  })
    .then((newProject) => {
      if (newProject.success) {
        return dispatch(getAccountUsers(store.getState().session.account.account_id, store.getState().authentication.user.cognito_id, true))
        .then(async () => {
          await getUser(store.getState().authentication.user.cognito_id, store.getState().session.account.account_id, true);
          dispatch({ type: ADD_PROJECT, payload: newProject.payload });
          return newProject;
        });
      }
      return newProject;
    })
    .catch((error) => {
      return {
        success: false,
        message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
      };
    });
};

export const updateProject = (project_id, updates) => async (dispatch) => {
  return API.patch(Gateway.name, `/projects/update/${project_id}/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`, {
    headers: {
      Authorization: store.getState().session.token,
    },
    body: {
      updates
    },
  })
    .then((updated) => {
      dispatch({ type: UPDATE_PROJECT, payload: updated.payload });
      return updated;
    })
    .catch((error) => {
      return {
        success: false,
        message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
      };
    });
};

export const removeProject = (project_id) => async (dispatch) => {
  dispatch({ type: REMOVE_PROJECT, payload: project_id });
  return API.del(Gateway.name, `/projects/delete/${project_id}/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`, {
    headers: {
      Authorization: store.getState().session.token,
    },
  })
    .then((updated) => {
      return updated;
    })
    .catch((error) => {
      return {
        success: false,
        message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
      };
    });
};

export const getProject = (project_id, silent = false) => async (dispatch) => {
  if (!silent) dispatch({ type: FETCHING_PROJECT, payload: true });
  return API.get(Gateway.name, `/projects/${project_id}/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`, {
    headers: {
      Authorization: store.getState().session.token,
    }
  })
    .then((response) => {
      dispatch({ type: UPDATE_PROJECT, payload: response.payload });
      if (!silent) {
        dispatch({ type: FETCHING_PROJECT, payload: false });
        dispatch({ type: FETCHED_PROJECT, payload: true });
        dispatch({ type: LOADING_BLOCKS, payload: [] });
      }
      return response;
    })
    .catch((error) => {
      if (!silent) {
        dispatch({ type: FETCHING_PROJECT, payload: false });
        dispatch({ type: FETCHED_PROJECT, payload: true });
        dispatch({ type: LOADING_BLOCKS, payload: [] });
      }
      return {
        success: false,
        message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
      };
    });
};
export const setDocument = (data) => async (dispatch) => {
  dispatch({ type: SET_DOCUMENT, payload: data });
};
export const openSurvey = (block, segment) => async (dispatch) => {
  dispatch({ type: OPEN_SURVEY, payload: { ...block, active_segment: segment } });
};
export const closeSurvey = () => async (dispatch) => {
  dispatch({ type: CLOSE_SURVEY });
};
export const storeProjectSettings = (project_id, settings) => async (dispatch) => {
  dispatch({ type: STORE_PROJECT_SETTINGS, payload: { project_id, settings } });
};

export const getFoods = async (query) => {
  return axios.get(`https://api.edamam.com/auto-complete?app_id=59e3f1e3&app_key=8fad27c4d135c4b56659e71ce530af4e&q=${query}`, {
    disableMultipart: true,
    headers: {
      'Content-Type': "application/json"
    }
  })
    .then((results) => results.data)
    .catch(() => [])
};

export const SearchDiagnoses = async (term) => {
  return await fetchDiagnoses(term);
};

export const getPrompts = (project_id, override) => async (dispatch) => {
  if ((!store.getState().prompts.fetching && !store.getState().prompts.fetched[project_id]) || override) {
    dispatch({ type: FETCHING_PROMPTS, payload: true });
    return API.get(Gateway.name, `/prompts/get-all/${project_id}/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`,
      {
        headers: {
          Authorization: store.getState().session.token
        }
      })
      .then((data) => {
        dispatch({ type: ADD_PROMPTS, payload: { list: data.payload, prompt_reminders: data.prompt_reminders, project_id } });
        dispatch({ type: FETCHING_PROMPTS, payload: false });
        dispatch({ type: FETCHED_PROMPTS, payload: { status: true, project_id } });
        return data;
      })
      .catch((error) => {
        dispatch({ type: ADD_PROMPTS, payload: { list: [], project_id } });
        dispatch({ type: FETCHING_PROMPTS, payload: false });
        dispatch({ type: FETCHED_PROMPTS, payload: { status: true, project_id } });
        return {
          success: false,
          message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
        };
      });
  }
};

export const getSurveyJson = async (survey_id, all = false) => {
  if (store.getState().session.authorized) {
    let url = `/projects/get-json/${survey_id}/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`;
    if (all) url += "?all=true";
    return API.get(Gateway.name, url,
      {
        headers: {
          Authorization: store.getState().session.token
        }
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return {
          success: false,
          message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
        };
      });
  }
};

export const setAdaptive = (config) => async (dispatch) => {
  dispatch({ type: SET_ADAPTIVE, payload: config });
};