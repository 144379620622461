import { API } from "aws-amplify";
import { apiGateway } from "../../config";
import { store } from "../";
import {
  ADD_BLOCKS,
  FETCHING_BLOCKS
} from "./constants";
const Gateway = apiGateway.find((gateway) => gateway.name === "client");

export const getBlocks = (override, project_id) => async (dispatch) => {
  if ((!store.getState().blocks.fetching && !store.getState().blocks.fetched) || override) {
    dispatch({ type: FETCHING_BLOCKS, payload: true });
    return API.get(Gateway.name, `/blocks/get-all/${(store.getState().session.account.account_id)}/${store.getState().authentication.user.cognito_id}`,
      {
        headers: {
          Authorization: store.getState().session.token
        }
      })
      .then((data) => {
        dispatch({ type: ADD_BLOCKS, payload: { list: data.payload, project_id } });
        dispatch({ type: FETCHING_BLOCKS, payload: false });
        return data;
      })
      .catch((error) => {
        dispatch({ type: FETCHING_BLOCKS, payload: false });
        return {
          success: false,
          message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
        };
      });
  } else {
    return { success: true, payload: store.getState().blocks.list };
  }
};