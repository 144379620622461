import { API } from "aws-amplify";
import { apiGateway } from "../../config";
import { store } from "../";
import {
  ADD_ACCOUNTS,
  SET_ACCOUNT,
  SET_CURRENT_ACCOUNT,
  SET_PRIMARY_ACCOUNT,
  FETCHING_ACCOUNTS,
  FETCHED_ACCOUNTS
} from "./constants";
import { getAccountUsers } from "./users";
const Gateway = apiGateway.find((gateway) => gateway.name === "client");

export const getAccounts = (cognito_id, account_id, override) => async (dispatch) => {
  if ((!store.getState().accounts.fetching && !store.getState().accounts.fetched) || override) {
    dispatch({ type: FETCHING_ACCOUNTS, payload: true });
    return API.get(Gateway.name, `/accounts/get-accounts/${(store.getState().session.account.account_id || account_id)}/${cognito_id}`,
      {
        headers: {
          Authorization: store.getState().session.token
        }
      })
        .then((data) => {
          const current = data.payload.find((a) => a.is_current);
          const primary = data.payload.find((a) => a.is_primary);
          if (data.payload.length > 1) {
            if (current) {
              dispatch(getAccountUsers(current.account_id, cognito_id))
              dispatch({ type: SET_CURRENT_ACCOUNT, payload: current });
              dispatch({ type: SET_ACCOUNT, payload: current });
            }
            if (primary) {
              dispatch({ type: SET_PRIMARY_ACCOUNT, payload: primary });
              if (!current) dispatch({ type: SET_ACCOUNT, payload: primary });
            }
          } else {
            dispatch(getAccountUsers(data.payload[0].account_id, cognito_id))
            dispatch({ type: SET_CURRENT_ACCOUNT, payload: data.payload[0] });
            dispatch({ type: SET_PRIMARY_ACCOUNT, payload: data.payload[0] });
            dispatch({ type: SET_ACCOUNT, payload: data.payload[0] });
          }
          dispatch({ type: ADD_ACCOUNTS, payload: data.payload });
          dispatch({ type: FETCHING_ACCOUNTS, payload: false });
          dispatch({ type: FETCHED_ACCOUNTS, payload: true });
          return { success: data.success, accounts: data.payload, current: current || data.payload[0], primary: primary || data.payload[0] };
        })
        .catch((error) => {
          dispatch({ type: FETCHING_ACCOUNTS, payload: false });
          dispatch({ type: FETCHED_ACCOUNTS, payload: true });
          return {
            success: false,
            message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
          };
        });
  }
};

export const createAccount = async (account, invite_code) => {
  return API.post(Gateway.name, `/accounts/create/${account.cognito_id}`, {
    headers: {
      Authorization: store.getState().session.token,
    },
    body: {
      account,
      invite_code
    },
  })
    .then((newAccount) => {
      return newAccount;
    })
    .catch((error) => {
      return {
        success: false,
        message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
      };
    });
};

export const updateAccountFeatures = (updates) => async (dispatch) => {
  return API.patch(Gateway.name, `/accounts/update-account-features/${store.getState().session.account.account_id}/${store.getState().authentication.user.cognito_id}`, {
    headers: {
      Authorization: store.getState().session.token,
    },
    body: {
      updates
    },
  })
    .then((account) => {
      dispatch(getAccounts(store.getState().authentication.user.cognito_id, store.getState().session.account.account_id, true));
      return account;
    })
    .catch((error) => {
      return {
        success: false,
        message: error.response && error.response.data ? error.response.data.message : "Something went wrong."
      };
    });
};

export const createAccountMembership = (account_membership) => async (dispatch) => {
  return API.post(Gateway.name, `/memberships/create/${account_membership.account_id}/${account_membership.cognito_id}`, {
    headers: {
      Authorization: store.getState().session.token,
    },
    body: {
      account_membership,
    },
  })
    .then((newMembership) => {
      return newMembership;
    })
    .catch((error) => {
      return {
        success: false,
        error,
      };
    });
};