import {
  ADD_CONTACTS,
  ADD_CONTACT,
  DELETE_CONTACT,
  UPDATE_CONTACT,
  FETCHING_CONTACTS,
  FETCHED_CONTACTS,
  ADD_PROJECTS,
  CLEAR_ALL,
  CREATING_CONTACT,
  UPDATING_CONTACT
} from "../actions/constants";
import defaults from "./defaults";

const contactsReducer = (state = defaults.contacts, { type, payload }) => {
  switch (type) {
    case ADD_CONTACTS:
      return { ...state, list: { ...state.list, [payload.project_id]: payload.list }, fetched_all: new Date() };
    case ADD_CONTACT:
      const existing_contacts_add = (state.list[payload.project_id] || []).filter((p) => p.id !== payload.id);
      return { ...state, list: { ...state.list, [payload.project_id]: [...existing_contacts_add, payload] } };
    case UPDATE_CONTACT:
      const updating_target_list = state.list[payload.new.project_id];
      const updating_filtered = updating_target_list.filter((d) => d.id !== payload.contact_id);
      return { ...state, updating_contact: false, list: { ...state.list, [payload.new.project_id]: [...updating_filtered, payload.new] } };
    case DELETE_CONTACT:
      const current = state.list[payload.project_id];
      const remaining = current.filter((d) => d.id !== payload.id);
      return { ...state, list: { ...state.list, [payload.project_id]: remaining } };
    case FETCHING_CONTACTS:
      return { ...state, fetching: payload };
    case FETCHED_CONTACTS:
      return { ...state, fetched: { ...state.fetched, [payload.project_id]: new Date() } };
    case ADD_PROJECTS:
      return { ...state, fetched: {}, fetching: false, list: {} };
    case CREATING_CONTACT:
      return { ...state, creating_contact: payload };
    case UPDATING_CONTACT:
      return { ...state, updating_contact: payload };
    case CLEAR_ALL:
      return defaults.contacts;
    default:
      return state;
  }
};

export default contactsReducer;