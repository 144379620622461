import { CLEAR_ALL, LOCATION_CHANGE } from "../actions/constants";
import defaults from "./defaults";

const routerReducer = (state = defaults.router, { type, payload }) => {
  switch (type) {
    case LOCATION_CHANGE:
      return { ...state, ...payload };
    case CLEAR_ALL:
      return defaults.router;
    default:
      return state;
  }
};

export default routerReducer;