import { toastr } from "react-redux-toastr";

export const showNotification =
  (type, title = "", message = "", metadata = {}) => async (dispatch) => {
    const config = {
      ...metadata,
      status: type
    };
    if (metadata && metadata.action) config.onToastrClick = () => metadata.noDispatch ? metadata.action() : dispatch(metadata.action);
    switch (type) {
      case "success":
        toastr.success(title, message, config);
        break;
      case "info":
        toastr.info(title, message, config);
        break;
      case "warning":
        toastr.warning(title, message, config);
        break;
      case "error":
        toastr.error(title, message, config);
        break;
      case "delete":
        const deleteOptions = {
          onOk: () =>  metadata.noDispatch ? metadata.action() : dispatch(metadata.action),
          onCancel: () => toastr.removeByType("confirms"),
          okText: metadata.okText || "Ok",
          cancelText: metadata.cancelText || "Cancel",
          disableCancel: metadata.disableCancel
        };
        toastr.confirm(title, deleteOptions);
        break;
      case "confirm":
        const confirmOptions = {
          onOk: () =>  metadata.noDispatch ? metadata.action() : dispatch(metadata.action),
          onCancel: () => toastr.removeByType("confirms"),
          okText: metadata.Yes || "Yes",
          cancelText: metadata.No || "No"
        };
        toastr.confirm(title, confirmOptions);
        break;
      default:
        break;
    }
  };
