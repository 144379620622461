import {
  UPDATE_USER,
  UPDATE_CREDENTIALS,
  FETCHING_CREDENTIALS,
  FETCHED_CREDENTIALS,
  FETCHING_USER,
  FETCHED_USER,
  CLEAR_ALL,
  AUTO_VERIFYING_PHONE,
} from "../actions/constants";
import defaults from "./defaults";

const authenticationReducer = (state = defaults.authentication, { type, payload }) => {
  switch (type) {
    case UPDATE_USER:
      return { ...state, user: { ...state.user, ...payload } };
    case UPDATE_CREDENTIALS:
      return { ...state, cognito: payload };
    case FETCHING_CREDENTIALS:
      return { ...state, fetching: payload };
    case FETCHED_CREDENTIALS:
      return { ...state, fetched: payload };
    case FETCHING_USER:
      return { ...state, fetching: payload };
    case FETCHED_USER:
      return { ...state, fetched: payload };
    case AUTO_VERIFYING_PHONE:
      return {...state, auto_verifying_phone: payload };
    case CLEAR_ALL:
      return defaults.authentication;
    default:
      return state;
  }
};

export default authenticationReducer;