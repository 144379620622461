import { library } from "@fortawesome/fontawesome-svg-core";
// import { far } from "@fortawesome/pro-regular-svg-icons";
// import { fass } from "@fortawesome/sharp-solid-svg-icons";
// import { fasl } from "@fortawesome/sharp-light-svg-icons";
// import { fasr } from "@fortawesome/sharp-regular-svg-icons";
import {
  faArrowUpFromSquare as falArrowUpFromSquare,
  faSquarePlus as falSquarePlus,
  faDisplayArrowDown as falDisplayArrowDown,
  faSpinner as falSpinner,
} from "@fortawesome/pro-light-svg-icons";
import {
  faChevronUp as fasChevronUp,
  faChevronDown as fasChevronDown,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faDownload as fasDownload,
  faBars as fasBars,
  faCheckCircle as fasCheckCircle,
  faSpinner as fasSpinner,
  faTimes as fasTimes,
  faCog as fasCog,
  faArrowsRotate as fasArrowsRotate,
  faGripVertical as fasGripVertical,
  faArrowAltCircleRight as fasArrowAltCircleRight,
  faLock as fasLock,
  faPencil as fasPencil,
  faMicrophoneSlash as fasMicrophoneSlash,
  faMicrophone as fasMicrophone,
  faCircleExclamation as fasCircleExclamation,
  faEllipsisVertical as fasEllipsisVertical,
  faCircle as fasCircle,
  faDash as fasDash,
  faEllipsis as fasEllipsis,
  faScrewdriverWrench as fasScrewdriverWrench,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faCloudArrowUp as fatCloudArrowUp,
  faRectangleList as fatRectangleList,
  faWallet as fatWallet,
  faUser as fatUser,
  faCogs as fatCogs,
  faSpinner as fatSpinner,
  faHandWave as fatHandWave,
  faLaptopFile as fatLaptopFile,
  faHospitalUser as fatHospitalUser,
  faFileLock as fatFileLock,
  faFamily as fatFamily
} from "@fortawesome/pro-thin-svg-icons";
import {
  faChevronLeft as fadChevronLeft,
  faChevronUp as fadChevronUp,
  faChevronDown as fadChevronDown,
  faChevronRight as fadChevronRight,
  faBars as fadBars,
  faCheckCircle as fadCheckCircle,
  faSpinner as fadSpinner,
  faLock as fadLock,
  faPencil as fadPencil,
  faTrash as fadTrash,
  faArrowLeft as fadArrowLeft,
  faArrowUp as fadArrowUp,
  faArrowDown as fadArrowDown,
  faDownload as fadDownload,
  faTimes as fadTimes,
  faListUl as fadListUl,
  faSearch as fadSearch,
  faShare as fadShare,
  faPaintBrush as fadPaintBrush,
  faEllipsis as fadEllipsis,
  faUserPen as fadUserPen,
  faEnvelope as fadEnvelope,
  faUser as fadUser,
  faMicrophone as fadMicrophone,
  faMicrophoneSlash as fadMicrophoneSlash,
  faPhone as fadPhone,
  faMagnifyingGlass as fadMagnifyingGlass,
  faArrowRightFromBracket as fadArrowRightFromBracket,
  faChartLine as fadChartLine,
  faListCheck as fadListCheck,
  faFolders as fadFolders,
  faPrescriptionBottleMedical as fadPrescriptionBottleMedical,
  faUsers as fadUsers,
  faFile as fadFile,
  faCog as fadCog,
  faRectangleList as fadRectangleList,
  faWallet as fadWallet,
  faCogs as fadCogs,
  faFileImage as fadFileImage,
  faFileLines as fadFileLines,
  faFilePdf as fadFilePdf,
  faFileZip as fadFileZip,
  faFileExcel as fadFileExcel,
  faFileWord as fadFileWord,
  faFileDoc as fadFileDoc,
  faFileMusic as fadFileMusic,
  faFileCsv as fadFileCsv,
  faFilePowerpoint as fadFilePowerpoint,
  faFileVideo as fadFileVideo,
  faCalendar as fadCalendar,
  faStethoscope as fadStethoscope,
  faNoteMedical as fadNoteMedical,
  faHeadSideBrain as fadHeadSideBrain,
  faFilesMedical as fadFilesMedical,
  faExclamationCircle as fadExclamationCircle,
  faInfoCircle as fadInfoCircle,
  faUserHeadset as fadUserHeadset,
  faSliders as fadSliders,
  faBookOpen as fadBookOpen,
  faBookmark as fadBookmark,
  faLayerPlus as fadLayerPlus,
  faStarShooting as fadStarShooting,
  faStar as fadStar,
  faCloudArrowDown as fadCloudArrowDown,
  faFloppyDiskPen as fadFloppyDiskPen,
  faArrowRight as fadArrowRight,
  faFilePen as fadFilePen,
  faLink as fadLink,
  faShieldCheck as fadShieldCheck,
  faShieldExclamation as fadShieldExclamation,
  faShieldXmark as fadShieldXmark,
  faSquareEllipsisVertical as fadSquareEllipsisVertical,
  faQuestionCircle as fadQuestionCircle,
  faSignature as fadSignature,
  faCalendarDay as fadCalendarDay,
  faFileCircleInfo as fadFileCircleInfo,
  faMemoCircleCheck as fadMemoCircleCheck,
  faUserCircle as fadUserCircle,
  faUserDoctor as fadUserDoctor,
  faUserShield as fadUserShield,
  faUsersMedical as fadUsersMedical,
  faUsersGear as fadUsersGear,
  faLightEmergencyOn as fadLightEmergencyOn,
  faPrescriptionBottlePill as fadPrescriptionBottlePill,
  faCircle as fadCircle,
  faDash as fadDash,
  faTablets as fadTablets,
  faCapsules as fadCapsules,
  faPills as fadPills,
  faEyeDropperHalf as fadEyeDropperHalf,
  faFileBinary as fadFileBinary,
  faCreditCard as fadCreditCard,
  faCreditCardFront as fadCreditCardFront,
  faRectangleHistoryCircleUser as fadRectangleHistoryCircleUser,
  faMapLocation as fadMapLocation,
  faArrowsRotate as fadArrowsRotate,
  faCoins as fadCoins,
  faCode as fadCode,
  faEye as fadEye,
  faEyeSlash as fadEyeSlash,
  faRocket as fadRocket,
  faRightFromBracket as fadRightFromBracket,
  faCameraViewfinder as fadCameraViewfinder,
  faMoneyCheckDollarPen as fadMoneyCheckDollarPen,
  faUserPlus as fadUserPlus,
  faPenToSquare as fadPenToSquare,
  faKey as fadKey,
  faShieldKeyhole as fadShieldKeyhole,
  faCircleHalfStroke as fadCircleHalfStroke,
  faBell as fadBell,
  faSparkles as fadSparkles,
  faClock as fadClock,
  faFingerprint as fadFingerprint,
  faCircleXmark as fadCircleXmark,
  faCircleDollar as fadCircleDollar,
  faReceipt as fadReceipt,
  faNote as fadNote,
  faCalendarCheck as fadCalendarCheck,
  faBoxes as fadBoxes,
  faGrid2 as fadGrid2,
  faTimer as fadTimer,
  faHouse as fadHouse,
  faComment as fadComment,
  faUserMagnifyingGlass as fadUserMagnifyingGlass,
  faFileMagnifyingGlass as fadFileMagnifyingGlass,
  faMemoCircleInfo as fadMemoCircleInfo,
  faQrcode as fadQrcode,
  faExpand as fadExpand,
  faPerson as fadPerson,
  faPlateUtensils as fadPlateUtensils,
  faClothesHanger as fadClothesHanger,
  faToilet as fadToilet,
  faToothbrush as fadToothbrush,
  faUniversalAccess as fadUniversalAccess,
  faChildReaching as fadChildReaching,
  faNotesMedical as fadNotesMedical,
  faFileSignature as fadFileSignature,
  faHandsPraying as fadHandsPraying,
  faPeopleGroup as fadPeopleGroup,
  faPeopleRoof as fadPeopleRoof,
  faTaxiBus as fadTaxiBus,
  faBed as fadBed,
  faSchool as fadSchool,
  faSchoolFlag as fadSchoolFlag,
  faChalkboardUser as fadChalkboardUser,
  faIdCard as fadIdCard,
  faBooks as fadBooks,
  faHousePersonLeave as fadHousePersonLeave,
  faBookOpenCover as fadBookOpenCover,
  faHandHoldingHand as fadHandHoldingHand,
  faScreenUsers as fadScreenUsers,
  faFileUser as fadFileUser,
  faBuildingMemo as fadBuildingMemo,
  faGamepadModern as fadGamepadModern,
  faFaceSmile as fadFaceSmile,
  faEarthAmericas as fadEarthAmericas,
  faSpinnerThird as fadSpinnerThird,
  faHandWave as fadHandWave,
  faLaptopFile as fadLaptopFile,
  faHospitalUser as fadHospitalUser,
  faFileLock as fadFileLock,
  faFamily as fadFamily,
  faGrid as fadGrid,
  faHandHoldingDollar as fadHandHoldingDollar,
  faCloud as fadCloud,
} from "@fortawesome/pro-duotone-svg-icons";

import {
  faCcAmex as falCcAmex,
  faCcDinersClub as falCcDinersClub,
  faCcDiscover as falCcDiscover,
  faCcJcb as falCcJcb,
  faCcMastercard as falCcMastercard,
  faCcVisa as falCcVisa
} from "@fortawesome/free-brands-svg-icons";

const loadIcons = () => {
  // add light icons
  library.add(
    falArrowUpFromSquare,
    falSquarePlus,
    falDisplayArrowDown
  );

  // add solid icons
  library.add(
    fasChevronUp,
    fasChevronDown,
    fasChevronLeft,
    fasChevronRight,
    fasDownload,
    fasBars,
    fasCheckCircle,
    fasSpinner,
    fasTimes,
    fasCog,
    fasArrowsRotate,
    fasGripVertical,
    fasArrowAltCircleRight,
    fasLock,
    fasPencil,
    fasMicrophoneSlash,
    fasMicrophone,
    fasCircleExclamation,
    fasEllipsisVertical,
    fasCircle,
    fasDash,
    fasEllipsis,
    fasScrewdriverWrench
  );

  // add duotone icons
  library.add(
    fadChevronLeft,
    fadChevronDown,
    fadChevronUp,
    fadChevronRight,
    fadBars,
    fadCheckCircle,
    fadSpinner,
    fadLock,
    fadPencil,
    fadTrash,
    fadArrowLeft,
    fadDownload,
    fadTimes,
    fadListUl,
    fadSearch,
    fadShare,
    fadPaintBrush,
    fadEllipsis,
    fadUserPen,
    fadEnvelope,
    fadUser,
    fadUserCircle,
    fadMicrophone,
    fadMicrophoneSlash,
    fadPhone,
    fadMagnifyingGlass,
    fadArrowRightFromBracket,
    fadChartLine,
    fadListCheck,
    fadFolders,
    fadPrescriptionBottleMedical,
    fadUsers,
    fadFile,
    fadCog,
    fadRectangleList,
    fadWallet,
    fadCogs,
    fadFileImage,
    fadFileLines,
    fadFilePdf,
    fadFileZip,
    fadFileExcel,
    fadFileWord,
    fadFileDoc,
    fadFileMusic,
    fadFileCsv,
    fadFilePowerpoint,
    fadFileVideo,
    fadCalendar,
    fadStethoscope,
    fadNoteMedical,
    fadHeadSideBrain,
    fadFilesMedical,
    fadExclamationCircle,
    fadInfoCircle,
    fadUserHeadset,
    fadSliders,
    fadBookOpen,
    fadBookmark,
    fadLayerPlus,
    fadStarShooting,
    fadStar,
    fadCloudArrowDown,
    fadFloppyDiskPen,
    fadArrowRight,
    fadFilePen,
    fadShieldCheck,
    fadShieldExclamation,
    fadShieldXmark,
    fadSquareEllipsisVertical,
    fadQuestionCircle,
    fadSignature,
    fadCalendarDay,
    fadFileCircleInfo,
    fadMemoCircleCheck,
    fadLink,
    fadUserDoctor,
    fadUserShield,
    fadUsersMedical,
    fadUsersGear,
    fadLightEmergencyOn,
    fadPrescriptionBottlePill,
    fadCircle,
    fadDash,
    fadTablets,
    fadCapsules,
    fadPills,
    fadEyeDropperHalf,
    fadFileBinary,
    fadCreditCard,
    fadCreditCardFront,
    fadRectangleHistoryCircleUser,
    fadMapLocation,
    fadArrowsRotate,
    fadCoins,
    fadCode,
    fadArrowUp,
    fadArrowDown,
    fadEye,
    fadEyeSlash,
    fadRocket,
    fadRightFromBracket,
    fadCameraViewfinder,
    fadMoneyCheckDollarPen,
    fadUserPlus,
    fadPenToSquare,
    fadKey,
    fadShieldKeyhole,
    fadCircleHalfStroke,
    fadBell,
    fadSparkles,
    fadClock,
    fadFingerprint,
    fadCircleXmark,
    fadCircleDollar,
    fadReceipt,
    fadNote,
    fadCalendarCheck,
    fadBoxes,
    fadGrid2,
    fadTimer,
    fadHouse,
    fadComment,
    fadUserMagnifyingGlass,
    fadFileMagnifyingGlass,
    fadMemoCircleInfo,
    fadQrcode,
    fadExpand,
    fadPerson,
    fadPlateUtensils,
    fadClothesHanger,
    fadToilet,
    fadToothbrush,
    fadUniversalAccess,
    fadChildReaching,
    fadNotesMedical,
    fadFileSignature,
    fadHandsPraying,
    fadPeopleGroup,
    fadPeopleRoof,
    fadTaxiBus,
    fadBed,
    fadSchool,
    fadSchoolFlag,
    fadChalkboardUser,
    fadIdCard,
    fadBooks,
    fadHousePersonLeave,
    fadBookOpenCover,
    fadHandHoldingHand,
    fadScreenUsers,
    fadFileUser,
    fadBuildingMemo,
    fadGamepadModern,
    fadFaceSmile,
    fadEarthAmericas,
    fadSpinnerThird,
    fadHandWave,
    fadLaptopFile,
    fadHospitalUser,
    fadFileLock,
    fadFamily,
    fadGrid,
    fadHandHoldingDollar,
    fadCloud
  );

  // add thin icons
  library.add(
    fatCloudArrowUp,
    fatRectangleList,
    fatWallet,
    fatUser,
    fatCogs,
    fatSpinner,
    fatHandWave,
    fatLaptopFile,
    fatHospitalUser,
    fatFileLock,
    fatFamily
  );

  // add brand icons
  library.add(
    falCcAmex,
    falCcDinersClub,
    falCcDiscover,
    falCcJcb,
    falCcMastercard,
    falCcVisa,
    falSpinner
  );
};

export default loadIcons;
