import {
  FETCHING_ADMIN_ACCOUNTS,
  FETCHED_ADMIN_ACCOUNTS,
  CLEAR_ALL,
  FETCHING_ADMIN_SUBSCRIPTIONS,
  FETCHED_ADMIN_SUBSCRIPTIONS,
  FETCHING_ADMIN_USERS,
  FETCHED_ADMIN_USERS,
  FETCHING_ADMIN_PROJECTS,
  FETCHED_ADMIN_PROJECTS,
  FETCHING_ADMIN_COSTS,
  FETCHED_ADMIN_COSTS,
  FETCHING_ADMIN_SETTINGS,
  FETCHED_ADMIN_SETTINGS,
  FETCHING_ADMIN_PROMPTS,
  FETCHED_ADMIN_PROMPTS,
  FETCHING_ADMIN_BLOCKS,
  FETCHED_ADMIN_BLOCKS
} from "../actions/constants";
import defaults from "./defaults";

const adminReducer = (state = defaults.admin, { type, payload }) => {
  switch (type) {
    case FETCHING_ADMIN_SETTINGS:
      return { ...state, fetching_settings: payload };
    case FETCHED_ADMIN_SETTINGS:
      return { ...state, fetched_settings: payload };
    case FETCHING_ADMIN_ACCOUNTS:
      return { ...state, fetching_accounts: payload };
    case FETCHED_ADMIN_ACCOUNTS:
      return { ...state, fetched_accounts: payload };
    case FETCHING_ADMIN_SUBSCRIPTIONS:
      return { ...state, fetching_subscriptions: payload };
    case FETCHED_ADMIN_SUBSCRIPTIONS:
      return { ...state, fetched_subscriptions: payload };
    case FETCHING_ADMIN_USERS:
      return { ...state, fetching_users: payload };
    case FETCHED_ADMIN_USERS:
      return { ...state, fetched_users: payload };
    case FETCHING_ADMIN_PROJECTS:
      return { ...state, fetching_projects: payload };
    case FETCHED_ADMIN_PROJECTS:
      return { ...state, fetched_projects: payload };
    case FETCHING_ADMIN_COSTS:
      return { ...state, fetching_costs: payload };
    case FETCHED_ADMIN_COSTS:
      return { ...state, fetched_costs: payload };
    case FETCHING_ADMIN_PROMPTS:
      return { ...state, fetching_prompts: payload };
    case FETCHED_ADMIN_PROMPTS:
      return { ...state, fetched_prompts: payload };
    case FETCHING_ADMIN_BLOCKS:
      return { ...state, fetching_blocks: payload };
    case FETCHED_ADMIN_BLOCKS:
      return { ...state, fetched_blocks: payload };
    case CLEAR_ALL:
      return defaults.admin;
    default:
      return state;
  }
};

export default adminReducer;