import {
  ADD_BLOCKS,
  ADD_BLOCK,
  UPDATE_BLOCK,
  REMOVE_BLOCK,
  FETCHING_BLOCKS,
  FETCHED_BLOCKS,
  CLEAR_ALL,
  ADD_PROJECTS
} from "../actions/constants";
import defaults from "./defaults";

const blocksReducer = (state = defaults.blocks, { type, payload }) => {
  switch (type) {
    case ADD_BLOCKS:
      return { ...state, list: payload.list, fetched: { ...state.fetched, [payload.project_id]: new Date()} };
    case ADD_BLOCK:
      const existing_blocks_add = state.list.filter((p) => p.id !== payload.id);
      return { ...state, list: [...existing_blocks_add, payload] };
    case UPDATE_BLOCK:
      const existing_blocks_update = state.list.filter((p) => p.id !== payload.id);
      return { ...state, list: [...existing_blocks_update, payload] };
    case REMOVE_BLOCK:
      const remaining = state.list.filter((d) => d.id !== payload.id);
      return { ...state, list: remaining };
    case FETCHING_BLOCKS:
      return { ...state, fetching: payload };
    case FETCHED_BLOCKS:
      return { ...state, fetched: new Date() };
    case ADD_PROJECTS:
      return { ...state, fetched: false, fetching: false, list: [] };
    case CLEAR_ALL:
      return defaults.blocks;
    default:
      return state;
  }
};

export default blocksReducer;